import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NoWhitelabelComponent } from "./no-whitelabel.component";
import { NoWhitelabelRoutingModule } from "./no-whitelabel-routing.module";

@NgModule({
  declarations: [NoWhitelabelComponent],
  imports: [CommonModule, NoWhitelabelRoutingModule],
})
export class NoWhitelabelModule {}
