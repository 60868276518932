<div *ngIf="credential; else notfound" class="l-detail">
  <section class="l-detail-x-content">
    <div id="overview" class="l-detail-x-subsection wrap textblock">
      <h1>{{ credential.name }}</h1>
      <div class="l-overview">
        <div class="l-overview-x-image">
          <!-- NOTE: Alt text should describe the image's content. e.g. Pencil Icon -->
          <img src="{{ credential.image }}" width="240" height="240" />
        </div>
        <div class="l-overview-x-content">
          <div class="l-overview-x-calltoaction">
            <div class="calltoaction">
              <ng-template
                [ngIf]="!submission?.status"
                [ngIfElse]="ctaShowStatus"
              >
                <ng-template
                  [ngIf]="
                    credential.availability === 'public' ||
                    credential.availability === 'private'
                  "
                  [ngIfElse]="inactive"
                >
                  <div class="calltoaction-x-text">
                    <ng-container
                      *ngIf="!deniedSubmission; else resubmissionPrice"
                    >
                      {{
                        credential.price > 0 ? "$" + credential.price : "Free"
                      }}
                    </ng-container>
                    <ng-template #resubmissionPrice>
                      {{
                        credential.resubmission_price
                          ? "$" + credential.resubmission_price
                          : credential.price > 0
                          ? "$" + credential.price
                          : "Free"
                      }}
                      to reapply
                    </ng-template>
                  </div>
                  <button
                    *ngIf="assessmentExtension"
                    (click)="logApplyClick(credential.id)"
                    [routerLink]="['/explore', credential.slug, 'application']"
                    class="calltoaction-x-button button button-large"
                    type="button"
                  >
                    <span class="button-x-text">
                      <ng-container
                        *ngIf="!deniedSubmission; else resubmissionText"
                        >Apply</ng-container
                      >
                      <ng-template #resubmissionText>Reapply</ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template #inactive>
                  <div class="calltoaction-x-text">
                    Not Accepting Submissions
                  </div>
                </ng-template>
                <button
                  class="calltoaction-x-buttonsm button button-tertiary"
                  *ngIf="canEdit()"
                  type="button"
                  (click)="openModal()"
                >
                  <span class="button-x-text">Edit</span>
                </button>
              </ng-template>
              <ng-template #ctaShowStatus>
                <div class="calltoaction-x-text">{{ submission.status }}</div>
                <button
                  [routerLink]="[
                    '/dashboard',
                    'submission',
                    submission.readable_id
                  ]"
                  class="calltoaction-x-button button button-large"
                  type="button"
                >
                  <span class="button-x-text">View Submission</span>
                </button>
              </ng-template>
            </div>
          </div>
          <p>{{ credential.description }}</p>
          <div class="appoverview">
            <div
              class="appoverview-x-actions"
              style="min-height: 0; border-width: 0"
            >
              <ul>
                <li>
                  <a
                    class="icon icon-organization small"
                    [routerLink]="['/explore']"
                    [queryParams]="{
                      organization__name: credential.organization.name
                    }"
                    queryParamsHandling="merge"
                  >
                    <span class="icon-x-text">{{
                      credential.organization.name
                    }}</span>
                  </a>
                </li>
                <li *ngIf="credential.tag">
                  <a
                    class="icon icon-stack small"
                    *ngIf="credential.tag"
                    [routerLink]="['/explore']"
                    [queryParams]="{ tag: credential.tag }"
                    queryParamsHandling="merge"
                  >
                    <span class="icon-x-text">{{ credential.tag }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="certification-history"
      class="l-detail-x-subsection wrap"
      *ngIf="previousSubmissions && previousSubmissions.length > 1"
    >
        <app-previous-submissions-table
          [submissions]="previousSubmissions"
          title="Certification History"
        ></app-previous-submissions-table>
    </div>

    <div
      *ngIf="credential.criteria_text || credential.criteria_url"
      id="details"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Details</span></h2>
      <div
        *ngIf="credential.criteria_text"
        class="textblock"
        [innerHTML]="credential.criteria_text | MarkdownIt"
      ></div>
      <div *ngIf="credential.criteria_url" class="l-detail-x-button">
        <button class="button button-secondary" type="button">
          <a
            href="{{ credential.criteria_url }}"
            download
            class="button-x-text"
            style="color: inherit"
            >View Printable Version</a
          >
        </button>
      </div>
    </div>

    <div
      *ngIf="credential.extra_descriptions.length > 0"
      id="research-and-resources"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Research &amp; Resources</span></h2>
      <ng-template
        ngFor
        let-extraDescription
        [ngForOf]="credential.extra_descriptions"
      >
        <h3>{{ extraDescription.name }}</h3>
        <div
          class="textblock"
          [innerHTML]="extraDescription.narrative | MarkdownIt"
        ></div>
      </ng-template>
    </div>

    <div
      *ngIf="assessmentExtension?.description"
      id="assessment-extension-overall-description"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Score Description</span></h2>
      <div [outerHTML]="assessmentExtension?.description | MarkdownIt"></div>
    </div>

    <div
      *ngIf="assessmentExtension"
      id="submission-and-evaluation"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Submission &amp; Evaluation</span></h2>
      <ng-container *ngFor="let assessment of assessmentExtension?.assessments">
        <ng-container
          *ngFor="let section of assessment.sections; let sectionIndex = index"
        >
          <h3>Part {{ sectionIndex + 1 }}: {{ section.title }}</h3>

          <ng-container *ngFor="let question of section.questions">
            <p><strong>Prompt</strong></p>
            <p data-app-question-text [questionText]="question.text"></p>

            <div
              *ngIf="question.rubric"
              class="forminput-small forminput-attachment forminput"
            >
              <app-rubric-question
                [question]="question"
                [previousRubricRecommendations]="previousRubricRecommendations"
              ></app-rubric-question>
            </div>
          </ng-container>

          <div
            data-app-rubric-section
            *ngIf="section.rubric"
            [section]="section"
            class="l-detail-x-subsectionbig"
            [previousRubricRecommendations]="previousRubricRecommendations"
          ></div>
        </ng-container>
      </ng-container>
    </div>

    <div id="issuing-organization" class="l-detail-x-subsection wrap textblock">
      <h2><span>Issuing Organization</span></h2>
      <h4>{{ credential.organization.name }}</h4>
      <!-- NOTE: Alt text should describe the image's contents -->
      <p>
        <img
          src="{{ credential.organization.image }}"
          width="64"
          height="64"
        />{{ credential.organization.description }}
      </p>
      <!--
                            <a class="icon icon-organization small"
                               [routerLink]="['/explore']"
                               [queryParams]="{organization__name: credential.organization.name}"
                               queryParamsHandling="merge">
                               {{ credential.organization.name }}
                            </a>
            -->
      <div class="l-deatil-x-button">
        <a
          class="button button-secondary"
          type="button"
          *ngIf="credential.organization.url"
          [href]="credential.organization.url"
          target="_blank"
        >
          <span class="button-x-text">Visit Website</span>
        </a>
      </div>
    </div>

    <app-submission-history
      *ngIf="user?.is_admin || user?.is_issuer || user?.is_assessor"
      title="Credential history"
      [entries]="credentialHistory"
    ></app-submission-history>
  </section>
  <div class="l-detail-x-stickycontainer">
    <nav
      class="l-detail-x-nav"
      role="navigation"
      aria-labelledby="sidebarnavigation"
    >
      <h2 class="visuallyhidden" id="sidebarnavigation"></h2>
      <div class="l-detail-x-sidebaritems">
        <a
          class="sidebarnavitem"
          href="#overview"
          (click)="navigateTo('#overview')"
        >
          Overview
        </a>
        <a
          *ngIf="credential.criteria_text || credential.criteria_url"
          class="sidebarnavitem"
          href="#details"
          (click)="navigateTo('#details')"
        >
          Details
        </a>
        <a
          *ngIf="credential.extra_descriptions.length > 0"
          class="sidebarnavitem"
          href="#research-and-resources"
          (click)="navigateTo('#research-and-resources')"
        >
          Research & Resources
        </a>
        <a
          *ngIf="assessmentExtension"
          class="sidebarnavitem"
          href="#submission-and-evaluation"
          (click)="navigateTo('#submission-and-evaluation')"
        >
          Submission & Evaluation
        </a>
        <a
          class="sidebarnavitem"
          href="#issuing-organization"
          (click)="navigateTo('#issuing-organization')"
        >
          Issuing Organization
        </a>
      </div>
    </nav>
  </div>
</div>

<ng-template #notfound>
  <app-not-found></app-not-found>
</ng-template>
