import { Component, Input } from "@angular/core";

import { AssessmentSection } from "../../assessment.type";

import { PreviousRecommendationRubricMixin } from "../../previous-recommendation-rubric-mixin/previous-recommendation-rubric.mixin";

@Component({
  selector: "[data-app-rubric-section]",
  templateUrl: "./rubric-section.component.html",
  styleUrls: ["./rubric-section.component.css"],
})
export class RubricSectionComponent extends PreviousRecommendationRubricMixin {
  assessmentType = "section";
  @Input() section: AssessmentSection;
}
