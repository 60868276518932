import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModule } from "../../shared/shared.module";

import { DraftListComponent } from "./draft-list.component";

@NgModule({
  declarations: [DraftListComponent],
  imports: [CommonModule, SharedModule],
})
export class DraftListModule {}
