import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Credential } from "../../shared/credential.type";
import { Submission } from "../../shared/submission.type";

import { ReviewPageService } from "../review-page.service";
import { Confirmation } from "../../shared/messages.constants";
import { ModalService } from "../../shared/core/modal.service";

@Component({
  selector: "app-assessor-modal",
  templateUrl: "./assessor-modal.component.html",
  styleUrls: ["./assessor-modal.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessorModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  currentSubmission: Submission;
  resubmission: Submission;
  roles: any[] = [];
  assessorForm: UntypedFormGroup;

  @ViewChild("ngxFocus", { static: true }) focusTrap;
  modalIsOpen = false;

  // Back button pressed.
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.closeDialog();
  }

  @Input()
  set isOpen(value: boolean) {
    this.modalService.anyModalIsOpen.next(value);
    this.modalIsOpen = value;

    if (value) {
      this.focusTrap.activateFocusTrap();
    }
  }

  @Input()
  set submission(selectedSubmission: Submission) {
    if (selectedSubmission) {
      this.currentSubmission = selectedSubmission;
      this.resubmission = selectedSubmission.resubmission;
      this.reviewPageService
        .getOrganizationRoles(
          (<Credential>selectedSubmission.credential).organization.id
        )
        .then((res) => {
          this.roles = res;
          this.ref.markForCheck();
        });

      if (selectedSubmission.assessor) {
        this.assessorForm.patchValue({
          assessor: selectedSubmission.assessor.id,
        });
      } else if (selectedSubmission.resubmission) {
        this.assessorForm.patchValue({
          assessor: selectedSubmission.resubmission.assessor.id,
        });
      }
    }
  }

  constructor(
    private reviewPageService: ReviewPageService,
    private ref: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private modalService: ModalService
  ) {}

  closeDialog(message = null) {
    this.closeModal.emit(message);
    this.resetForm();
    this.focusTrap.deactivateFocusTrap();
    this.modalService.anyModalIsOpen.next(false);
    this.modalIsOpen = false;
  }

  onSubmit() {
    if (this.assessorForm.valid) {
      const assessorId = this.assessorForm.get("assessor").value,
        role = this.roles.find((r) => r.user.id === assessorId),
        assessor = role.user,
        reason = Confirmation.ASSIGNED(
          `${assessor.first_name} ${assessor.last_name}`
        );
      this.reviewPageService
        .assignAssessor(
          this.currentSubmission.readable_id,
          this.assessorForm.value,
          reason
        )
        .then((res) => {
          this.closeDialog("success");
        });
    }
  }

  resetForm() {
    this.assessorForm.reset({
      assessor: [null, [Validators.required]],
    });
  }

  createForm() {
    this.assessorForm = this.fb.group({
      assessor: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.createForm();
  }
}
