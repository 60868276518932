import { Component, OnInit, Input, HostBinding } from "@angular/core";

import { NgxMarkdownItPipe } from "../../ngx-markdown-it.pipe";

@Component({
  selector: "[data-app-question-text]",
  templateUrl: "./question-text.component.html",
  styleUrls: ["./question-text.component.css"],
})
export class QuestionTextComponent implements OnInit {
  @Input() questionText: string;
  @HostBinding("class.textblock") textBlock = true;
  @HostBinding("innerHTML") innerHtml;

  constructor(private markdownItToHtmlPipe: NgxMarkdownItPipe) {}

  ngOnInit() {
    this.innerHtml = this.markdownItToHtmlPipe.transform(this.questionText);
  }
}
