import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ToastContainerModule } from "ngx-toastr";

import { ToastMessageContainerComponent } from "./toast-message-container/toast-message-container.component";
import { ToastMessageComponent } from "./toast-message/toast-message.component";

@NgModule({
  declarations: [ToastMessageContainerComponent, ToastMessageComponent],
  imports: [CommonModule, ToastContainerModule],
  exports: [ToastMessageContainerComponent],
})
export class ToastMessagesModule {}
