<div class="l-siteerror">
  <div class="l-containerhorizontal">
    <div class="l-siteerror-x-text">
      <p class="textbordered">404</p>
      <div class="textmessage">
        <h3 class="textmessage-x-heading">Page Not Found</h3>
        <p>
          Please make sure you're signed in and check your URL, or return to the
          home page.
        </p>
      </div>
    </div>
  </div>
  <div class="l-containerhorizontal wrap">
    <div class="l-siteerror-x-snippets">
      <div class="snippet">
        <h3 class="snippet-x-heading" id="snippet-faq">Have a Question?</h3>
        <p>Check out our frequently asked questions</p>
        <a
          class="button button-secondary"
          routerLink="/faqs"
          aria-labelledby="snippet-faq"
        >
          <span class="button-x-text">Read FAQs</span>
        </a>
      </div>
      <div class="snippet">
        <h3 class="snippet-x-heading" id="snippet-browse">
          Ready to get started?
        </h3>
        <p>Find micro-credentials that align with your needs and goals</p>
        <a
          class="button button-secondary"
          routerLink="/explore"
          aria-labelledby="snippet-browse"
        >
          <span class="button-x-text">Explore</span>
        </a>
      </div>
    </div>
  </div>
</div>
