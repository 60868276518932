import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "../authentication.guard";

import { DecisionCreateComponent } from "./decision-create.component";

const routes: Routes = [
  {
    path: "review/submission/:id/review",
    component: DecisionCreateComponent,
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DecisionCreateRoutingModule {}
