import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "../authentication.guard";

import { ProfileComponent } from "./profile.component";
import { PreviousRouteGuard } from "./previous-route.guard";

const routes: Routes = [
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthenticationGuard, PreviousRouteGuard],
    data: { title: "Profile" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
