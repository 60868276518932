import { Injectable, Injector } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ConfigService {
  static MESSAGE_FOREVER_TIMEOUT = 28800000;

  environment = environment;
  _window: any;

  constructor(private injector: Injector) {}

  public getConfig(key: string, defaultValue?: any): any {
    const envConfig = this.environment,
      appConfig = this.injector.get("config", null);

    let envConfigValue, appConfigValue;

    if (envConfig) {
      envConfigValue = envConfig[key];
    }

    if (appConfig) {
      appConfigValue = appConfig[key];
    }

    if (!appConfigValue && !envConfigValue) {
      if (defaultValue) {
        return defaultValue;
      }
      throw new Error(
        `This application must define a configuration object for key '${key}'`
      );
    }

    return envConfigValue || appConfigValue;
  }
}
