/* eslint-disable curly */
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Subscription, Subject, EMPTY } from "rxjs";
import { concatMap, tap, filter, catchError } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpEventType,
  HttpErrorResponse,
  HttpEvent,
} from "@angular/common/http";

import { LocalStorageService } from "ngx-webstorage";

import { ConfigService } from "../../../core/config.service";
import { SubmissionFile } from "../../../submission-file.type";
import { AssessmentQuestion } from "../../../assessment.type";
import { Error } from "../../../messages.constants";

import {
  guid,
  UploadFile,
  UPLOAD_FILE_STATE,
  LocalFilesService,
  ViewerService,
  FileService,
  LocalFile,
} from "../shared";

@Component({
  selector: "dig-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
  providers: [{ provide: ViewerService, useClass: LocalFilesService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigFileUploaderComponent implements OnInit, OnDestroy {
  @Input() question: AssessmentQuestion;
  @Input() value: SubmissionFile[] = [];
  @Input() disabled: boolean = false;
  @Output() uploaded: EventEmitter<SubmissionFile> =
    new EventEmitter<SubmissionFile>();
  @Output() removed: EventEmitter<SubmissionFile> =
    new EventEmitter<SubmissionFile>();
  @ViewChild("file", { static: true }) fileInput: ElementRef;
  private _listenFileUploads: Subscription;
  private filesUploadQueue$ = new Subject<UploadFile>();
  private _url: string;
  uploadedFiles: string[] = [];
  selectedFileIndex: number;
  submissionFileMap: { [guid: string]: SubmissionFile } = {};

  @Input() set url(url: string) {
    this._url = url;
  }

  getUploadFileByUrl(fileUrl: string): Promise<UploadFile> {
    return fetch(fileUrl).then((response) => {
      return response.blob().then((blobPart) => {
        const file = new File([blobPart], fileUrl, { type: blobPart.type });
        const uploadFile = new UploadFile(file, guid());
        uploadFile.state = UPLOAD_FILE_STATE.EDIT;
        uploadFile.progress = 100;
        return uploadFile;
      });
    });
  }

  get hasError() {
    return !!this.files.filter((f) => f.state === UPLOAD_FILE_STATE.FAILED)
      .length;
  }

  get url() {
    if (!this._url) {
      const apiHost = this.configService.getConfig("apiHost"),
        apiVersion = this.configService.getConfig("apiVersion");

      return `${apiHost}/api/${apiVersion}/educator/submission-file/`;
    } else return this._url;
  }

  dragAreaClass = "";

  files: UploadFile[] = [];

  @HostListener("dragover", ["$event"]) onDragOver(event) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event) {
    this.dragAreaClass = "";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event) {
    this.dragAreaClass = "";
    event.preventDefault();
    event.stopPropagation();

    const files: File[] = event.dataTransfer.files;
    this.handleFileChange(files);
  }

  onFileChange(event) {
    const files: File[] = event.target.files;
    this.handleFileChange(files);
  }

  removeFile(file: UploadFile) {
    const fileInput = this.fileInput.nativeElement;

    return this.deleteFile(file).subscribe((_: HttpEvent<SubmissionFile>) => {
      // Allow onchange when uploading the same file immediately after removing
      fileInput.value = "";

      const fileIndex = this.files.findIndex((f) => f.guid === file.guid);
      const carouselFileIndex = this.uploadedFiles.findIndex(
        (f) => JSON.parse(f).name === file.name
      );

      if (fileIndex === -1) return;

      this.files = [
        ...this.files.slice(0, fileIndex),
        ...this.files.slice(fileIndex + 1),
      ];

      this.uploadedFiles = [
        ...this.uploadedFiles.slice(0, carouselFileIndex),
        ...this.uploadedFiles.slice(carouselFileIndex + 1),
      ];
    });
  }

  deleteFile(file: UploadFile) {
    const submissionFile: SubmissionFile = this.submissionFileMap[file.guid];
    const authToken = this.localStorageService.retrieve("token");

    const request = new HttpRequest<SubmissionFile>(
      "DELETE",
      `${this.url}${submissionFile.id}/`,
      submissionFile,
      {
        headers: new HttpHeaders({ Authorization: `Token ${authToken}` }),
        withCredentials: true,
      }
    );

    return this.httpClient.request<SubmissionFile>(request).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((res) => {
        console.log("DELETE RESPONSE", res);
        this.removed.emit(submissionFile);
      }),
      catchError((err) => EMPTY)
    );
  }

  viewFile(file: UploadFile, index: number, carousel) {
    this.selectedFileIndex = index;
    carousel.openPanelWithBackdrop();
  }

  trackByRef(index: number, file: UploadFile) {
    const { guid, progress } = file;
    return [guid, progress].join("-");
  }

  handleFileChange(files: File[]) {
    this.selectedFileIndex = null;
    const _normalizedFiles = Array.from(files);

    if (_normalizedFiles && _normalizedFiles.length > 0) {
      let newFiles = [];
      _normalizedFiles.forEach((file) => {
        const newFile = new UploadFile(file, guid());
        newFiles = [...newFiles, newFile];
        if (file.size >= 20971520) {
          newFile.state = UPLOAD_FILE_STATE.FAILED;
          newFile.errorText = Error.UPLOAD_SIZE;
        }
      });
      this.files = [...this.files, ...newFiles];
      newFiles
        .filter((file) => file.state !== UPLOAD_FILE_STATE.FAILED)
        .forEach((file) => this.filesUploadQueue$.next(file));
    }
  }

  updateFile =
    (file: UploadFile) => (updateFn: (f: UploadFile) => UploadFile) => {
      const index = this.files.findIndex((f) => f.guid === file.guid);
      if (index === -1) return;

      const newFile = updateFn(new UploadFile(file.uploadedFile, file.guid));

      this.files = [
        ...this.files.slice(0, index),
        newFile,
        ...this.files.slice(index + 1),
      ];

      this.ref.markForCheck();
    };

  constructor(
    private ref: ChangeDetectorRef,
    private httpClient: HttpClient,
    private fileService: FileService,
    private configService: ConfigService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this._listenFileUploads = this.filesUploadQueue$
      .asObservable()
      .pipe(concatMap((file) => this.createUploadQueueItem(file)))
      .subscribe();

    const fetchFiles$ = this.value.map((submissionFile: SubmissionFile) => {
      return this.getUploadFileByUrl(submissionFile.file).then(
        (uploadFile: UploadFile) => {
          return this.fileService
            .getLocalFile(uploadFile.uploadedFile)
            .toPromise()
            .then((localFile: LocalFile) => {
              return Promise.resolve([submissionFile, uploadFile, localFile]);
            });
        }
      );
    });

    Promise.all(fetchFiles$).then(
      (fileInfos: [SubmissionFile, UploadFile, LocalFile][]) => {
        fileInfos.forEach(
          ([submissionFile, uploadFile, localFile]: [
            SubmissionFile,
            UploadFile,
            LocalFile
          ]) => {
            const fileUrl: string = submissionFile.file;

            this.submissionFileMap[uploadFile.guid] = submissionFile;
            this.uploaded.emit(submissionFile);

            /* Add to "courasel" via a stringified "LocalFile" and a file URL */
            const carouselLocalFile = localFile;
            carouselLocalFile.url = fileUrl;
            this.uploadedFiles = [
              ...this.uploadedFiles,
              JSON.stringify(carouselLocalFile),
            ];

            /* Add to internal representation of files */
            this.files = [...this.files, uploadFile];
            //this.filesUploadQueue$.next(uploadFile);
          }
        );
      }
    );
  }

  ngOnDestroy() {
    this._listenFileUploads.unsubscribe();
  }

  createUploadQueueItem(uploadFile: UploadFile) {
    const formData = new FormData();
    const authToken = this.localStorageService.retrieve("token");
    formData.append("file", uploadFile.uploadedFile, uploadFile.name);
    formData.append("assessment_question", this.question.id.toString());
    const request = new HttpRequest<FormData>("POST", this.url, formData, {
      headers: new HttpHeaders({ Authorization: `Token ${authToken}` }),
      reportProgress: true,
      withCredentials: true,
    });
    const update = this.updateFile(uploadFile);

    return this.httpClient.request<FormData>(request).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round((100 * event.loaded) / event.total);

          update((newFile) => {
            newFile.progress = progress;
            return newFile;
          });
        }
      }),
      filter((event) => event instanceof HttpResponse),
      tap((res) => {
        update((newFile) => {
          newFile.state = UPLOAD_FILE_STATE.EDIT;
          return newFile;
        });

        this.fileService
          .getLocalFile(uploadFile.uploadedFile)
          .subscribe((localFile: LocalFile) => {
            const submissionFile: SubmissionFile = res["body"];
            const fileUrl = submissionFile.file;

            this.submissionFileMap[uploadFile.guid] = submissionFile;
            this.uploaded.emit(submissionFile);

            const carouselFile = localFile;
            carouselFile.url = fileUrl;
            this.uploadedFiles = [
              ...this.uploadedFiles,
              JSON.stringify(carouselFile),
            ];
          });
      }),
      catchError((err) => {
        this.handleError(uploadFile, err);
        return EMPTY;
      })
    );
  }

  handleError(file: UploadFile, err: HttpErrorResponse) {
    this.updateFile(file)((newFile) => {
      newFile.state = UPLOAD_FILE_STATE.FAILED;
      newFile.errorText = Error.UPLOAD;
      return newFile;
    });
  }
}
