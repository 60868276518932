import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "fileName" })
export class FileNamePipe implements PipeTransform {
  transform(filePath: string) {
    let fileName = filePath.split("/").pop();

    if (fileName.indexOf("?") !== -1) {
      fileName = fileName.split("?")[0];
    }

    return fileName;
  }
}
