import { Component, OnInit, ViewChild } from "@angular/core";

import { ToastContainerDirective, ToastrService } from "ngx-toastr";

@Component({
  selector: "app-toast-message-container",
  templateUrl: "./toast-message-container.component.html",
  styleUrls: ["./toast-message-container.component.css"],
})
export class ToastMessageContainerComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true })
  toastMessageContainer: ToastContainerDirective;

  constructor(private toastrService: ToastrService) {}

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastMessageContainer;
  }
}
