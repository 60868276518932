<div *ngIf="submission && submissionComments" class="l-detail">
  <section *ngIf="formInitialized" class="l-detail-x-content">
    <app-section-overview
      [credential]="credential"
      [previousSubmissions]="previousSubmissions"
      [submission]="submission"
      [user]="user"
    ></app-section-overview>

    <div
      *ngIf="submission.resubmission_note"
      id="resubmission-note"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Resubmission Note</span></h2>
      <h3>Here's What Changed</h3>
      <p>
        A short explanation about the changes made since the last submission.
      </p>
      <div class="forminput-small forminput forminput-attachment">
        <!-- <label for="comment" class="forminput-x-label">Response</label> -->
        <div class="forminput-x-input" style="position: relative">
          <textarea
            placeholder="None provided"
            style="position: absolute; height: 100%"
            [value]="submission.resubmission_note"
            disabled
          ></textarea>
          <div
            style="visibility: hidden; padding: 8px; min-height: 164px"
            [innerHTML]="submission.resubmission_note | newlineToBr"
          ></div>
        </div>
      </div>
    </div>

    <form
      [formGroup]="recommendForm"
      (ngSubmit)="createRecommendation()"
      method="POST"
      action="/"
      role="form"
      novalidate
    >
      <div
        *ngIf="assessmentExtension?.description"
        id="assessment-extension-overall-description"
        class="l-detail-x-subsection wrap textblock"
      >
        <h2><span>Score Description</span></h2>
        <div [outerHTML]="assessmentExtension?.description | MarkdownIt"></div>
      </div>

      <app-sections-assessment-extension
        [recommendForm]="recommendForm"
        [rubricRecommendations]="rubricRecommendations"
        [recommendation]="recommendation"
        [assessmentExtension]="assessmentExtension"
        [fileEvidence]="fileEvidence"
        [urlEvidence]="urlEvidence"
        [textEvidence]="textEvidence"
        [previousRubricRecommendations]="previousRubricRecommendations"
        [submission]="submission"
        [comments]="submissionComments"
      ></app-sections-assessment-extension>

      <app-section-recommend-and-decide
        [formValid]="
          recommendForm.valid &&
          !recommendForm.value.request_additional_assessment
        "
        [canRecommend]="true"
        buttonText="Submit to Issuer"
        [recommendation]="recommendation"
        [decision]="decision"
        [assessmentExtension]="assessmentExtension"
        [submission]="submission"
        (requestAdditionalAssessment)="requestAdditionalAssessment($event)"
        [showRecommendationComment]="userIsStaff"
        [comments]="submissionComments"
        [ownAssess]="ownAssess"
        [parentForm]="recommendForm"
      ></app-section-recommend-and-decide>
    </form>
    <app-submission-history
      [entries]="submissionHistory"
    ></app-submission-history>
  </section>

  <app-navigation-sections
    completionText="Recommendation"
    [allSections]="allSections"
    (navigateTo)="navigateTo($event)"
    [showNote]="!!submission?.resubmission"
  ></app-navigation-sections>
</div>
