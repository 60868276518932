<div *ngIf="isViewMode()" class="comments">
  <div class="l-childrenhorizontal submission-comment-header">
    <div class="textinfo">
      <div class="submission-comment-header__label">
        {{ data.user_name }} | {{ data.created_at | date: "short" }}
        <span *ngIf="isEdited()">&nbsp;| *edited</span>
      </div>
    </div>
    <div>
      <button
        *ngIf="isEditable() || isDeletable()"
        class="button button-more button-secondary button-small"
        type="button"
        (click)="openDropdown(data.id); $event.stopPropagation()"
      >
        <span class="button-x-text">More Options</span>
      </button>
      <div
        class="l-menudesktop-x-profile l-menudropdown"
        id="menudropdown"
        [ngClass]="{
          'l-menudropdown-is-active': openedDropdownId === data.id
        }"
      >
        <div class="l-menudropdown-x-menu">
          <div class="dropdown">
            <button
              *ngIf="isEditable()"
              class="dropdownitem"
              (click)="onEdit()"
            >
              Edit
            </button>
            <button
              *ngIf="isDeletable()"
              class="dropdownitem"
              (click)="onRemove()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="textblock" [innerHtml]="data.comment | safeHtml"></div>
</div>
<div *ngIf="isEditMode()" class="comments">
  <form #form="ngForm" class="textblock list">
    <div class="forminput">
      <div class="forminput-x-input">
        <quill-editor
          [(ngModel)]="newComment"
          [styles]="{ height: '120px' }"
          name="edit-comment-{{ data.id }}"
          class="submission-comment-editor"
          placeholder="Enter your comment..."
        ></quill-editor>
      </div>
    </div>
    <div class="l-childrenhorizontal button--right">
      <button class="button" [disabled]="!newComment" (click)="onSubmit()">
        Update Comment
      </button>
      <button class="button button-secondary" (click)="onCancel()">
        Cancel
      </button>
    </div>
  </form>
</div>
