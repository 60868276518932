<div
  *ngIf="section.rubric"
  class="l-detail-x-subsectionbig forminput"
  [class.previous-was-wrong]="previousRecommendation?.implies_not_passing"
>
  <div class="wrap wrap-interactive3">
    <div class="textinfo textinfo-expanded textinfo-outer textinfo-outerblue">
      <header class="textinfo-x-header">
        <h5>Section Assessment</h5>
      </header>
      <div
        class="attachment attachment-big attachment-inner attachment-borderblue wrap"
      >
        <div class="textinfo-x-content">
          <div
            class="textinfo-x-section"
            *ngFor="
              let criterion of section.rubric.criterions;
              let criterionIndex = index
            "
          >
            <h3>
              Section {{ criterionIndex + 1
              }}<ng-container *ngIf="criterion.description">: </ng-container
              ><span *ngIf="criterion.description">
                {{ criterion.description }}</span
              >
            </h3>
            <div class="forminput forminput-small forminput-inputsspaced">
              <fieldset>
                <div
                  class="forminput-x-input"
                  *ngFor="
                    let criterionLevel of criterion.criterion_levels;
                    let criterionLevelIndex = index
                  "
                >
                  <div class="formradio formradio-bold">
                    <input
                      class="visuallyhidden"
                      type="radio"
                      [checked]="criterionLevels.includes(criterionLevel.id)"
                      (click)="
                        setRubricRecommendationCriterionLevel(
                          criterionLevel.id,
                          criterion.id
                        )
                      "
                      value="{{ criterion.id }},{{ criterionLevel.id }}"
                      name="section_{{ criterion.id }}"
                      id="section_{{ section.id }}_criterionlevel_{{
                        criterionLevel.id
                      }}"
                    />
                    <label
                      for="section_{{ section.id }}_criterionlevel_{{
                        criterionLevel.id
                      }}"
                    >
                      {{ criterionLevel.quality }}
                      <span
                        class="forminput-x-sublabel"
                        [innerHTML]="criterionLevel.description | newlineToBr"
                      ></span>
                    </label>
                  </div>
                </div>
              </fieldset>
              <app-rubric-comment-event
                (commentChange)="onCommentChange($event)"
                [criterion]="criterion"
                [comment]="criterionComments[criterion.id]"
                [isReadOnly]="false"
              >
              </app-rubric-comment-event>
              <app-submission-comments
                [data]="comments"
                [info]="commentsInfo[criterion.id]"
              >
                <hr class="comments__hr" />
                <label class="forminput-x-label"
                  >Assessor Comments (Private):</label
                >
              </app-submission-comments>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
