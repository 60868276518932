import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PaginationModule } from "./pagination/pagination.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { NgRepeat } from "./ngrepeat.directive";
import { EditCredentialModule } from "./edit-credential/edit-credential.module";

import { AssessorModalComponent } from "../review-page/assessor-modal/assessor-modal.component";
import { CredentialCardComponent } from "./credential-card/credential-card.component";
import { StatusFilterComponent } from "./status-filter/status-filter.component";
import { EmptyStateComponent } from "./empty-state/empty-state.component";
import { SearchMenuComponent } from "./search-menu/search-menu.component";
import { SubmissionHistoryComponent } from "./submission-history/submission-history.component";
import { BulkAssignmentModalComponent } from "../review-page/bulk-assignment-modal/bulk-assignment-modal.component";

import { SearchOptionDirective } from "./search-menu/search-option.directive";
import { SearchEmptyStateDirective } from "./search-menu/search-empty-state.directive";

import { IssuerAuthorizationGuard } from "./authorization/issuer-authorization.guard";
import { AssessorAuthorizationGuard } from "./authorization/assessor-authorization.guard";
import { AdminAuthorizationGuard } from "./authorization/admin-authorization.guard";

import { CapitalizePipe } from "./capitalize.pipe";
import { NotFoundComponent } from "./not-found/not-found.component";
import { CredentialResolveGuard } from "./resolvers/credential-resolve.guard";
import { SubmissionResolveGuard } from "./resolvers/submission-resolve.guard";
import { SubmissionDetailMixinModule } from "./submission-detail-mixin/submission-detail-mixin.module";
import { SubmissionLinksModule } from "./submission-links/submission-links.module";
import { AssessmentFormsModule } from "./assessment-forms/assessment-forms.module";
import { LoadingOverlayModule } from "./loading-overlay/loading-overlay.module";
import { NewlineToBrModule } from "./newline-to-br/newline-to-br.module";
import { NgxMarkdownItPipe } from "./ngx-markdown-it.pipe";
import { RemoveTextPipe } from "./remove-text.pipe";
import { PardotService } from "./pardot.service";

import { PreviousSubmissionsTableModule } from "./previous-submissions-table/previous-submissions-table.module";
import { NgxFocusTrapModule } from "ngx-focus-trap";
import { ToastMessagesModule } from "./toast-messages/toast-messages.module";
import { QuillModule } from "ngx-quill";
import { SubmissionCommentsModule } from "./submission-comments/submission-comments.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PaginationModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayModule,
    PortalModule,
    EditCredentialModule,
    SubmissionCommentsModule,
    SubmissionDetailMixinModule,
    SubmissionLinksModule,
    AssessmentFormsModule,
    LoadingOverlayModule,
    NewlineToBrModule,
    PreviousSubmissionsTableModule,
    NgxFocusTrapModule,
    ToastMessagesModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ list: "ordered" }, { list: "bullet" }],
          ["clean"],
        ],
      },
    }),
  ],
  declarations: [
    NgRepeat,
    AssessorModalComponent,
    BulkAssignmentModalComponent,
    StatusFilterComponent,
    CredentialCardComponent,
    EmptyStateComponent,
    CapitalizePipe,
    SearchMenuComponent,
    SearchOptionDirective,
    SearchEmptyStateDirective,
    NotFoundComponent,
    SubmissionHistoryComponent,
    NgxMarkdownItPipe,
    RemoveTextPipe,
  ],
  exports: [
    CommonModule,
    NgRepeat,
    PaginationModule,
    AssessorModalComponent,
    BulkAssignmentModalComponent,
    StatusFilterComponent,
    CredentialCardComponent,
    EmptyStateComponent,
    CapitalizePipe,
    SearchMenuComponent,
    SubmissionHistoryComponent,
    SearchOptionDirective,
    SearchEmptyStateDirective,
    NotFoundComponent,
    EditCredentialModule,
    SubmissionCommentsModule,
    SubmissionLinksModule,
    AssessmentFormsModule,
    LoadingOverlayModule,
    NewlineToBrModule,
    NgxMarkdownItPipe,
    RemoveTextPipe,
    PreviousSubmissionsTableModule,
    NgxFocusTrapModule,
    ToastMessagesModule,
  ],
  providers: [
    AssessorAuthorizationGuard,
    IssuerAuthorizationGuard,
    AdminAuthorizationGuard,
    CredentialResolveGuard,
    SubmissionResolveGuard,
    PardotService,
  ],
})
export class SharedModule {}
