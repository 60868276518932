import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "./shared/core/user.service";

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginOrGetUserThenGo(next, state.url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginOrGetUserThenGo(next, state.url);
  }

  loginOrGetUserThenGo(route, url): Promise<boolean> {
    const token = route.queryParamMap.get("token"),
      ssoToken = route.queryParamMap.get("access_token");

    return this.userService.login(token, ssoToken).then((success: boolean) => {
      if (token && ssoToken) {
        const noParamUrl: string = url.replace(/\?.*/, ""); // Path without query params
        this.router.navigateByUrl(noParamUrl);
      }
      return true;
    });
  }
}
