<div
  class="l-dialog dialog"
  [ngClass]="{ 'dialog-is-open': modalIsOpen }"
  aria-modal="true"
  role="dialog"
  ngxFocusTrap
  #ngxFocus="ngxFocusTrap"
  [initActivated]="false"
>
  <div class="dialog-x-content wrap">
    <div class="dialog-x-close">
      <button aria-labelledby="" type="button" (click)="closeDialog()">
        Close Dialog
      </button>
    </div>
    <form
      class="l-dialogform"
      [formGroup]="credentialForm"
      (ngSubmit)="onSubmit()"
      novalidate
    >
      <div class="dialogheader">
        <h3 class="dialogheader-x-heading">Edit Micro-credential</h3>
        <p></p>
      </div>
      <div class="l-dialogoverview" *ngIf="credential">
        <div class="l-dialogoverview-x-image">
          <img
            [src]="credential.image"
            alt="Credential Image"
            width="48"
            height="48"
          />
        </div>
        <div class="l-dialogoverview-x-content">
          <div class="l-dialogoverview-x-header">
            <p>{{ credential.name }}</p>
          </div>
          <ul>
            <li>
              <a class="icon icon-organization small">
                <span class="icon-x-text">{{
                  credential.organization.name
                }}</span>
              </a>
            </li>
            <li *ngIf="credential.tag">
              <a class="icon icon-stack small">
                <span class="icon-x-text">{{ credential.tag }}</span>
              </a>
            </li>
            <li>MC ID: {{ credential.readable_id }}</li>
          </ul>
        </div>
      </div>
      <div class="message message-error l-message" *ngIf="noAssessment">
        <h3 class="visuallyhidden" id="message">Form Message</h3>
        <p>
          There is no assessment associated with this Micro-credential. Until
          there is an assessment, this micro-credential cannot accept
          submissions.
        </p>
      </div>
      <div
        class="forminput"
        [ngClass]="{ 'forminput-x-error': credentialForm.get('price').errors }"
      >
        <legend for="price" class="forminput-x-label">
          Fee <span>(Must be {{ minPrice.toFixed(2) }} or more, or 0.00)</span>
        </legend>
        <div class="forminput-x-input">
          <input
            type="number"
            min="0"
            name="price"
            id="price"
            placeholder="$0.00"
            formControlName="price"
          />
          <p
            class="forminput-x-errortext"
            *ngIf="credentialForm.get('price').errors"
          >
            <span
              >Value must be {{ minPrice.toFixed(2) }} or more, or 0.00</span
            >
          </p>
        </div>
      </div>
      <div
        class="forminput"
        [ngClass]="{
          'forminput-x-error':
            credentialForm.errors?.lessThanPrice ||
            credentialForm.get('resubmission_price').errors
        }"
      >
        <legend for="resubmission_price" class="forminput-x-label">
          Resubmission Fee
          <span
            >(Must be {{ minPrice.toFixed(2) }} or more, or 0.00 and not more
            than the price above)</span
          >
        </legend>
        <div class="forminput-x-input">
          <input
            type="number"
            min="0"
            name="resubmission_price"
            id="resubmission_price"
            placeholder="$0.00"
            formControlName="resubmission_price"
          />
          <p
            class="forminput-x-errortext"
            *ngIf="credentialForm.get('resubmission_price').errors"
          >
            <span
              >Value must be {{ minPrice.toFixed(2) }} or more, or 0.00</span
            >
          </p>
          <p
            class="forminput-x-errortext"
            *ngIf="credentialForm.errors?.lessThanPrice"
          >
            <span>Value must be less than or equal to the price above</span>
          </p>
        </div>
      </div>
      <fieldset class="forminput">
        <legend class="forminput-x-label">Availability</legend>
        <div class="forminput-x-input" *ngIf="user">
          <div class="formradio">
            <input
              class="visuallyhidden"
              type="radio"
              id="availabilityPublic"
              formControlName="availability"
              [attr.disabled]="
                (!user.is_admin &&
                user.is_issuer &&
                credentialForm.get('availability').value === 'locked'
                  ? true
                  : null) || noAssessment
                  ? true
                  : null
              "
              value="public"
              name="availability"
            />
            <label for="availabilityPublic"
              >Public - Accepting submission</label
            >
          </div>
          <div class="formradio">
            <input
              class="visuallyhidden"
              type="radio"
              id="availabilityPrivate"
              formControlName="availability"
              [attr.disabled]="
                (!user.is_admin &&
                user.is_issuer &&
                credentialForm.get('availability').value === 'locked'
                  ? true
                  : null) || noAssessment
                  ? true
                  : null
              "
              value="private"
              name="availability"
            />
            <label for="availabilityPrivate"
              >Private - Accepting submission</label
            >
          </div>
          <div
            class="formradio"
            *ngIf="
              !user.is_admin &&
              user.is_issuer &&
              credentialForm.get('availability').value !== 'locked'
            "
          >
            <input
              class="visuallyhidden"
              type="radio"
              id="availabilityInactive"
              formControlName="availability"
              value="inactive"
              [attr.disabled]="noAssessment ? true : null"
              name="availability"
            />
            <label for="availabilityInactive"
              >Inactive - Unavailable and doesn't accept submissions</label
            >
          </div>
          <div
            class="formradio"
            *ngIf="
              !user.is_admin &&
              user.is_issuer &&
              credentialForm.get('availability').value === 'locked'
            "
          >
            <input
              class="visuallyhidden"
              type="radio"
              id="availabilityLocked"
              formControlName="availability"
              value="locked"
              [attr.disabled]="true"
              name="availability"
            />
            <label for="availabilityLocked"
              >Inactive - Unavailable and doesn't accept submissions
              <span class="forminput-x-sublabel"
                >Contact Digital Promise Admin to modify this
                micro-credential.</span
              ></label
            >
          </div>

          <div class="formradio" *ngIf="user.is_admin">
            <input
              class="visuallyhidden"
              type="radio"
              formControlName="availability"
              id="availabilityInactive"
              value="inactive"
              [attr.disabled]="noAssessment ? true : null"
              name="availability"
            />
            <label for="availabilityInactive"
              >Inactive - Unavailable and doesn't accept submissions</label
            >
          </div>
          <div class="formradio" *ngIf="user.is_admin">
            <input
              class="visuallyhidden"
              type="radio"
              formControlName="availability"
              id="availabilityLocked"
              value="locked"
              [attr.disabled]="noAssessment ? true : null"
              name="availability"
            />
            <label for="availabilityLocked"
              >Inactive - Do not allow Issuer override</label
            >
          </div>
        </div>
      </fieldset>
      <div class="l-dialogform-x-button">
        <button
          class="button"
          type="submit"
          [disabled]="credentialForm.pristine || credentialForm.invalid"
        >
          <span class="button-x-text">Save Changes</span>
        </button>
      </div>
      <app-dialog-history [entries]="historyData | async"></app-dialog-history>
    </form>
  </div>
</div>
