import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DialogHistoryComponent } from "./dialog-history.component";

@NgModule({
  imports: [CommonModule],
  exports: [DialogHistoryComponent],
  declarations: [DialogHistoryComponent],
  providers: [],
})
export class DialogHistoryModule {}
