import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { flatten, values } from "lodash";
import { ContentChange } from "ngx-quill";

import { AssessmentQuestion } from "../../assessment.type";
import { PreviousRecommendationRubricMixin } from "../../previous-recommendation-rubric-mixin/previous-recommendation-rubric.mixin";

@Component({
  selector: "app-rubric-question-assess-form",
  templateUrl: "./rubric-question-assess-form.component.html",
  styleUrls: [
    "./rubric-question-assess-form.component.css",
    "../../submission-comments/submission-comments.component.css",
  ],
})
export class RubricQuestionAssessFormComponent
  extends PreviousRecommendationRubricMixin
  implements OnInit
{
  assessmentType = "question";
  criterionComments = {};
  criterionLevels = {};
  @Input() question: AssessmentQuestion;
  @Input() parentForm: UntypedFormGroup;
  questionRubricCriterionLevel: UntypedFormControl = new UntypedFormControl();

  ngOnInit() {
    this.commentsInfo = this.getCommentsInfo(
      this.question.rubric?.criterions || []
    );
    if (this.question.rubric) {
      this.criterionComments = this.parentForm.controls.rubric_recommendations
        .get(this.question.rubric.formArrayIndex.toString())
        .get("comments").value;
      this.criterionLevels = flatten(
        values(
          this.parentForm.controls.rubric_recommendations
            .get(this.question.rubric.formArrayIndex.toString())
            .get("criterion_levels").value
        )
      );
    }
  }

  // TODO: better to move to truly reactive forms and not use them only as store
  onCommentChange(event: ContentChange) {
    this.parentForm.controls.rubric_recommendations
      .get(this.question.rubric.formArrayIndex.toString())
      .get("comments")
      .get(event.source)
      .setValue(event.html);
  }

  setRubricRecommendationCriterionLevel(
    criterionLevelId: number,
    criterionId: number
  ) {
    this.parentForm.controls.rubric_recommendations
      .get(this.question.rubric.formArrayIndex.toString())
      .get("criterion_levels")
      .get(`criterion_${criterionId}`)
      .setValue(criterionLevelId);
  }
}
