import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ShareTokenCreateRoutingModule } from "./share-token-create-routing.module";
import { ShareTokenCreateComponent } from "./share-token-create.component";
import { ShareTokenCreateService } from "./share-token-create.service";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [CommonModule, ShareTokenCreateRoutingModule, SharedModule],
  declarations: [ShareTokenCreateComponent],
  providers: [ShareTokenCreateService],
})
export class ShareTokenCreateModule {}
