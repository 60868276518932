import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { TryLoginGuard } from "../try-login.guard";
import { SiteGuard } from "../site.guard";

import { HomepageComponent } from "./homepage.component";

const routes: Routes = [
  {
    path: "",
    component: HomepageComponent,
    canActivate: [TryLoginGuard, SiteGuard],
    data: { title: "Digital Promise" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomepageRoutingModule {}
