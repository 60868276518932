<section class="statusfilters wrap l-statusfilters l-containerhorizontal">
  <h3 class="visuallyhidden" id="statusfilters">Filter by Status</h3>
  <div class="statusfilters-x-filter">
    <button
      routerLink="awarded"
      routerLinkActive="statusfilter-is-active"
      class="statusfilter statusfilter-progress"
      type="button"
    >
      <span class="statusfilter-x-text">Awarded</span>
      <span class="statusfilter-x-count" *ngIf="submissionCounts">
        <span class="visuallyhidden">(Contains: </span>
        <span class="statuscount statuscount-confirm">{{
          submissionCounts.awarded_count
        }}</span>
        <span class="visuallyhidden">, Is Active) </span>
      </span>
    </button>
  </div>
  <div class="statusfilters-x-filter">
    <button
      routerLink="drafts"
      routerLinkActive="statusfilter-is-active"
      class="statusfilter statusfilter-progress"
      type="button"
    >
      <span class="statusfilter-x-text">Drafts</span>
      <span class="statusfilter-x-count" *ngIf="submissionCounts">
        <span class="visuallyhidden">(Contains: </span>
        <span class="statuscount statuscount-confirm">{{
          submissionCounts.drafts_count
        }}</span>
        <span class="visuallyhidden">, Is Active) </span>
      </span>
    </button>
  </div>
  <div class="statusfilters-x-filter">
    <button
      routerLink="all"
      routerLinkActive="statusfilter-is-active"
      class="statusfilter statusfilter-all"
      type="button"
    >
      <span class="statusfilter-x-text">All</span>
      <span class="statusfilter-x-count" *ngIf="submissionCounts">
        <span class="visuallyhidden">(Contains: </span>
        <span class="statuscount statuscount-all">{{
          submissionCounts.all_count
        }}</span>
        <span class="visuallyhidden">, Is Active) </span>
      </span>
    </button>
  </div>
</section>

<router-outlet></router-outlet>
