import { Component, HostBinding, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";

import { Submission } from "../../submission.type";
import { Credential } from "../../credential.type";
import { SubmissionDecision } from "../../submission-decision.type";
import { User } from "../../user.type";
import { SitetypeService } from "app/shared/core/sitetype.service";

@Component({
  selector: "[decision-status-bar]",
  templateUrl: "./decision-status-bar.component.html",
  styleUrls: ["./decision-status-bar.component.css"],
})
export class DecisionStatusBarComponent implements OnInit, OnChanges {
  @Input() decision: SubmissionDecision;
  @Input() submission: Submission;
  @Input() user: User;
  @HostBinding("class.submissionstate-confirm") confirmState = false;
  @HostBinding("class.submissionstate-leveled") leveledState = false;
  @HostBinding("class.submissionstate-error") errorState = false;
  ownAward = false;
  awardedCredential: Credential;
  isLeveled = false;
  isStaff = false;
  stars = [];
  max_stars = [];
  credential_certification = "";

  constructor(private siteType: SitetypeService) {}

  ngOnInit() {
    this.siteType.siteType$.subscribe((siteType: string) => {
      var certification  = this.siteType.getCredentialsCertification();
      this.credential_certification = certification;
    })
    
    if (this.decision) {
      if((this.submission.credential as Credential).apply_only) {
        this.isLeveled = true;
        this.confirmState = this.decision.to_award && this.submission.related_level_badge.level === this.submission.related_level_badge.max_level;
        this.leveledState = this.decision.to_award && this.submission.related_level_badge.level !== this.submission.related_level_badge.max_level;
        this.errorState = !this.decision.to_award;
        if (this.submission && this.submission.related_level_badge.level) {
          this.stars = Array(this.submission.related_level_badge.level);
          this.max_stars = Array(this.submission.related_level_badge.max_level - this.submission.related_level_badge.level);
        }
        this.awardedCredential = this.submission.related_level_badge.credential;
      } else {
        this.confirmState = this.decision.to_award;
        this.leveledState = false;
        this.errorState = !this.decision.to_award;
        if (typeof this.submission.credential !== "number") {
          this.awardedCredential = this.submission.credential;
        }
      }
    }

    this.setAwardDisplay();
  }

  setAwardDisplay() {
    if (this.user) {
      this.isStaff =
        this.user.is_assessor || this.user.is_issuer || this.user.is_admin;
    }

    if (this.submission && this.user) {
      this.ownAward = (<User>this.submission.user).id === this.user.id;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const changeUser = changes["user"];
    const changeSubmission = changes["submission"];
    if (changeUser || changeSubmission) {
      this.setAwardDisplay();
    }
  }
}
