<div class="explore-more">
    <h1>Ready for more?</h1>

    <div class="button-shadow-container">
        <div class="button-shadow"></div>
        <a routerLink="/explore"
        class="button button-secondary  l-overview-x-buttonicon">
            Explore all of Digital Promise’s {{ credentialName }}s
            <span class="icon icon-chevron-right"></span>
        </a>
    </div>
</div>
