import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { NgxMarkdownItPipe } from "../ngx-markdown-it.pipe";
import { NewlineToBrModule } from "../newline-to-br/newline-to-br.module";

import { RubricQuestionComponent } from "./rubric-question/rubric-question.component";
import { RubricSectionComponent } from "./rubric-section/rubric-section.component";
import { RubricQuestionAssessedComponent } from "./rubric-question-assessed/rubric-question-assessed.component";
import { RubricSectionAssessedComponent } from "./rubric-section-assessed/rubric-section-assessed.component";
import { DecisionStatusBarComponent } from "./decision-status-bar/decision-status-bar.component";
import { SectionOverviewComponent } from "./section-overview/section-overview.component";
import { SubsectionRecommendationFormComponent } from "./subsection-recommendation-form/subsection-recommendation-form.component";
import { SubsectionDecisionFormComponent } from "./subsection-decision-form/subsection-decision-form.component";
import { SectionsAssessmentExtensionComponent } from "./sections-assessment-extension/sections-assessment-extension.component";
import { RubricQuestionAssessFormComponent } from "./rubric-question-assess-form/rubric-question-assess-form.component";
import { RubricSectionAssessFormComponent } from "./rubric-section-assess-form/rubric-section-assess-form.component";
import { SectionRecommendAndDecideComponent } from "./section-recommend-and-decide/section-recommend-and-decide.component";
import { NavigationSectionsComponent } from "./navigation-sections/navigation-sections.component";
import { AnswerInputFormComponent } from "./answer-input-form/answer-input-form.component";
import { QuestionTextComponent } from "./question-text/question-text.component";
import { PreviousNotPassingRubricRecommendationComponent } from "./previous-not-passing-rubric-recommendation/previous-not-passing-rubric-recommendation.component";

import { DigViewerModule } from "../file-uploader/lib/dig-viewer.module";
import { SubmissionLinksModule } from "../submission-links/submission-links.module";
import { PreviousSubmissionsTableModule } from "../previous-submissions-table/previous-submissions-table.module";

import { NewlineToBrPipe } from "../newline-to-br/newline-to-br.pipe";
import { SubmissionCommentsModule } from "../submission-comments/submission-comments.module";
import { QuillModule } from "ngx-quill";
import { RubricCommentComponent } from "./rubcric-comment/rubric-comment.component";
import { RubricCommentEventComponent } from "./rubcric-comment/rubric-comment-event.component";
import { LeveledRecommendationComponent } from './leveled-recommendation/leveled-recommendation.component';
import { BooleanRecommendationComponent } from './boolean-recommendation/boolean-recommendation.component';
import { ExploreMoreComponent } from './explore-more/explore-more.component';
import { AutomatedScoreCalculatorComponent } from './automated-score-calculator/automated-score-calculator.component';

@NgModule({
  imports: [
    DigViewerModule.forRoot(),
    SubmissionLinksModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NewlineToBrModule,
    PreviousSubmissionsTableModule,
    SubmissionCommentsModule,
    QuillModule,
  ],
  exports: [
    RubricQuestionComponent,
    RubricSectionComponent,
    RubricQuestionAssessedComponent,
    RubricSectionAssessedComponent,
    DecisionStatusBarComponent,
    SubsectionRecommendationFormComponent,
    SubsectionDecisionFormComponent,
    SectionsAssessmentExtensionComponent,
    RubricQuestionAssessFormComponent,
    RubricSectionAssessFormComponent,
    SectionRecommendAndDecideComponent,
    NavigationSectionsComponent,
    AnswerInputFormComponent,
    QuestionTextComponent,
    SectionOverviewComponent,
    PreviousNotPassingRubricRecommendationComponent,
    ExploreMoreComponent,
  ],
  declarations: [
    RubricQuestionComponent,
    RubricSectionComponent,
    RubricQuestionAssessedComponent,
    RubricSectionAssessedComponent,
    DecisionStatusBarComponent,
    SubsectionRecommendationFormComponent,
    SubsectionDecisionFormComponent,
    SectionsAssessmentExtensionComponent,
    RubricQuestionAssessFormComponent,
    RubricSectionAssessFormComponent,
    SectionRecommendAndDecideComponent,
    NavigationSectionsComponent,
    AnswerInputFormComponent,
    QuestionTextComponent,
    SectionOverviewComponent,
    PreviousNotPassingRubricRecommendationComponent,
    RubricCommentComponent,
    RubricCommentEventComponent,
    LeveledRecommendationComponent,
    BooleanRecommendationComponent,
    ExploreMoreComponent,
    AutomatedScoreCalculatorComponent,
  ],
  providers: [NewlineToBrPipe, NgxMarkdownItPipe],
})
export class AssessmentFormsModule {}
