import {
  Component,
  Input,
  Optional,
  HostBinding,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  ControlContainer,
} from "@angular/forms";

import { ToastrService } from "ngx-toastr";

import { DecisionCreateService } from "../../../decision-create/decision-create.service";

import {
  AssessmentSection,
  RubricCriterion,
  RubricCriterionLevel,
} from "../../assessment.type";
import { SubmissionRecommendationRubric } from "../../submission-recommendation-rubric.type";
import { PreviousRecommendationRubricMixin } from "../../previous-recommendation-rubric-mixin/previous-recommendation-rubric.mixin";
import { CommentSubmitter } from "app/shared/comment.type";

@Component({
  selector: "app-rubric-section-assessed",
  templateUrl: "./rubric-section-assessed.component.html",
  styleUrls: [
    "./rubric-section-assessed.component.css",
    "../../submission-comments/submission-comments.component.css",
  ],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class RubricSectionAssessedComponent
  extends PreviousRecommendationRubricMixin
  implements OnInit
{
  assessmentType = "section";
  @Input() section: AssessmentSection;

  @HostBinding("attr.class") className = "l-detail-x-subsectionbig";

  private criterionLevelId: UntypedFormControl = new UntypedFormControl();

  editing = false;
  form: UntypedFormGroup;
  @Input() showEditButton = false;
  @Input() showAssessorComment = false;
  @Output() updated: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() notEditing: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Optional() private control: ControlContainer,
    private messageService: ToastrService,
    private fb: UntypedFormBuilder,
    private decisionCreateService: DecisionCreateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.section.rubric) {
      const rubricRecommendation: void | SubmissionRecommendationRubric =
        this.getRubricRecommendation(
          this.rubricRecommendations,
          `assessment ${this.assessmentType}`,
          this.section.id,
          this.section.rubric.id
        );

      if (rubricRecommendation) {
        this.rubricRecommendation = rubricRecommendation;
        this.instantiateForm(rubricRecommendation);
      }
      this.commentsInfo = this.getCommentsInfo(
        this.section.rubric.criterions || []
      );
    }
  }

  instantiateForm(
    rubricRecommendation: SubmissionRecommendationRubric,
    comments?: CommentSubmitter[]
  ) {
    this.form = this.fb.group({
      id: rubricRecommendation.id,
      comments: this.fb.group(
        this.getFormSubmitterComments(this.section.rubric, comments)
      ),
      criterion_levels: this.fb.array(
        rubricRecommendation.criterion_levels.map((criterionLevelId: number) =>
          this.fb.control(criterionLevelId)
        )
      ),
      assessment_type: rubricRecommendation.assessment_type.replace(" ", ""),
      assessment_type_pk: rubricRecommendation.assessment_type_pk,
      recommendation: rubricRecommendation.recommendation,
      rubric: rubricRecommendation.rubric,
    });
  }

  setRubricRecommendationCriterionLevel(
    newCriterionLevelId: number,
    ofRubricCriterion: RubricCriterion,
    criterionIndex: number
  ) {
    const chosenRubricCriterionLevels =
        this.form.controls.criterion_levels.value,
      thisRubricCriterionLevels = ofRubricCriterion.criterion_levels.map(
        (criterionLevel: RubricCriterionLevel) => criterionLevel.id
      );

    let swappedCriterionLevelChoice = false;
    const newRubricCriterionLevels = chosenRubricCriterionLevels.map(
      (criterionLevelId: number) => {
        if (thisRubricCriterionLevels.includes(criterionLevelId)) {
          swappedCriterionLevelChoice = true;
          return newCriterionLevelId;
        } else {
          return criterionLevelId;
        }
      }
    );

    const formArray = this.form.get("criterion_levels") as UntypedFormArray;
    formArray.setValue(newRubricCriterionLevels);

    if (!swappedCriterionLevelChoice) {
      const insertAt = criterionIndex || newRubricCriterionLevels.length;
      formArray.insert(insertAt, this.fb.control(newCriterionLevelId));
    }
  }

  startUpdateRubricRecommendation() {
    this.editing = true;
    this.isEditing.emit(true);
  }

  exitUpdateRubricRecommendation(comments?: CommentSubmitter[]) {
    this.editing = false;
    this.notEditing.emit(true);
    this.instantiateForm(this.rubricRecommendation, comments);
  }

  saveUpdateRubricRecommendation() {
    if (this.rubricRecommendation && this.form) {
      const data = this.getRequestData(this.form.value);
      this.decisionCreateService
        .putRecommendationRubric(data)
        .then((rubricRecommendation: SubmissionRecommendationRubric) => {
          this.rubricRecommendation = rubricRecommendation;
          this.exitUpdateRubricRecommendation(data.comments);
          this.updated.emit(true);
          this.messageService.success(
            "Assessor's rubric recommendation updated"
          );
        })
        .catch((err) => {
          this.exitUpdateRubricRecommendation();
          this.messageService.error(
            "Failed to update assessor's rubric recommendation",
            ""
          );
        });
    }
  }
}
