import { Component, Host, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { LevelBadgeType } from 'app/shared/assessment.type';
import {v4 as uuidv4} from 'uuid';
import { Credential } from "app/shared/credential.type";
import { CredentialDetailService } from "app/credential-detail/credential-detail.service";
import { ControlContainer, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { EventEmitter } from 'stream';
import { SubmissionRecommendation } from 'app/shared/submission-recommendation.type';
import { SubmissionDecision } from 'app/shared/submission-decision.type';


@Component({
  selector: 'app-leveled-recommendation',
  templateUrl: './leveled-recommendation.component.html',
  styleUrls: ['./leveled-recommendation.component.css']
})
export class LeveledRecommendationComponent implements OnInit, OnChanges {
  @Input() credential: Credential;
  @Input() isRecommended: boolean;
  @Input() editing: boolean;
  @Input() form: UntypedFormControl;
  @Input() decision: SubmissionDecision;
  @Input() recommendation: SubmissionRecommendation;
  @Input() canCreate: boolean;
  @Input() canDecide: boolean;
  @Input() skippedSections: Number[];
  @Input() isDecision: boolean;

  leveledRecommendation: UntypedFormControl = new UntypedFormControl();
  leveledBadges: LevelBadgeType[];
  skippedBadges: LevelBadgeType[] = [];
  max_level = 0;
  name_setup = 'recommendationToAward'
  disabled = false;
  pointed = false;
  passing_score = 0;

  slug = '';
  constructor(
    
    @Optional() public container: ControlContainer,
    private fb: UntypedFormBuilder,
    private credentialDetailService: CredentialDetailService,
  ) { 
   

  }

  updateDisabled(): void{
    if(this.editing){
      this.disabled = false;
    }else{
      this.disabled = (this.isRecommended && !this.editing) || (this.canDecide !== undefined && !this.canDecide) || !this.canCreate;
    
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
   
    this.updateDisabled();
    
  }
  
  ngOnInit(): void {
    
    this.slug = this.credential?.slug;
    this.name_setup = uuidv4()
    this.updateDisabled();
    this.credentialDetailService
    .getCredentialLeveled(this.slug )
    .then((leveled_badges: LevelBadgeType[]) => {
      this.leveledBadges = leveled_badges;
      this.max_level = this.leveledBadges[0].level;
      if(this.leveledBadges[0].level_badge_type == 'point'){
        this.pointed = true;
        this.leveledBadges[0].high_score = 100;
        this.leveledBadges[0].low_score = this.leveledBadges[0].passing_score;
        for(let i = this.leveledBadges.length-1; i >= 0; i--){
          if(i == this.leveledBadges.length-1){
            this.passing_score = this.leveledBadges[i].passing_score-1;
          }
          this.leveledBadges[i].high_score = (this.leveledBadges[i-1].passing_score-1);
          this.leveledBadges[i].low_score=this.leveledBadges[i].passing_score;
        }
      }
      for(let i = 0; i < this.leveledBadges.length; i++){
        if(this.leveledBadges[i].level_badge_type == 'section'){
          if(this.skippedSections.includes(this.leveledBadges[i].assessment_section)){
            
            this.skippedBadges.push(this.leveledBadges[i]);
          }
        }
      }
      for(let i = 0; i < this.skippedBadges.length; i++){
        this.leveledBadges.splice(this.leveledBadges.indexOf(this.skippedBadges[i]), 1);
      }
    });
    if(this.recommendation){
      this.leveledRecommendation.setValue(this.recommendation.leveled_badge);
    }
    if (!this.isRecommended && this.container && this.canCreate) {
      this.leveledRecommendation.valueChanges.subscribe((value: number) => {
        if(value > 0){
          this.container.control.get("to_award").setValue(true);
          this.container.control.get("leveled_badge").setValue(value);
        }else{
          this.container.control.get("to_award").setValue(false);
        }
      });
    }
    if (this.isRecommended && this.container && this.canCreate) {
      this.leveledRecommendation.valueChanges.subscribe((value: number) => {
        if(value > 0){
          this.container.control.get("to_award").setValue(true);
          this.container.control.get("leveled_badge").setValue(value);
        }else{
          this.container.control.get("to_award").setValue(false);
        }
      });
    }
    if(this.form){
      this.leveledRecommendation.valueChanges.subscribe((value: number) => {
        if(value > 0){
          this.form.get("to_award").setValue(true);
          this.form.get("leveled_badge").setValue(value);
        }else{
          this.form.get("to_award").setValue(false);
          this.form.get("leveled_badge").setValue(null);
        }
      });
    }
  }

}
