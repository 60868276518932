import { Component, ApplicationRef } from "@angular/core";

import { ToastNoAnimation, ToastrService, ToastPackage } from "ngx-toastr";

@Component({
  selector: "app-toast-message",
  templateUrl: "./toast-message.component.html",
  styleUrls: ["./toast-message.component.css"],
})
export class ToastMessageComponent extends ToastNoAnimation {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    public appRef: ApplicationRef
  ) {
    super(toastrService, toastPackage, appRef);
  }
}
