import { Component, OnInit } from "@angular/core";

import { ProgressPageService } from "./progress-page.service";

@Component({
  selector: "app-progress-page",
  templateUrl: "./progress-page.component.html",
  styleUrls: ["./progress-page.component.css"],
})
export class ProgressPageComponent implements OnInit {
  submissionCounts: any;

  constructor(private progressPageService: ProgressPageService) {}

  ngOnInit() {
    this.progressPageService
      .getSubmissionCounts()
      .then((submissionCounts: any) => {
        this.submissionCounts = submissionCounts;
      });
  }
}
