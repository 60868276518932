import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

import { ApiGetService } from "../core/apiget.service";
import { Submission } from "../submission.type";

@Injectable()
export class SubmissionResolveGuard implements Resolve<Submission | void> {
  constructor(private apiGetService: ApiGetService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Submission | void> | Promise<Submission | void> {
    const submissionId = route.paramMap.get("id");

    return this.apiGetService
      .get<Submission>(`submission/${submissionId}`)
      .catch(() => {});
  }
}
