<div
  class="textinfo-x-comment rubric-comment__header"
  *ngIf="isReadOnly && !isEmpty(comment)"
>
  <h4>Submission Feedback:</h4>
  <div class="textblock" [innerHtml]="comment | safeHtml"></div>
</div>
<div formGroupName="comments" class="textinfo-x-comment" *ngIf="!isReadOnly">
  <label class="forminput-x-label rubric-comment__header"
    >Submission Feedback:</label
  >
  <quill-editor
    [formControlName]="criterion.id"
    [styles]="{ height: '120px' }"
    class="submission-comment-editor"
    name="criterion_comment_{{ criterion.id }}"
    placeholder="Enter your response..."
  >
  </quill-editor>
</div>
