import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "./shared/core/user.service";
import { ConfigService } from "./shared/core/config.service";

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private configService: ConfigService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated(next, state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated(next, state.url);
  }

  isAuthenticated(route, url): Promise<boolean> {
    const token = route.queryParamMap.get("token"),
      ssoToken = "NOT_REQUIRED";

    return this.userService.login(token, ssoToken).then((success: boolean) => {
      if (!success) {
        window.location.href =
          this.configService.getConfig("apiHost") +
          "/get-auth-code?nextUrl=" +
          url;
      }

      if (token && ssoToken) {
        const query = url.replace(/^[^?]+\?/, ""),
          params = new URLSearchParams(query);
        params.delete("token");
        params.delete("access_token");
        const newUrl: string = url.replace(/\?.*/, `?${params.toString()}`);
        this.router.navigateByUrl(newUrl);
      }

      return true;
    });
  }
}
