import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RubricCriterion } from "app/shared/assessment.type";
import { isEmpty } from "lodash";
import { ContentChange } from "ngx-quill";

@Component({
  selector: "app-rubric-comment-event",
  templateUrl: "./rubric-comment-event.component.html",
  styleUrls: [
    "./rubric-comment.component.css",
    "../../submission-comments/submission-comments.component.css",
    "../../submission-comment/submission-comment-base.component.css",
  ],
})
export class RubricCommentEventComponent {
  @Input() isReadOnly: boolean = true;
  @Input() criterion: RubricCriterion;
  @Input() comment: string | null;
  @Output() commentChange = new EventEmitter<ContentChange>();

  readonly isEmpty = isEmpty;

  constructor() {}

  onCommentChange(event: ContentChange) {
    event.source = this.criterion.id.toString();
    this.commentChange.emit(event);
  }
}
