import { Directive, Input, Injectable } from "@angular/core";
import { Params } from "@angular/router";

import { BehaviorSubject } from "rxjs";

@Injectable()
export class ActivatedRouteStub {
  private subject = new BehaviorSubject(this.testQueryParams);
  queryParams = this.subject.asObservable();

  private _queryParams = {};

  get testQueryParams() {
    return this._queryParams;
  }
  set testQueryParams(params: Params) {
    this._queryParams = params;
    this.subject.next(this._queryParams);
  }
}

@Directive({
  selector: "[routerLink]",
})
export class RouterLinkStubDirective {
  @Input() routerLink: any;
  @Input() queryParams: {};
}
