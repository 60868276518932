<div 
[class.textinfo-x-hidden]="
(isRecommended  && recommendation.leveled_badge != badge.id && !editing) || (!editing && isRecommended && recommendation.leveled_badge != badge.id )
"
[class.l-hidden]="
(isRecommended  && recommendation.leveled_badge != badge.id && !editing)|| (!editing && isRecommended && recommendation.leveled_badge != badge.id )
"
tabindex="-1" *ngFor="let badge of leveledBadges;"> 
        <div class="forminput-x-input">
            <div class="formradio formradio-bold">
                <input 
                class="visuallyhidden" 
                type="radio"  
                name="{{name_setup}}"
                [formControl]="leveledRecommendation"
                [value]="badge.id"
                [attr.disabled]="disabled ? true : null"
                id="{{name_setup}}-{{ badge.id }}"
                />
                <label for="{{name_setup}}-{{ badge.id }}">
                  
                   {{isDecision ? '': 'Recommend '}}Award, {{ badge.credential.name }}
                </label>
                <label class="helper">
                    This level represents 
                    <ng-container *ngIf="badge.proficiency_qualifier[0].toLowerCase() === 'a' || badge.proficiency_qualifier[0].toLowerCase() === 'e' || badge.proficiency_qualifier[0].toLowerCase() === 'i' || badge.proficiency_qualifier[0].toLowerCase() === 'o' || badge.proficiency_qualifier[0].toLowerCase() === 'u'">
                    an
                    </ng-container>
                    <ng-container *ngIf="badge.proficiency_qualifier[0].toLowerCase() !== 'a' && badge.proficiency_qualifier[0].toLowerCase() !== 'e' && badge.proficiency_qualifier[0].toLowerCase() !== 'i' && badge.proficiency_qualifier[0].toLowerCase() !== 'o' && badge.proficiency_qualifier[0].toLowerCase() !== 'u'">
                    a
                    </ng-container>
                     {{ badge.proficiency_qualifier }} proficiency in the criteria.
                    <ng-container *ngIf="badge.level_badge_type == 'point'">
                        Awards at this level typically have a score in the {{badge.low_score}}-{{badge.high_score}}% range.
                    </ng-container>
                </label>
            </div>
        </div>
</div>
<div
[class.textinfo-x-hidden]="
(isRecommended   && !editing && recommendation.leveled_badge != null) 
"
[class.l-hidden]="
(isRecommended   && !editing && recommendation.leveled_badge != null) 
"
tabindex="-1"
>
<div class="forminput-x-input">
  <div class="formradio formradio-bold">
    <input
      class="visuallyhidden"
      type="radio"
      [formControl]="leveledRecommendation"
      name="{{name_setup}}"
      id="{{name_setup}}-0"
     
      [attr.disabled]="disabled ? true : null"
      [value]="-1"
    />
    <label for="{{name_setup}}-0"
      >{{isDecision ? '': 'Recommend '}} Deny
      
   </label>
      <label class="helper">
        This application did not meet the criteria. 
        <ng-container *ngIf="pointed">
          Denied applications typically have a score below {{passing_score}}%.  
      </ng-container>
      </label>
  </div>
</div>
</div>
<div *ngIf="skippedBadges?.length > 0 && (editing || isDecision) "  class="attachment attachment-big textinfo wrap wrap-light2">
  <div class="textinfo-x-content">
    <p class="textinfo-mb">This application did not apply for every level. The following levels are not available to award:</p>
    <div tabindex="-1" *ngFor="let skipped of skippedBadges;">
      <p class="textinfo-small-bold" >               
        {{ skipped.credential.name }}
      </p>
      <p class="textinfo-small textinfo-mb">
        This level represents an {{ skipped.proficiency_qualifier }} proficiency in the criteria.
      </p>
  </div> 
</div>
</div>

