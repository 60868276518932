import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { User } from "./user.type";
import { WindowRefService } from "./core/window-ref.service";

@Injectable()
export class PardotService {
  constructor(private http: HttpClient, private window: WindowRefService) {}

  private getFormData(user: User) {
    return {
      user_id: user.id,
      submission_count: user.submission_count,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.educator.title,
      grade_level: user.educator.grade_level,
      content_area: user.educator.content_area,
      years_of_experience: user.educator.years_of_experience,
      degree_earned: user.educator.degree_earned,
      school_state: (user.educator.state && user.educator.state.name) || "",
      school_district:
        (user.educator.district && user.educator.district.name) || "",
      school_name: (user.educator.school && user.educator.school.name) || "",
      opt_out: user.receive_email_notifications ? 0 : 1,
    };
  }

  private postFormData(data): Promise<any> {
    const params = new URLSearchParams();
    Object.keys(data).forEach((key) => params.append(key, data[key]));
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const protocol = this.window.nativeWindow.location.protocol;
    return this.http
      .post(
        `${protocol}//go.pardot.com/l/414542/2018-05-09/45d2st`,
        params.toString(),
        {
          headers: headers,
          withCredentials: true,
        }
      )
      .toPromise()
      .catch((e: any) => {
        // NOTE : No CORS policy is configured on the Pardot side. This results
        // in an error, but we don't care.
        console.log(e);
      });
  }

  postPardot(user: User): Promise<any> {
    return this.postFormData(this.getFormData(user));
  }
}
