import { Injectable } from "@angular/core";
import {
  Comment,
  CommentAction,
  CommentInfo,
  CommentType,
} from "../comment.type";
import { UserService } from "../core/user.service";
import { User, UserRole } from "../user.type";
import { Submission, SubmissionStatus } from "../submission.type";

@Injectable()
export class SubmissionCommentsMixinService {
  constructor(private userService: UserService) {}

  initComment(info: CommentInfo): Comment {
    return <Comment>{
      id: null,
      comment_type: info.commentType,
      content_type: info.contentType,
      object_pk: info.pk,
      submission_id: info.submission?.id,
      user_id: this.userService.user.id,
      comment: "",
    };
  }

  // Returns actions scope for comments
  // For Issuer/Assessor comment type
  // 1. create: current assessor for not asessed submission
  // 2. update:
  // - current assessor only for own comments and not assessed submission
  // 3. delete:
  // - current assessor only for own comments and not assessed submission
  // - admin/issuer for all comments they have access to object at any point of time
  // For Submitter comment type:
  // 1. create/update/delete
  // - current assessor for not asessed submission
  // - admin/issuer without restrictions
  actionsScopeFor(
    comment: Comment,
    submission: Submission | null,
    commentType: CommentType
  ) {
    const user: User = this.userService.user;
    const isAssessed = [
      SubmissionStatus.ASSESSED,
      SubmissionStatus.AWARDED,
      SubmissionStatus.DENIED,
    ].includes(<SubmissionStatus | null>submission?.status);
    const isAssessor = (<User>submission?.assessor)?.id === user.id;
    const isOwn = comment.user_id === user.id;

    if (commentType === CommentType.SUBMITTER) {
      if (
        user.role === UserRole.SUBMITTER &&
        [SubmissionStatus.AWARDED, SubmissionStatus.DENIED].includes(
          <SubmissionStatus | null>submission?.status
        )
      )
        return [CommentAction.view];
      if (user.is_assessor)
        return (isAssessed || !isOwn) && !user.is_issuer
          ? [CommentAction.view]
          : [CommentAction.view, CommentAction.edit, CommentAction.add];
    }

    if ([CommentType.ISSUER, CommentType.ASSESSOR].includes(commentType)) {
      if (user.is_issuer && !isAssessor)
        return [CommentAction.view, CommentAction.remove];
      if (user.is_assessor)
        return isAssessed || !isOwn
          ? isAssessed
            ? [CommentAction.view]
            : [CommentAction.view, CommentAction.add]
          : [
              CommentAction.view,
              CommentAction.remove,
              CommentAction.edit,
              CommentAction.add,
            ];
    }
    return [];
  }
}
