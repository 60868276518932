import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { FaqsComponent } from "./faqs.component";
import { FaqsRoutingModule } from "./faqs-routing.module";

import { FaqsService } from "./faqs.service";

@NgModule({
  imports: [SharedModule, FaqsRoutingModule],
  declarations: [FaqsComponent],
  providers: [FaqsService],
})
export class FaqsModule {}
