import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormControl } from '@angular/forms';
import { SubmissionRecommendation } from 'app/shared/submission-recommendation.type';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-boolean-recommendation',
  templateUrl: './boolean-recommendation.component.html',
  styleUrls: ['./boolean-recommendation.component.css']
})
export class BooleanRecommendationComponent implements OnInit {
  @Input() isRecommended: boolean;
  @Input() recommendation: SubmissionRecommendation;
  @Input() canCreate: boolean;
  @Input() editing: boolean;
  @Input() form: UntypedFormControl;
  @Input() isDecision: boolean;
  @Input() canDecide: boolean;
  
  recommendationToAward: UntypedFormControl = new UntypedFormControl();
  disabled = false;
  name_setup = 'recommendationToAward'

  constructor(
    @Optional() public container: ControlContainer,
  ) { }

  updateDisabled(): void{
    if(this.editing){
      this.disabled = false;
    }else{
      this.disabled = (this.isRecommended && !this.editing) || (this.canDecide !== undefined && !this.canDecide) || !this.canCreate;
    
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
   
    this.updateDisabled();
    
  }

  ngOnInit(): void {
    this.name_setup = uuidv4()
    this.updateDisabled();
    if (!this.isRecommended && this.container && this.canCreate) {
      this.recommendationToAward.valueChanges.subscribe((value: boolean) => {
        this.container.control.get("to_award").setValue(value);
      });
    }
    if(this.form){
      this.recommendationToAward.valueChanges.subscribe((value: boolean) => {
        this.form.get("to_award").setValue(value);
      });
    }

  }

}
