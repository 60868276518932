import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";

import { ShareToken } from "../shared/share-token.type";

@Injectable()
export class ShareTokenCreateService {
  constructor(private apiGetService: ApiGetService) {}

  postShareToken(data: any = {}): Promise<ShareToken | void> {
    return this.apiGetService.post<ShareToken>("share-token/", data);
  }
}
