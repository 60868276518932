import { Component } from "@angular/core";
import { WindowRefService } from "app/shared/core/window-ref.service";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.css"],
})
export class HomepageComponent {
  constructor(private windowService: WindowRefService) {}

  openTabTo(href: string) {
    this.windowService.nativeWindow.open(href, "_blank");
    return false;
  }
}
