import { Component, Input, OnInit } from "@angular/core";
import { Comment, CommentAction, CommentInfo } from "../comment.type";
import { SubmissionCommentsMixinService } from "./submission-comments-mixin.service";

@Component({
  selector: "app-submission-comments",
  templateUrl: "./submission-comments.component.html",
  styleUrls: ["./submission-comments.component.css"],
})
export class SubmissionCommentsComponent implements OnInit {
  @Input() data: Comment[];
  @Input() info: CommentInfo;
  @Input() clsWrap: string = "";

  comments: Comment[] = [];
  newComment: Comment = <Comment>{};
  isAddAllowed: boolean = false;

  constructor(
    private submissionCommentsMixinService: SubmissionCommentsMixinService
  ) {}

  ngOnInit() {
    this.comments = this.data.filter(
      (c: Comment) =>
        c.comment_type === this.info.commentType &&
        c.content_type === this.info.contentType &&
        c.object_pk === this.info.pk
    );
    this.newComment = this.initComment();
    this.isAddAllowed = this.actionsScopeFor(this.newComment).includes(
      CommentAction.add
    );
  }

  initComment(): Comment {
    return this.submissionCommentsMixinService.initComment(this.info);
  }

  commentTrackBy(_idx: number, comment: { id: number }): number {
    return comment.id;
  }

  actionsScopeFor(comment: Comment | null) {
    return this.submissionCommentsMixinService.actionsScopeFor(
      comment,
      this.info.submission,
      this.info.commentType
    );
  }

  onSubmitComment(payload: { data: Comment }) {
    const idx = this.comments.findIndex((c) => c.id === payload.data.id);
    if (idx > -1) this.comments[idx] = payload.data;
    else this.comments.push(payload.data);
  }

  onRemoveComment(payload: { id: number }) {
    const idx = this.comments.findIndex((c) => c.id === payload.id);
    if (idx > -1) this.comments.splice(idx, 1);
  }
}
