import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";
import { Submission } from "../shared/submission.type";
import { AwardedCredential } from "../shared/awarded-credential.type";
import { ShareToken } from "../shared/share-token.type";

@Injectable()
export class AwardedCredentialDetailService {
  constructor(private apiGetService: ApiGetService) {}

  getAwardedCredential(id: string): Promise<AwardedCredential | void> {
    return this.apiGetService.get<AwardedCredential>(`awarded/${id}/`);
  }

  getShareToken(readable_id: string): Promise<ShareToken | void> {
    return this.apiGetService.get<ShareToken>(`share-token/${readable_id}/`);
  }

  getSubmission(readable_id: string): Promise<Submission | void> {
    return this.apiGetService
      .get<Submission>(`submission/${readable_id}/`)
      .catch(() => {});
  }
}
