import {
  throwError as observableThrowError,
  Observable,
  Observer,
  of,
} from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";

import { tap, map, switchMap } from "rxjs/operators";

import { ViewFile } from "./view-file";
import { FileMIMEType } from "./mime-types";

interface ResponseCache {
  [url: string]: ViewFile;
}

const ENCODE = [
  FileMIMEType.IMAGE_JPEG,
  FileMIMEType.IMAGE_PNG,
  FileMIMEType.VIDEO,
  FileMIMEType.PDF,
];

@Injectable()
export class ViewerService {
  private isValidUrl =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,5}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/i;

  private fileCache: Map<string, ViewFile> = new Map();

  constructor(private http: HttpClient) {}

  getFile(url: string): Observable<ViewFile> {
    if (!this.isValidUrl.test(url)) {
      return observableThrowError({ message: `Wrong url format for "${url}"` });
    }

    return this.fileCache.has(url)
      ? of(this.fileCache.get(url))
      : this.http.get(url, { observe: "response", responseType: "blob" }).pipe(
          switchMap((response) => responseToDigFile(response)),
          tap((file) => this.fileCache.set(url, file))
        );
  }
}

function responseToDigFile(
  httpResponse: HttpResponse<Object>
): Observable<ViewFile> {
  const type = httpResponse.headers.get("content-type");
  const url = httpResponse.url;

  return Observable.create((observer: Observer<any>) => {
    if (!ENCODE.includes(type as FileMIMEType)) {
      observer.next(null);
      observer.complete();
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(httpResponse.body as Blob);

    reader.onloadend = () => {
      observer.next(reader.result);
      observer.complete();
    };

    reader.onerror = () => observer.error("Failed to parse the file.");
  }).pipe(map((body) => ({ name: url, body, type, url })));
}
