<div
  class="l-dialog dialog"
  [ngClass]="{ 'dialog-is-open': dialogIsOpen }"
  aria-modal="true"
  role="dialog"
  ngxFocusTrap
  #ngxFocus="ngxFocusTrap"
  [initActivated]="false"
>
  <div class="document">
    <dig-viewer
      [isVisible]="dialogIsOpen"
      [selectedUrl]="currentUrl$ | async"
      (closeDialog)="closeDialog()"
    ></dig-viewer>

    <div
      class="document-x-button documentbutton-x-left"
      *ngIf="urls.length > 1"
      (click)="slidePrev()"
      [attr.disabled]="selectedUrlIndex === 0 ? true : null"
    >
      <button type="button" class="icon icon-back icon-centered">
        <span class="visuallyhidden">Button Prev</span>
      </button>
    </div>
    <div
      class="document-x-button documentbutton-x-right"
      *ngIf="urls.length > 1"
      (click)="slideNext()"
      [attr.disabled]="selectedUrlIndex === urls.length - 1 ? true : null"
    >
      <button type="button" class="icon icon-next icon-centered">
        <span class="visuallyhidden">Button Next</span>
      </button>
    </div>
  </div>
</div>
