import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { RubricCriterion } from "app/shared/assessment.type";
import { isEmpty } from "lodash";

@Component({
  selector: "app-rubric-comment",
  templateUrl: "./rubric-comment.component.html",
  styleUrls: [
    "./rubric-comment.component.css",
    "../../submission-comments/submission-comments.component.css",
    "../../submission-comment/submission-comment-base.component.css",
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RubricCommentComponent {
  @Input() isReadOnly: boolean = true;
  @Input() criterion: RubricCriterion;

  readonly isEmpty = isEmpty;

  constructor(private parentForm: FormGroupDirective) {}

  get comment(): string | null {
    return this.parentForm.value.comments[this.criterion.id];
  }
}
