import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  HostListener,
} from "@angular/core";

import { Subject } from "rxjs";
import { ModalService } from "../../../core/modal.service";

@Component({
  selector: "dig-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigViewerCarouselComponent {
  private _urls: string[];
  private _selectedUrlIndex = 0;
  dialogIsOpen = false;
  @ViewChild("ngxFocus", { static: true }) focusTrap;

  @Input()
  set urls(urls: string[]) {
    this._urls = urls;
    // reset the carousel
    this.currentUrl$.next(urls[0]);
  }
  get urls() {
    return this._urls;
  }

  @Input()
  set selectedUrlIndex(index: number) {
    this._selectedUrlIndex = index;
    const currentUrl = this.urls[this._selectedUrlIndex];
    this.currentUrl$.next(currentUrl);
  }
  get selectedUrlIndex() {
    return this._selectedUrlIndex;
  }

  // Back button pressed.
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.closeDialog();
  }

  currentUrl$: Subject<string> = new Subject();

  constructor(private modalService: ModalService) {}

  openPanelWithBackdrop() {
    this.dialogIsOpen = true;
    this.modalService.anyModalIsOpen.next(true);
    this.focusTrap.activateFocusTrap();
    const currentUrl = this.urls[this._selectedUrlIndex];
    this.currentUrl$.next(currentUrl);
  }

  closeDialog() {
    this.dialogIsOpen = false;
    this.modalService.anyModalIsOpen.next(false);
    this.focusTrap.deactivateFocusTrap();
  }

  slideNext() {
    if (this.selectedUrlIndex < this.urls.length - 1) {
      this.selectedUrlIndex += 1;
    }
  }

  slidePrev() {
    if (this.selectedUrlIndex > 0) {
      this.selectedUrlIndex -= 1;
    }
  }
}
