import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { SubmissionCommentComponent } from "../submission-comment/submission-comment.component";
import { SubmissionCommentMixinService } from "../submission-comment/submission-comment-mixin.service";
import { SubmissionCommentNewComponent } from "../submission-comment/submission-comment-new.component";
import { SubmissionCommentsComponent } from "./submission-comments.component";
import { SubmissionCommentsMixinService } from "./submission-comments-mixin.service";
import { QuillModule } from "ngx-quill";
import { SafeHtmlPipe } from "../safe-html.pipe";

@NgModule({
  imports: [CommonModule, FormsModule, QuillModule],
  declarations: [
    SafeHtmlPipe,
    SubmissionCommentsComponent,
    SubmissionCommentNewComponent,
    SubmissionCommentComponent,
  ],
  exports: [SubmissionCommentsComponent, SafeHtmlPipe],
  providers: [SubmissionCommentsMixinService, SubmissionCommentMixinService],
})
export class SubmissionCommentsModule {}
