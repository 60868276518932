<td (click)="viewFile(file)">{{ file.name | fileName | truncate: 40 }}</td>

<ng-container [ngSwitch]="file.state">
  <td colspan="2" *ngSwitchCase="STATE.PROGRESS">
    <div class="progress">
      <span [ngStyle]="{ width: file.progress + '%' }"></span>
    </div>
  </td>

  <ng-container *ngSwitchCase="STATE.EDIT">
    <!-- <span (click)="viewFile(file)">View</span> -->
    <td class="textsmall">
      {{ file.size | fileSize }}
    </td>
    <td>
      <button
        (click)="removeFile(file)"
        class="button button-text"
        type="button"
      >
        <span class="button-x-text">Remove</span>
      </button>
    </td>
  </ng-container>

  <ng-container *ngSwitchCase="STATE.FINISHED">
    <td colspan="2" class="textsmall">
      {{ file.size | fileSize }}
    </td>
  </ng-container>

  <ng-container *ngSwitchCase="STATE.FAILED">
    <td colspan="2" class="textsmall forminput-x-errortext">
      {{ file.errorText }}
    </td>
  </ng-container>

  <ng-container *ngSwitchDefault></ng-container>
</ng-container>
