import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";

import { ProgressPageRoutingModule } from "./progress-page-routing.module";
import { ProgressPageComponent } from "./progress-page.component";
import { ProgressPageService } from "./progress-page.service";
import { AwardedSubmissionListComponent } from "./awarded-submission-list/awarded-submission-list.component";
import { DraftListModule } from "./draft-list/draft-list.module";

@NgModule({
  imports: [SharedModule, ProgressPageRoutingModule, DraftListModule],
  providers: [ProgressPageService],
  declarations: [ProgressPageComponent, AwardedSubmissionListComponent],
})
export class ProgressPageModule {}
