import { Injectable } from "@angular/core";
import { ApiGetService } from "../core/apiget.service";
import { Comment } from "../comment.type";
import { UserService } from "../core/user.service";
import { User } from "../user.type";

@Injectable()
export class SubmissionCommentMixinService {
  constructor(
    private apiGetService: ApiGetService,
    private userService: UserService
  ) {}

  userData() {
    const user: User = this.userService.user;
    return {
      user_id: user?.id,
      name: user?.full_name,
      email: user?.email,
    };
  }

  addComment(data: Comment): Promise<Comment> {
    return this.apiGetService.post<Comment>("comments/", {
      ...data,
      submit_date: new Date().toISOString(),
      ...this.userData(),
    });
  }

  updateComment(id: number, comment: string): Promise<Comment> {
    return this.apiGetService.patch<Comment>(`comments/${id}/`, { comment });
  }

  removeComment(id: number): Promise<any> {
    return this.apiGetService.delete<Comment>(`comments/${id}/`);
  }
}
