import { AbstractControl, Validators } from "@angular/forms";

/**
 * makes the field required if the predicate function returns true
 */
export function requiredIfValidator(predicate: Function) {
  return (formControl: AbstractControl<any, any>) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}
