import { Component, OnInit } from '@angular/core';

import { SitetypeService } from "app/shared/core/sitetype.service";

@Component({
  selector: 'app-explore-more',
  templateUrl: './explore-more.component.html',
  styleUrls: ['./explore-more.component.css']
})
export class ExploreMoreComponent implements OnInit {
  credentialName:String;

  constructor(
    private siteType: SitetypeService,
  ) {
    // constructor logic
   }

  ngOnInit(): void {
    this.siteType.siteType$.subscribe((siteType: string) => {
      this.credentialName = this.siteType.getCredentialsCertification();
    });
  }

}
