import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "./shared/core/user.service";

@Injectable()
export class TryLoginGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.tryLogin(next, state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.tryLogin(next, state.url);
  }

  tryLogin(route, url): Promise<boolean> {
    const token = route.queryParamMap.get("token"),
      ssoToken = route.queryParamMap.get("access_token");

    return this.userService.login(token, ssoToken).then((success: boolean) => {
      if (token && ssoToken) {
        const noParamUrl: string = url.replace(/\?.*/, ""); // Path without query params
        this.router.navigateByUrl(noParamUrl);
      }

      if (success && url === "/") {
        this.router.navigateByUrl("/explore");
      }

      return true;
    });
  }
}
