import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Credential } from "../credential.type";
import { Observable } from "rxjs";
import { ApiGetService } from "../core/apiget.service";

@Injectable()
export class CredentialResolveGuard implements Resolve<Credential> {
  constructor(private apiGetService: ApiGetService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<Credential> | Promise<Credential> | Credential {
    const slug = route.params.slug;
    return this.apiGetService
      .get<Credential>(`credential/${slug}/`)
      .catch((reason: any) => {
        console.log(reason);
        return null;
      });
  }
}
