import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";

import { DecisionCreateRoutingModule } from "./decision-create-routing.module";
import { DecisionCreateComponent } from "./decision-create.component";
import { DecisionCreateService } from "./decision-create.service";

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, DecisionCreateRoutingModule],
  providers: [DecisionCreateService],
  declarations: [DecisionCreateComponent],
})
export class DecisionCreateModule {}
