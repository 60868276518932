export const UP_ARROW = 38;
export const DOWN_ARROW = 40;
export const RIGHT_ARROW = 39;
export const LEFT_ARROW = 37;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const HOME = 36;
export const END = 35;
export const ENTER = 13;
export const SPACE = 32;
export const TAB = 9;
export const ESCAPE = 27;
export const BACKSPACE = 8;
export const DELETE = 46;
export const A = 65;
export const Z = 90;
export const ZERO = 48;
export const NINE = 91;
export const COMMA = 188;
