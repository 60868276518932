import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SubmissionLinksComponent } from "./submission-links/submission-links.component";
import { LinksInputComponent } from "./links-input/links-input.component";
import { LinksListComponent } from "./links-list/links-list.component";

@NgModule({
  imports: [CommonModule],
  declarations: [
    SubmissionLinksComponent,
    LinksInputComponent,
    LinksListComponent,
  ],
  exports: [SubmissionLinksComponent, LinksInputComponent, LinksListComponent],
})
export class SubmissionLinksModule {}
