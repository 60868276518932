<ng-container *ngTemplateOutlet="alt ? alternate : primary"></ng-container>

<ng-template #primary>
  <nav class="pagination">
    <h3 class="visuallyhidden">Pagination</h3>

    <div class="pagination-x-pages">
      <a
        href="#"
        class="page"
        *ngIf="currentPage !== 1; else noPrevPage"
        (click)="selectPage(currentPage - 1)"
        >Prev</a
      >
      <ng-template #noPrevPage
        ><a class="page page-is-disabled">Prev</a></ng-template
      >

      <ng-container *ngFor="let item of pageLinks">
        <ng-container
          *ngTemplateOutlet="getTemplate(item); context: { $implicit: item }"
        ></ng-container>
      </ng-container>

      <a
        href="#"
        class="page"
        *ngIf="currentPage < totalPages; else noNextPage"
        (click)="selectPage(currentPage + 1)"
        >Next</a
      >
      <ng-template #noNextPage
        ><a class="page page-is-disabled">Next</a></ng-template
      >
    </div>
  </nav>
</ng-template>

<ng-template #alternate>
  <nav class="l-paginationalt1">
    <h3 class="visuallyhidden">Pagination</h3>

    <button
      *ngIf="currentPage !== 1; else noPrevPageAlt"
      href
      (click)="selectPage(currentPage - 1)"
      type="button"
      class="button"
    >
      Prev
    </button>
    <ng-template #noPrevPageAlt
      ><button type="button" class="button" disabled>
        <span class="button-x-text">Prev</span>
      </button></ng-template
    >

    <div class="l-paginationalt1-x-pages">
      <ng-container *ngFor="let item of pageLinks">
        <ng-container
          *ngTemplateOutlet="getTemplate(item); context: { $implicit: item }"
        ></ng-container>
      </ng-container>
    </div>

    <button
      type="button"
      class="button"
      *ngIf="currentPage < totalPages; else noNextPageAlt"
      href
      (click)="selectPage(currentPage + 1)"
    >
      Next
    </button>
    <ng-template #noNextPageAlt
      ><button type="button" class="button" disabled>
        <span class="button-x-text">Next</span>
      </button></ng-template
    >
  </nav>
</ng-template>

<ng-template #pageLink let-item>
  <app-page-number
    [alt]="alt"
    [pageNumber]="item.page"
    [currentPage]="currentPage"
    (click)="selectPage(item.page)"
  ></app-page-number>
</ng-template>

<ng-template #placeholder>
  <span>...</span>
</ng-template>
