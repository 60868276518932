import { Pipe, PipeTransform } from "@angular/core";

import * as MarkdownIt from "markdown-it";

@Pipe({
  name: "MarkdownIt",
})
export class NgxMarkdownItPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const md = MarkdownIt();
    return md.render(value);
  }
}
