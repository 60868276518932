import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { ToastrService } from "ngx-toastr";

import { ShareTokenCreateService } from "./share-token-create.service";

import { ShareToken } from "../shared/share-token.type";
import { Confirmation } from "../shared/messages.constants";
import { ModalService } from "../shared/core/modal.service";

@Component({
  selector: "app-share-token-create",
  templateUrl: "./share-token-create.component.html",
  styleUrls: ["./share-token-create.component.css"],
  styles: [
    `
      .dialog {
        z-index: 10;
      }
    `,
  ],
})
export class ShareTokenCreateComponent implements OnInit, OnDestroy {
  awardedCredentialId: string;
  @ViewChild("ngxFocus", { static: true }) focusTrap;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: ToastrService,
    private tokenCreateService: ShareTokenCreateService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.awardedCredentialId = params.get("id");
    });
    this.modalService.anyModalIsOpen.next(true);
  }

  ngOnDestroy() {
    this.focusTrap.deactivateFocusTrap();
  }

  closePopup() {
    this.focusTrap.deactivateFocusTrap();
    this.modalService.anyModalIsOpen.next(false);
    this.router.navigate([{ outlets: { popup: null } }]);
  }

  createShareToken(form) {
    const data = {
      recipient_email: form.elements.recipient_email.value,
      includes_evidence: form.elements.with_evidence.checked,
      includes_feedback: form.elements.with_feedback.checked,
      awarded_credential: this.awardedCredentialId,
    };

    this.tokenCreateService
      .postShareToken(data)
      .then((shareToken: ShareToken) => {
        this.closePopup();
        this.messageService.success(Confirmation.CREDENTIAL("shared"));
      });
    return false;
  }
}
