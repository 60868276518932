import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationExtras,
  ParamMap,
} from "@angular/router";

import { Submission } from "../../shared/submission.type";

import { SubmissionDetailMixinService } from "../../shared/submission-detail-mixin/submission-detail-mixin.service";
import { Empty } from "../../shared/messages.constants";

import { Subscription } from "rxjs";

@Component({
  selector: "app-awarded-submission-list",
  templateUrl: "./awarded-submission-list.component.html",
  styleUrls: ["./awarded-submission-list.component.css"],
})
export class AwardedSubmissionListComponent implements OnInit, OnDestroy {
  awardedSubmissions: Submission[];
  emptyHeading = Empty.AWARD;
  emptyDescription = Empty.AWARD_DESC;
  routeSub: Subscription;
  totalPages = 0;
  currentPage = 1;
  paginationDefault = { page: 1, page_size: 40 };
  sortsDefault = { ordering: "-status_date" };
  filters: any = Object.assign({}, this.paginationDefault, this.sortsDefault);

  constructor(
    private submissionMixinService: SubmissionDetailMixinService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  updateFilters(queryParams: ParamMap) {
    this.filters = Object.assign({}, this.paginationDefault, this.sortsDefault);
    queryParams.keys.forEach((key: string) => {
      this.filters[key] = queryParams.get(key);
    });

    this.currentPage = parseInt(queryParams.get("page") || "1", 10);
  }

  updateUrl(
    key: string,
    value: string | number,
    moreKeys: { [key: string]: string | number } = {}
  ) {
    const thisUrl = `/dashboard/${this.route.snapshot.url[0].path}`,
      navigationExtras: NavigationExtras = {
        queryParams: Object.assign(
          {},
          this.filters,
          { [key]: value },
          moreKeys
        ),
        queryParamsHandling: "merge",
      };

    this.router.navigate([thisUrl], navigationExtras);
    return false;
  }

  getSubmissions() {
    this.submissionMixinService
      .fetchSubmissions(["Awarded"], this.filters)
      .then((response) => {
        if (response) {
          this.awardedSubmissions = response.results;
          this.totalPages = response.page_count;
        }
      });
  }

  ngOnInit() {
    this.routeSub = this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.updateFilters(paramMap);
      this.getSubmissions();
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
