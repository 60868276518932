import { Component, Host, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ɵɵsetComponentScope } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CredentialDetailService } from 'app/credential-detail/credential-detail.service';
import { AssessmentExtension, Rubric } from 'app/shared/assessment.type';
import { Credential } from "app/shared/credential.type";
import { SubmissionRecommendationRubric } from 'app/shared/submission-recommendation-rubric.type';

@Component({
  selector: 'app-automated-score-calculator',
  templateUrl: './automated-score-calculator.component.html',
  styleUrls: ['./automated-score-calculator.component.css']
})
export class AutomatedScoreCalculatorComponent implements OnInit,  OnChanges  {
  @Input() leveled: boolean;
  @Input() credential: Credential;
  @Input() assessmentExtension: AssessmentExtension;
  @Input() parentForm: UntypedFormGroup;
  @Input() rubricRecommendations: SubmissionRecommendationRubric[];
  slug: string = '';
  error: boolean = false;
  maxScore: number = 0;
  score: number = 0;
  percentage: number = 0;
  leveledBadge: boolean = false;
  criteriaScores = {};
  constructor(
    private credentialDetailService: CredentialDetailService,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.maxScore = 0;
    this.assessmentExtension?.assessments?.forEach((assessment) => {
      if(assessment.rubrics){
        assessment.rubrics.forEach((rubric) => {
          this.calculateRubricScore(rubric);
        });
      }
      assessment.sections.forEach((section) => {
        if (section.rubric) {
          this.calculateRubricScore(section.rubric)
        }
        section?.questions?.forEach((question) => {
          if (question.rubric) {
            this.calculateRubricScore(question.rubric)
          }
        });
      });
      if(this.rubricRecommendations){
        this.score = 0;
        this.error = false;
        this.rubricRecommendations.forEach((recommendation) => {
          recommendation.criterion_levels.forEach((level) => {
            let score = this.criteriaScores[`criterion_${level}`];
            if (score !== undefined) {
              if(Number.isNaN(score)){
                this.error = true;
              }
              this.score += score;
            }
          });
          var percentage = Math.round((this.score / this.maxScore) * 100);
          if(Number.isNaN(percentage)){
            this.error = true;
          } else {
            this.percentage = percentage;
          }    
        });
      }
    });
  }


  ngOnInit(): void {
    this.slug = this.credential?.slug;
    if (this.leveled) {
      this.credentialDetailService.getCredentialLeveled(this.slug).then((leveledBadges) => {
        leveledBadges[0].level_badge_type == 'point' ? this.leveledBadge = true : this.leveledBadge = false;
      });
    }

  
    this.parentForm.valueChanges.subscribe((value) => {
      this.score = 0;
      value.rubric_recommendations.forEach((recommendation) => {
        for (const [key, value] of Object.entries(recommendation.criterion_levels)) {
          let score = this.criteriaScores[`criterion_${value}`];
          if (score === undefined) {
            continue;
          }
          if(Number.isNaN(score)){
            this.error = true;
          }
          this.score += score;
        }
      });
      var percentage = Math.round((this.score / this.maxScore) * 100);
      if(Number.isNaN(percentage)){
        this.error = true;
      } else {
        this.percentage = percentage;
      }
   
    });
  }

  calculateRubricScore(rubric:Rubric ) {
    rubric.criterions.forEach((criterion) => {
      let weight = parseInt(criterion.weight);
      let score = 0;
      criterion.criterion_levels.forEach((level) => {
        let currentScore = parseInt(level.score)
        this.criteriaScores[`criterion_${level.id}`] = currentScore * weight;
        if (currentScore > score) {
          score = currentScore;
        }
      });
      this.maxScore += weight * score;
    });
  }
}
