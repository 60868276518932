<div class="l-detail">
  <section class="l-detail-x-content">
    <div class="l-detail-x-subsection wrap textblock">
      <h1>Frequently Asked Questions</h1>
    </div>
    <div
      *ngFor="let category of categories; let i = index"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2 [id]="slugify(category)">
        <span>{{ category.title }}</span>
      </h2>
      <ng-container *ngFor="let entry of category.entries">
        <h3>{{ entry.question }}</h3>
        <div class="textblock" [innerHTML]="entry.answer | MarkdownIt"></div>
      </ng-container>
    </div>
  </section>
  <div class="l-detail-x-stickycontainer">
    <nav
      class="l-detail-x-nav"
      role="navigation"
      aria-labelledby="sidebarnavigation"
    >
      <h2 class="visuallyhidden" id="sidebarnavigation"></h2>
      <div class="l-detail-x-sidebaritems">
        <a
          class="sidebarnavitem"
          *ngFor="let category of categories; let i = index"
          [routerLink]=""
          [fragment]="slugify(category)"
          (click)="navigateTo(getAnchor(category))"
        >
          {{ category.title }}
        </a>
      </div>
    </nav>
  </div>
</div>
