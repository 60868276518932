import { Component, Input, Optional, OnInit } from "@angular/core";
import { UntypedFormControl, ControlContainer } from "@angular/forms";

import { SubmissionDecision } from "../../submission-decision.type";
import { Submission } from "app/shared/submission.type";
import { tr } from "date-fns/locale";

@Component({
  selector: "app-subsection-decision-form",
  templateUrl: "./subsection-decision-form.component.html",
  styleUrls: ["./subsection-decision-form.component.css"],
})
export class SubsectionDecisionFormComponent implements OnInit {
  @Input() decision: SubmissionDecision;
  @Input() canCreate = false;
  @Input() canDecide: boolean;
  @Input() submission: Submission;
  @Input() credential: Credential | Number;
  decisionToAward: UntypedFormControl = new UntypedFormControl();
  skippedSections = [];
  leveled = false;
  constructor(@Optional() public container: ControlContainer) {}

  ngOnInit() {
    if (!this.decision && this.container && this.canCreate) {
      this.decisionToAward.valueChanges.subscribe((value: boolean) => {
        this.container.control.get("to_award").setValue(value);
      });
    }
    if(this.submission !== undefined){
      if(typeof this.submission?.credential !== 'number'){
        this.leveled = this.submission.credential?.apply_only;
      }
    }
    if (this.decision) {
      this.decisionToAward.setValue(this.decision.to_award);
    }
    if(this.submission.sections) {
      for (let section of this.submission.sections) {
        if (section.skipped) {
          this.skippedSections.push(section.section);
        }
      }
    }
  }
}
