<label for="links" class="forminput-x-label"
  >Links <span *ngIf="helpText">({{ helpText }})</span></label
>
<app-links-input (url)="addLink($event)" *ngIf="!readonly" [disabled]="disabled"></app-links-input>
<app-links-list
  [urls]="urls"
  [readonly]="readonly"
  (removeUrl)="removeLink($event)"
  *ngIf="urls.length > 0"
></app-links-list>
