import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";

import { Credential } from "../shared/credential.type";
import { AssessmentExtension, LevelBadgeType } from "../shared/assessment.type";
import { HistoryData } from "../shared/history.type";

@Injectable()
export class CredentialDetailService {
  constructor(private apiGetService: ApiGetService) {}

  getCredential(slug: string): Promise<Credential | void> {
    return this.apiGetService.get<Credential>(`credential/${slug}/`);
  }

  getCredentialLeveled(slug: string): Promise<LevelBadgeType[] | void> {
    return this.apiGetService.get<LevelBadgeType[]>(`credential/${slug}/leveled`);
  }

  getAssessment(id: number): Promise<AssessmentExtension | void> {
    return this.apiGetService.get<AssessmentExtension>(
      `credential/extension/assessment/${id}/`
    );
  }

  getCredentialHistory(credentialId: number): Promise<HistoryData[]> {
    return this.apiGetService.get<HistoryData[]>(
      `history/?related_object_type__model=credential&related_object_pk=${credentialId}`
    );
  }

  logApplyClick(credentialId: number): any {
    return this.apiGetService.post<any>("apply-click/", {
      credential: credentialId,
    });
  }
}
