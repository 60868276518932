import { Component, HostBinding } from "@angular/core";
import { LoadingOverlayService } from "./loading-overlay.service";

@Component({
  selector: "app-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrls: ["./loading-overlay.component.css"],
  styles: [
    `
      :host {
        background-color: rgba(255, 255, 255, 0.75);
      }
    `,
  ],
})
export class LoadingOverlayComponent {
  @HostBinding("attr.class")
  get className() {
    return `l-dialog dialog loading ${this.isOpen ? "dialog-is-open" : ""}`;
  }

  get isOpen() {
    return this.loaderService.isLoading;
  }

  constructor(private loaderService: LoadingOverlayService) {}
}
