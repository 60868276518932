import { NgModule } from "@angular/core";
import { LoadingOverlayService } from "./loading-overlay.service";
import { LoadingOverlayComponent } from "./loading-overlay.component";
import { LoadingIndicatorDemoComponent } from "./loading-indicator-demo/loading-indicator-demo.component";

@NgModule({
  imports: [],
  providers: [LoadingOverlayService],
  declarations: [LoadingOverlayComponent, LoadingIndicatorDemoComponent],
  exports: [LoadingOverlayComponent],
})
export class LoadingOverlayModule {}
