import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginationComponent } from "./pagination/pagination.component";
import { PageNumberComponent } from "./page-number/page-number.component";

@NgModule({
  imports: [CommonModule],
  declarations: [PaginationComponent, PageNumberComponent],
  exports: [PaginationComponent],
})
export class PaginationModule {}
