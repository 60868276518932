/* eslint-disable */
export const categories = [
  {
    title: "General FAQs",
    entries: [
      {
        question: "What are micro-credentials?",
        answer:
          "Micro-credentials are a form of competency-based recognition that validate an educator’s professional learning. An educator selects a micro-credential that captures a research-backed, discrete skill and submits evidence to demonstrate their competence in that skill. The educator receives a micro-credential, in the form of a digital badge, if the issuing organization determines that the evidence meets the requirements of the scoring guide and rubric.",
      },
      {
        question: "Who issues micro-credentials?",
        answer:
          "Micro-credentials are issued by organizations with content expertise in particular educator practices or competency areas, including non-profit and for-profit professional development organizations, ed tech companies, institutions of higher education, and others.",
      },
      {
        question: "How long does it take to earn a micro-credential?",
        answer:
          "The amount of time it takes to earn a micro-credential depends on the particular micro-credential and an educator’s previous experience with the skill addressed in the micro-credential.",
      },
      {
        question:
          "Do I need to attend a training, course, or other learning experience to earn a micro-credential?",
        answer:
          "No. Micro-credentials do not require educators to participate in specific learning experiences or trainings to earn them. Additionally, there is no limit to the amount of time an educator can take to prepare their micro-credential submission.",
      },
      {
        question: "How much does it cost to earn a micro-credential?",
        answer:
          "There is no cost associated with creating a micro-credential platform account or earning a micro-credential. However, the issuing organization may choose to charge a reasonable assessment fee to cover the cost of assessing a micro-credential submission.",
      },
      {
        question:
          "What can I do with my micro-credentials once I’ve earned them?",
        answer:
          "Once earned, micro-credentials can be shared through social networks, embedded in websites or learning management systems (LMS), emailed to colleagues, and downloaded as digital badges. Micro-credentials comply with the [open badges specification](http://www.openbadgespec.org), which means they are unique to each earner and contain metadata recognized and supported by dozens of platforms across the web.",
      },
      {
        question:
          "Can I earn micro-credentials collaboratively with other educators?",
        answer:
          "Yes. All micro-credential submissions should be unique to each educator. As a result, it’s imperative that the evidence you submit reflects the work you’ve done. Additionally, please indicate that you worked with other team members in your submission.",
      },
      {
        question:
          "Can I re-apply for a micro-credential if I did not earn it the first time?",
        answer:
          "Yes. Please revise your submission based on the feedback from the assessor and re-submit all associated evidence for the micro-credential it through the platform.",
      },
      {
        question:
          "Where can I go for help if I have questions about the micro-credential content or rubric?",
        answer:
          "Please submit a question through the [help desk](/contact) and Digital Promise will direct your question to the appropriate individual.",
      },
      {
        question:
          "Are micro-credentials only for professionals in the education sector?",
        answer:
          "Micro-credentials are intended to recognize competencies that are most relevant in the education sector, including early education, K-12 education, and higher education. However, many of the skills and competencies represented by the micro-credentials are applicable across many sectors.",
      },
      {
        question: "Do all micro-credentials require in-class instruction?",
        answer:
          "No, there are many micro-credentials that do not require in-class instruction. Please carefully review our catalogue to identify micro-credentials that are relevant to non-classroom educators such as librarians, administrators, and coaches.",
      },
      {
        question: "How often are micro-credentials added to the platform?",
        answer:
          "Micro-credentials are continuously added to our platform. We update our catalogue as our work with industry-leading issuing organizations grows.",
      },
    ],
  },
  {
    title: "Assessor Support",
    entries: [
      {
        question:
          "How much time do I have to assess a micro-credential submission?",
        answer:
          "Every micro-credential submission must be assessed and awarded or denied within 14 days of the date of submission.",
      },
      {
        question:
          "Where can I go for help if I have questions about the micro-credential content or rubric?",
        answer:
          "Any questions involving content should be directed to the issuer of your organization’s micro-credentials.",
      },
      {
        question:
          "What should I do if I have questions about anonymity or a conflict of interest (bias) regarding the submission I’ve been assigned to assess?",
        answer:
          "Any questions involving anonymity, possible conflicts of interest, or biases should be directed to the issuer of your organization’s micro-credentials.",
      },
      {
        question: "How should I handle resubmissions?",
        answer:
          "All resubmissions should contain all of the necessary evidence, not just those pieces that have been modified and/or added. If an educator does not provide all pieces of the submission, please leave feedback in the assessment form identifying the missing evidence in the educator's submission and deny the micro-credential.",
      },
      {
        question:
          "What should I do if I think an educator has plagiarized their submission?",
        answer:
          "If you believe a submission has been plagiarized or if you receive more than one submission with similar or identical evidence, please tell the issuer of your organization’s micro-credentials immediately.",
      },
    ],
  },
  {
    title: "Issuer Support",
    entries: [
      {
        question: "How can I become an issuer of micro-credentials?",
        answer:
          "If you would like to develop micro-credentials, please visit [digitalpromise.org/microcredentials](https://digitalpromise.org/microcredentials) to learn more about our ecosystem and the role issuers play in it. Contact [microcredentials@digitalpromise.org](mailto:microcredentials@digitalpromise.org) to learn more about how to get started.",
      },
      {
        question:
          "How can I contact Digital Promise about an issue my organization is having?",
        answer:
          "Please reach out to the Digital Promise staff member you worked with during the micro-credential development process if you are having challenges related to your micro-credentials.",
      },
      {
        question:
          "How much time do I have to review a micro-credential submission?",
        answer:
          "Every micro-credential submission must be assessed and awarded or denied within 14 days of the submission date.",
      },
    ],
  },
  {
    title: "Technical Support",
    entries: [
      {
        question: "What should I do if I’m having trouble logging in?",
        answer:
          "If you are having difficulties logging in, please select “forgot my password” on the login screen and follow the prompts.",
      },
      {
        question: "What browser should I use for the best platform experience?",
        answer:
          "Please use [Google Chrome](https://stripe.com/help/security) or the most up-to-date version of any other standard browser.",
      },
      {
        question:
          "Can I access the micro-credential platform on my smartphone?",
        answer:
          "Yes. Some areas of the platform have been optimized for mobile. For the best overall experience, please log in using a desktop or laptop computer.",
      },
      {
        question: "What should I do if I encounter a broken link?",
        answer:
          "If you encounter a broken link while you are browsing or applying for a micro-credential, please submit the relevant information via the [help desk](/contact).  If you are an assessor, please reach out to your issuing organization, and if you are an issuing organization, please reach out to the Digital Promise team.",
      },
      {
        question:
          "What should I do if I encounter any type of technical difficulty?",
        answer:
          "If you encounter technical challenges, please submit the relevant information via the [help desk](/contact).",
      },
    ],
  },
  {
    title: "Payment",
    entries: [
      {
        question:
          "What payment types does the Digital Promise Micro-credential Platform accept?",
        answer:
          "Assessment fees can be paid for using any Visa, MasterCard, American Express, JCB, Discover, and Diners Club card type. The platform also accepts payment in the form of coupon codes, which may have been provided to you by an issuing organization or your school/district/state.",
      },
      {
        question:
          "What service does the Digital Promise Micro-credential Platform use to process financial transactions?",
        answer:
          "Digital Promise uses Stripe to process and facilitate transactions. You can visit their website here: https://stripe.com\n\nStripe is a third-party payment solution, that makes it possible for the Digital Promise Platform to accept credit card and debit card payment. It's secure and quick - you can process payments almost immediately.",
      },
      {
        question:
          "Why do I have a charge on my card statement from Stripe, yet I am not a Stripe user?",
        answer:
          "Digital Promise uses Stripe to process transactions for micro-credential assessment fees. You do not have to be a Stripe user to take advantage of this service.",
      },
      {
        question:
          "Where can I go to look up a charge on my credit card associated with the payment of a micro-credential assessment fee?",
        answer:
          "To look up a charge associated with the Micro-credential Platform, please visit this link and input the same card number used to pay for the assessment fee: https://support.stripe.com/charge-lookup",
      },
      {
        question:
          "Can I request a refund if I am unsuccessful in my attempt to earn a micro-credential?",
        answer:
          "Refunds are not issued for denied micro-credential submissions. However, if you would like to pursue a refund for a different reason please reach out via the [help desk](/contact).",
      },
      {
        question: "Is Stripe secure?",
        answer:
          "Yes. Stripe follows the most strict requirements for security in the industry. [Learn more about Stripe’s security](https://stripe.com/help/security).",
      },
      {
        question:
          "If I am having trouble using Stripe to process a payment, where can I go for support?",
        answer:
          "If you are having issues with our payments system, please reach via the [help desk](/contact).",
      },
    ],
  },
];
