<div class="toast l-toast toast-confirm l-toast-is-open">
  <div class="message" [ngClass]="toastClasses">
    <h3 class="visuallyhidden" id="toast-message-progress">Form Message</h3>
    <p>{{ message }}</p>
  </div>
  <button
    *ngIf="options.closeButton"
    aria-labelledby=""
    type="button"
    (click)="remove()"
  >
    Dismiss
  </button>
</div>
