import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";

import { categories as faqsData } from "./faqs-data";

@Injectable()
export class FaqsService {
  constructor(private apiGetService: ApiGetService) {}

  getData(): Promise<any> {
    // return this.apiGetService.get<any>('faq-data-route');
    return Promise.resolve(faqsData);
  }
}
