<div class="wrap l-overflowauto">
  <form
    action=""
    class="form l-form l-containerhorizontal"
    role="form"
    aria-labelledby=""
    [formGroup]="userForm"
    (ngSubmit)="submit()"
  >
    <div class="form-x-heading snippet snippet-large">
      <h3 class="snippet-x-heading" id="form-heading">Profile</h3>
      <p></p>
    </div>
    <div class="form-x-content">
      <div class="form-x-inputs">
        <fieldset>
          <legend class="formlegend">Contact Information</legend>
          <div
            class="forminput"
            [ngClass]="{
              'forminput-x-error': firstName.touched && firstName.invalid
            }"
          >
            <label for="firstname">First Name</label>
            <div class="forminput-x-input">
              <input
                type="text"
                id="firstname"
                formControlName="first_name"
                placeholder="First Name"
              />
              <p
                class="forminput-x-errortext"
                *ngIf="
                  firstName.touched &&
                  firstName.invalid &&
                  firstName.hasError('required')
                "
              >
                {{ messages.required }}
              </p>
            </div>
          </div>
          <div
            class="forminput"
            [ngClass]="{
              'forminput-x-error': lastName.touched && lastName.invalid
            }"
          >
            <label for="lastname">Last Name</label>
            <div class="forminput-x-input">
              <input
                type="text"
                id="lastname"
                formControlName="last_name"
                placeholder="Last Name"
              />
              <p
                class="forminput-x-errortext"
                *ngIf="
                  lastName.touched &&
                  lastName.invalid &&
                  lastName.hasError('required')
                "
              >
                {{ messages.required }}
              </p>
            </div>
          </div>
          <div
            class="forminput"
            [ngClass]="{ 'forminput-x-error': email.touched && email.invalid }"
          >
            <label for="email">Email</label>
            <div class="forminput-x-input">
              <input
                type="text"
                id="email"
                formControlName="email"
                placeholder="Email"
              />
              <p
                class="forminput-x-errortext"
                *ngIf="
                  email.touched && email.invalid && email.hasError('required')
                "
              >
                {{ messages.required }}
              </p>
            </div>
          </div>
          <div class="forminput">
            <p class="forminput-x-label">Notifications</p>
            <div class="forminput-x-input">
              <div class="formcheckbox">
                <input
                  class="visuallyhidden"
                  type="checkbox"
                  id="notifications"
                  formControlName="receive_email_notifications"
                  value="notifications"
                />
                <label for="notifications">Receive email notifications</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset
          *ngIf="user.educator; else accountError"
          formGroupName="educator"
        >
          <legend class="formlegend">Background Information</legend>
          <input type="hidden" formControlName="id" />
          <div class="forminput">
            <label for="title">Title <span>(Optional)</span></label>
            <div class="forminput-x-input">
              <select
                formControlName="title"
                id="title"
                *ngIf="educatorChoices"
              >
                <option [ngValue]="''" disabled>Select...</option>
                <option
                  *ngFor="let choice of educatorChoices.title"
                  [value]="choice[0]"
                  [selected]="user.educator.title === choice[1]"
                >
                  {{ choice[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="forminput">
            <label for="gradelevelteaching"
              >Grade Level(s) Taught <span>(Optional)</span></label
            >
            <div class="forminput-x-input">
              <select
                formControlName="grade_level"
                id="gradelevelteaching"
                *ngIf="educatorChoices"
              >
                <option [ngValue]="''" disabled>Select...</option>
                <option
                  *ngFor="let choice of educatorChoices.grade_level"
                  [value]="choice[0]"
                  [selected]="user.educator.grade_level === choice[1]"
                >
                  {{ choice[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="forminput">
            <label for="contentareateaching"
              >Content Area Taught <span>(Optional)</span></label
            >
            <div class="forminput-x-input">
              <select
                formControlName="content_area"
                id="contentareateaching"
                *ngIf="educatorChoices"
              >
                <option [ngValue]="''" disabled>Select...</option>
                <option
                  *ngFor="let choice of educatorChoices.content_area"
                  [value]="choice[0]"
                  [selected]="user.educator.content_area === choice[1]"
                >
                  {{ choice[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="forminput">
            <label for="yearsofexperience"
              >Years of Experience <span>(Optional)</span></label
            >
            <div class="forminput-x-input">
              <select
                formControlName="years_of_experience"
                id="yearsofexperience"
                *ngIf="educatorChoices"
              >
                <option [ngValue]="''" disabled>Select...</option>
                <option
                  *ngFor="let choice of educatorChoices.years_of_experience"
                  [value]="choice[0]"
                  [selected]="user.educator.years_of_experience === choice[1]"
                >
                  {{ choice[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="forminput">
            <label for="highestdegreeearned"
              >Highest Degree Earned <span>(Optional)</span></label
            >
            <div class="forminput-x-input">
              <select
                formControlName="degree_earned"
                id="highestdegreeearned"
                *ngIf="educatorChoices"
              >
                <option [ngValue]="''" disabled>Select...</option>
                <option
                  *ngFor="let choice of educatorChoices.degree_earned"
                  [value]="choice[0]"
                  [selected]="user.educator.degree_earned === choice[1]"
                >
                  {{ choice[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="forminput forminput-arrow">
            <label for="schoolstate"
              >School State <span>(Optional)</span></label
            >
            <app-search-menu
              class="forminput-x-input"
              [onValueChange]="onStateValueChange"
              [searchPlaceholder]="'Search for a state ...'"
              [mapFun]="mapToSearchOption"
              formControlName="state"
            >
              <ng-template
                searchOption
                let-option="model"
                let-isActive="isActive"
              >
                <div [class.active]="isActive" class="search-option">
                  <p>{{ option.viewValue }}</p>
                </div>
              </ng-template>
              <div *searchEmptyState>
                <div class="search-option">
                  <p>No results found</p>
                </div>
              </div>
            </app-search-menu>
          </div>
          <div class="forminput forminput-arrow">
            <label for="schooldistrict"
              >School District <span>(Optional)</span></label
            >
            <app-search-menu
              class="forminput-x-input"
              [onValueChange]="onDistrictValueChange"
              [searchPlaceholder]="'Search for a district ...'"
              [mapFun]="mapToSearchOption"
              [disableSearch]="!state.value"
              formControlName="district"
            >
              <ng-template
                searchOption
                let-option="model"
                let-isActive="isActive"
              >
                <div [class.active]="isActive" class="search-option">
                  <p>{{ option.viewValue }}</p>
                </div>
              </ng-template>
              <div *searchEmptyState>
                <div class="search-option">
                  <p>No results found</p>
                </div>
              </div>
            </app-search-menu>
          </div>
          <div class="forminput forminput-arrow">
            <label for="schoolname">School Name</label>
            <app-search-menu
              class="forminput-x-input"
              [onValueChange]="onSchoolValueChange"
              [searchPlaceholder]="'Search for a school ...'"
              [mapFun]="mapToSearchOption"
              [disableSearch]="!district.value"
              formControlName="school"
            >
              <ng-template
                searchOption
                let-option="model"
                let-isActive="isActive"
              >
                <div [class.active]="isActive" class="search-option">
                  <p>{{ option.viewValue }}</p>
                </div>
              </ng-template>
              <div *searchEmptyState>
                <div class="search-option">
                  <p>No results found</p>
                </div>
              </div>
            </app-search-menu>
          </div>
          <div class="forminput">
            <p class="forminput-x-label">Private Demographic Information</p>
            <div class="forminput-x-input">
              <div class="formcheckbox">
                <input
                  class="visuallyhidden"
                  type="checkbox"
                  id="private_demographic_information"
                  formControlName="private_demographic_information"
                />
                <label for="private_demographic_information"
                  >Do not share my micro-credential data with my school,
                  district, state, or other affiliated organization.</label
                >
              </div>
            </div>
          </div>
        </fieldset>
        <ng-template #accountError>
          <strong
            >We're sorry, there is an error with your account. You are not
            listed as an Educator. Please contact administration.</strong
          >
        </ng-template>
        <div class="form-x-submit" *ngIf="user.educator">
          <button
            class="button button-secondary"
            type="button"
            aria-labelledby="form-heading"
            (click)="cancel()"
          >
            <span class="button-x-text">Cancel</span>
          </button>
          <button
            class="button"
            type="submit"
            aria-labelledby="form-heading"
            [disabled]="userForm.invalid"
          >
            <span class="button-x-text">Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
