import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { AssessmentExtension, AssessmentSection } from "../../assessment.type";
import { SubmissionRecommendation } from "../../submission-recommendation.type";
import { SubmissionRecommendationRubric } from "../../submission-recommendation-rubric.type";
import { Submission } from "app/shared/submission.type";

@Component({
  selector: "app-sections-assessment-extension",
  templateUrl: "./sections-assessment-extension.component.html",
  styleUrls: ["./sections-assessment-extension.component.css"],
})
export class SectionsAssessmentExtensionComponent implements OnInit {
  @Input() assessmentExtension: AssessmentExtension;
  @Input() recommendation: SubmissionRecommendation;
  @Input() rubricRecommendations: SubmissionRecommendationRubric[];
  @Input() previousRubricRecommendations: SubmissionRecommendationRubric[];
  @Input() textEvidence: { [assessmentQuestion: number]: string } = {};
  @Input() fileEvidence: { [assessmentQuestion: number]: string[] } = {};
  @Input() urlEvidence: { [assessmentQuestion: number]: string[] } = {};
  @Input() recommendForm: UntypedFormGroup;
  @Input() canShowRecommendation = false;
  @Input() showEditButton = false;
  @Input() showAssessorComment = false;
  @Input() comments: Comment[];
  @Input() submission: Submission;
  @Output() updated: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() notEditing: EventEmitter<boolean> = new EventEmitter();
  partsWithMarkdown = ["2"];

  constructor() { }

  ngOnInit() {
    const skippedRubrics = this.getSkippedRubrics();
    this.recommendForm.controls["rubric_recommendations"]["controls"].map(c => {
      // disable control (for validation) if a rubric falls under a skipped sections
      if (skippedRubrics.includes(c.value.rubric)) {
        c.disable();
      } else {
        c.enable();
      }
    })
  }

  getSkippedSections() {
    return this.submission?.sections?.map(s => s.section);
  }

  getSkippedRubrics() {
    // return all rubrics in skipped sections
    // reduce is being used to "flatten" the array after each mapping
    return this.assessmentExtension?.assessments?.map(a => {
        return a.sections?.filter(s => this.getSkippedSections().includes(s.id))
          .map(s => {
            return s.rubric ? [s.rubric.id] : s.questions?.map(q => {
              return q.rubric?.id
            }).reduce((a, v) => a.concat(v), [])
          })
          .reduce((a, v) => a.concat(v), [])
      })
      .reduce((a, v) => a.concat(v), [])
  }

  emitUpdate() {
    this.updated.emit(true);
  }

  emitIsEditing() {
    this.isEditing.emit(true);
  }

  emitNotEditing() {
    this.notEditing.emit(true);
  }

  getSectionSkipped(section: AssessmentSection) {
    const skippedSections = this.submission.sections.map(s => s.section);
    return skippedSections.includes(section.id);
  }
}
