<div
  *ngIf="question.rubric"
  class="attachment attachment-borderblue attachment-blue wrap wrap-interactive3"
  [class.previous-was-wrong]="previousRecommendation?.implies_not_passing"
>
  <div class="textinfo textinfo-expanded">
    <header class="textinfo-x-header">
      <h5>Assessment Rubric</h5>
    </header>
    <div class="textinfo-x-content">
      <fieldset
        *ngFor="
          let criterion of question.rubric.criterions;
          let criterionIndex = index
        "
      >
        <div
          class="forminput-x-input"
          *ngFor="
            let criterionLevel of criterion.criterion_levels;
            let criterionLevelIndex = index
          "
        >
          <div class="formradio formradio-bold">
            <input
              class="visuallyhidden"
              type="radio"
              [checked]="criterionLevels.includes(criterionLevel.id)"
              (click)="
                setRubricRecommendationCriterionLevel(
                  criterionLevel.id,
                  criterion.id
                )
              "
              value="{{ criterion.id }},{{ criterionLevel.id }}"
              name="question_{{ criterion.id }}"
              id="question_{{ question.id }}_criterionlevel_{{
                criterionLevel.id
              }}"
            />
            <label
              for="question_{{ question.id }}_criterionlevel_{{
                criterionLevel.id
              }}"
            >
              {{ criterionLevel.quality }}
              <span
                class="forminput-x-sublabel"
                [innerHTML]="criterionLevel.description | newlineToBr"
              ></span>
            </label>
          </div>
        </div>
        <app-rubric-comment-event
          (commentChange)="onCommentChange($event)"
          [criterion]="criterion"
          [comment]="criterionComments[criterion.id]"
          [isReadOnly]="false"
        >
        </app-rubric-comment-event>
        <app-submission-comments
          [data]="comments"
          [info]="commentsInfo[criterion.id]"
        >
          <hr class="comments__hr" />
          <label class="forminput-x-label">Assessor Comments (Private):</label>
        </app-submission-comments>
      </fieldset>
    </div>
  </div>
</div>
