import { Component, Input } from "@angular/core";

import { AssessmentQuestion } from "../../assessment.type";
import { PreviousRecommendationRubricMixin } from "../../previous-recommendation-rubric-mixin/previous-recommendation-rubric.mixin";

@Component({
  selector: "app-rubric-question",
  templateUrl: "./rubric-question.component.html",
  styleUrls: ["./rubric-question.component.css"],
})
export class RubricQuestionComponent extends PreviousRecommendationRubricMixin {
  assessmentType = "question";
  @Input() question: AssessmentQuestion;
}
