import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";

import { AssessmentSection } from "../../assessment.type";

@Component({
  selector: "app-navigation-sections",
  templateUrl: "./navigation-sections.component.html",
  styleUrls: ["./navigation-sections.component.css"],
})
export class NavigationSectionsComponent {
  @Input() completionText: string;
  @Input() allSections: AssessmentSection[] = [];
  @Input() showNote = false;
  @Input() showNoteOnTop = true;
  @Output() navigateTo: EventEmitter<string> = new EventEmitter();

  @HostBinding("attr.class") className = "l-detail-x-stickycontainer";

  emitNavigateTo(id: string) {
    this.navigateTo.emit(id);
    return false;
  }
}
