<div
[class.textinfo-x-hidden]="
(isRecommended  && !recommendation.to_award && !editing) || (!editing && isRecommended && !recommendation.to_award )

"
[class.l-hidden]="

(isRecommended  && !recommendation.to_award && !editing) || (!editing && isRecommended && !recommendation.to_award )
"
tabindex="-1"
>
<div class="forminput-x-input">
  <div class="formradio formradio-bold">
    <input
      class="visuallyhidden"
      type="radio"
      [formControl]="recommendationToAward"
      
      name="{{name_setup}}"
      id="{{name_setup}}-1"
      [checked]="recommendation?.to_award"
      [attr.disabled]="disabled ? true : null"
      [value]="true"
    />
    <label for="{{name_setup}}-1"
      >  {{isDecision ? '': 'Recommend '}}Award</label
    >
  </div>
</div>
</div>
<div
[class.textinfo-x-hidden]="
  
(isRecommended  && recommendation.to_award && !editing) || (!editing && isRecommended && recommendation.to_award )
"
[class.l-hidden]="

(isRecommended  && recommendation.to_award && !editing) || (!editing && isRecommended && recommendation.to_award )
"
tabindex="-1"
>
<div class="forminput-x-input">
  <div class="formradio formradio-bold">
    <input
      class="visuallyhidden"
      type="radio"
      [formControl]="recommendationToAward"
      
      name="{{name_setup}}"
      id="{{name_setup}}-0"
      [checked]="
        isRecommended && !recommendation.to_award
      "
      [attr.disabled]="disabled ? true : null"
      [value]="false"
    />
    <label for="{{name_setup}}-0"
      >  {{isDecision ? '': 'Recommend '}}Deny</label
    >
  </div>
</div>
</div>