<form
  class="l-search wrap l-containerhorizontal"
  action=""
  role="form"
  aria-labelledby="heading-search"
>
  <h2 class="visuallyhidden" id="heading-search">Search</h2>
  <fieldset>
    <legend class="visuallyhidden">Filters</legend>
    <div class="l-search-x-filters">
      <div class="forminput">
        <label for="search" class="forminput-x-label">Search</label>
        <form
          (submit)="setSearch(search.value)"
          class="forminput-x-input forminput-x-inputsearch"
        >
          <input
            type="text"
            id="search"
            placeholder="Search for micro-credentials"
            #search
            [attr.value]="filters.search"
          />
          <!--
                    <input (click)="updateUrl('search', search.value);" class="button button-large button-inline" type="button" value="Go" />
                    <input (click)="updateUrl('search', ''); search.value = ''" class="button button-large button-inline" type="button" value="Clear" />
                    -->
        </form>
      </div>
      <div class="forminput">
        <p for="organization" class="forminput-x-label icon icon-organization">
          Issuing Organization
        </p>
        <div class="forminput-x-input">
          <select
            (change)="
              setOrganization($event.currentTarget.selectedOptions[0].value)
            "
            name="organization"
          >
            <option value>All Organizations</option>
            <option
              *ngFor="let organization of organizations"
              value="{{ organization.name }}"
              [selected]="filters?.organization__name === organization.name"
            >
              {{ organization.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="forminput" *ngIf="tags">
        <p class="forminput-x-label icon icon-stack" for="tag">Stack</p>
        <div class="forminput-x-input">
          <select
            (change)="setStack($event.currentTarget.selectedOptions[0].value)"
            name="tag"
          >
            <option value>All Stacks</option>
            <option
              *ngFor="let tag of tags"
              value="{{ tag }}"
              [selected]="filters?.tag === tag"
            >
              {{ tag }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<div class="l-containerhorizontal" *ngIf="credentialCount">
  <div class="l-resultsheader">
    <h2 class="textsmall">{{ credentialCount }} Results</h2>
    <div class="l-resultsheader-x-controls">
      <app-pagination
        [totalPages]="totalPages"
        [currentPage]="currentPage"
        (choosePage)="choosePage($event)"
      ></app-pagination>
      <div class="l-switchselect">
        <div class="l-switchselect-x-mobile">
          <div class="jumpsection">
            <label class="visuallyhidden" for="jumpsection1"
              >Jump to Section:</label
            >
            <select
              #newPerPage1
              (change)="setPageSize(newPerPage1.value)"
              name="jumpsection1"
              id="jumpsection1"
            >
              <option value="24" [selected]="pageSize === 24">24</option>
              <option value="48" [selected]="pageSize === 48">48</option>
              <option value="96" [selected]="pageSize === 96">96</option>
            </select>
          </div>
        </div>
        <div class="l-switchselect-x-mid">
          <div class="jumpsection">
            <label class="visuallyhidden" for="jumpsection2"
              >Jump to Section:</label
            >
            <select
              #newPerPage2
              (change)="setPageSize(newPerPage2.value)"
              name="jumpsection2"
              id="jumpsection2"
            >
              <option value="24" [selected]="pageSize === 24">Show 24</option>
              <option value="48" [selected]="pageSize === 48">Show 48</option>
              <option value="96" [selected]="pageSize === 96">Show 96</option>
            </select>
          </div>
        </div>
        <div class="l-switchselect-x-tablet">
          <div class="jumpsection">
            <label class="visuallyhidden" for="jumpsection3"
              >Jump to Section:</label
            >
            <select
              #newPerPage3
              (change)="setPageSize(newPerPage3.value)"
              name="jumpsection3"
              id="jumpsection3"
            >
              <option value="24" [selected]="pageSize === 24">
                Show 24 Results
              </option>
              <option value="48" [selected]="pageSize === 48">
                Show 48 Results
              </option>
              <option value="96" [selected]="pageSize === 96">
                Show 96 Results
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="credentialsTags">
    <ng-container *ngFor="let tag of pageTags">
      <h2 class="textruled">
        <p class="icon icon-stack">{{ tag }}</p>
      </h2>
      <div class="l-gridfour">
        <app-credential-card
          *ngFor="let credential of credentialsTags[tag]"
          [credential]="credential"
          [draft]="getDraft(credential.id)"
          [submission]="credential.latest_request_user_submission"
        >
        </app-credential-card>
      </div>
    </ng-container>
  </ng-container>

  <app-pagination
    [alt]="true"
    [totalPages]="totalPages"
    [currentPage]="currentPage"
    (choosePage)="choosePage($event)"
  ></app-pagination>
</div>

<div class="l-containerhorizontal" *ngIf="loaded && credentialCount <= 0">
  <div class="l-resultsheader">
    <h2 class="textsmall">0 Results</h2>
  </div>

  <div class="searchempty">
    <div class="searchempty-x-content">
      <div class="searchempty-x-image wrap"></div>
      <div class="searchempty-x-text">
        <h3 class="searchempty-x-heading">
          Sorry, no results were found
          <span *ngIf="filters.search"> for “{{ filters.search }}”</span>
        </h3>
        <p class="textsmall">Suggestions:</p>
        <ul class="searchempty-x-description">
          <li>Double check your spelling</li>
          <li>Try more general or different keywords</li>
          <li>Try fewer keywords</li>
          <li>Try removing any applied filters</li>
        </ul>
      </div>
    </div>
  </div>
</div>
