<div class="l-textmessage">
  <div class="l-textmessage-x-bordered">
    <p class="textbordered">!</p>
  </div>
  <div class="textmessage">
    <h3 class="textmessage-x-heading">Download to view this file</h3>
    <p>This file must be downloaded in order to view it.</p>
  </div>
  <a
    class="button"
    type="button"
    [attr.href]="file?.url | safeUrl"
    download
    target="_blank"
  >
    <span class="button-x-text">Download</span>
  </a>
</div>
