<article class="cardreview cardreview-empty card">
  <div class="cardreview-x-image"></div>
  <div class="cardreview-x-content">
    <header>
      <div class="cardreview-x-text">
        <h3 class="cardreview-x-heading">{{ heading }}</h3>
        <p class="cardreview-x-description">{{ description }}</p>
        <div class="cardreview-x-actions">
          <ng-content> </ng-content>
        </div>
      </div>
    </header>
  </div>
</article>
