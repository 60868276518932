import { Component, HostListener, OnInit } from "@angular/core";
import { SubmissionCommentBaseComponent } from "./submission-comment-base.component";

@Component({
  selector: "app-submission-comment",
  templateUrl: "./submission-comment.component.html",
  styleUrls: ["./submission-comment-base.component.css"],
})
export class SubmissionCommentComponent
  extends SubmissionCommentBaseComponent
  implements OnInit
{
  openedDropdownId = null;

  ngOnInit() {
    super.ngOnInit();
  }

  @HostListener("window:click")
  onHostClick() {
    this.openedDropdownId = null;
  }

  openDropdown(id: number) {
    this.openedDropdownId = id;
  }
}
