<div
  *ngIf="impliesNotPassing === true"
  class="message message-progress message-noicon"
>
  <h3 class="visuallyhidden" id="message-progress">Form Message</h3>
  <p>
    Please give this area additional attention. It was marked as needing
    improvement on the previous submission.
  </p>
</div>
