<div class="l-containerhorizontal">
  <div class="l-gridfour" *ngIf="drafts?.length > 0">
    <app-credential-card
      *ngFor="let draft of drafts"
      [credential]="draft.credential"
      [draft]="draft"
    >
    </app-credential-card>
  </div>

  <app-empty-state
    *ngIf="drafts?.length === 0"
    [heading]="emptyHeading"
    [description]="emptyDescription"
  >
    <button class="button" type="button" routerLink="/explore">
      <span class="button-x-text">Explore</span>
    </button>
  </app-empty-state>
</div>
