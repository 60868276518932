/* eslint-disable curly */
import { Component, Input, OnInit } from "@angular/core";
import { Credential } from "../credential.type";
import { Submission } from "../submission.type";
import { Draft } from "../draft.type";
import { StatusType } from "../status.type";
import { CredentialDetailService } from "app/credential-detail/credential-detail.service";
import { LevelBadgeType } from "../assessment.type";
import { forEach } from "lodash";
import { type } from "os";

@Component({
  selector: "app-credential-card",
  templateUrl: "./credential-card.component.html",
  styleUrls: ["./credential-card.component.css"],
})
export class CredentialCardComponent implements OnInit {
  @Input() credential: Credential;
  @Input() submission: Submission;
  @Input() draft: Draft;
  STATUS = StatusType;
  private max_level: number;
  private awarded_level: number;

  private highest_level_awarded : boolean = false;

  constructor(
    private credentialDetailService: CredentialDetailService) {}

  ngOnInit(): void {
    if(this.submission != null){
      if (this.submission.related_level_badge)
      {
        this.awarded_level = this.submission.related_level_badge.level;
        this.max_level = this.submission.related_level_badge.max_level;
        if(this.awarded_level == this.max_level){
          this.highest_level_awarded = true;
        }
      }
    }

  }

  getStatus(statusName) {
    if(statusName == null){
      return null;
    }  
    if(statusName.indexOf("awarded") > -1){
      return "awarded";
    }
    return statusName;
  }

  getPriceDisplay() {
    const price = this.submission
      ? this.credential.resubmission_price
      : this.credential.price;
    const suffix = this.submission ? " to reapply" : "";
    const priceValue = parseFloat(price);
    const priceString = priceValue > 0 ? price : "Free";
    return `${priceString}${suffix}`;
  }

  getLatestSubmissionRoute(submission) {
    if (
      submission.recommendation &&
      submission.recommendation.decision &&
      submission.recommendation.decision.award
    ) {
      return ["/awarded", submission.recommendation.decision.award.id];
    }
    return ["/dashboard", "submission", submission.readable_id];
  }
}
