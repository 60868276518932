import { Injectable } from "@angular/core";

import { Subject, BehaviorSubject } from "rxjs";

import { ApiGetService } from "../core/apiget.service";
import { HistoryData } from "../history.type";
import { Credential } from "../credential.type";
import { Confirmation } from "../messages.constants";

@Injectable()
export class EditCredentialService {
  credentialUpdated$: Subject<Credential> = new Subject();

  editCredentialModalisOpen = new BehaviorSubject<boolean>(false);
  selectedCredential = new BehaviorSubject<Credential>(null);
  updateList = new Subject<Confirmation>();
  errorMessage = new Subject<any>();

  constructor(private apiGetService: ApiGetService) {}

  updateCredential(credentialId, data) {
    return this.apiGetService.patch(`credential/${credentialId}/`, data);
  }

  getCredentialHistory(credentialId: number): Promise<HistoryData[]> {
    return this.apiGetService.get<HistoryData[]>(
      `history/?related_object_type__model=credential&related_object_pk=${credentialId}`
    );
  }
}
