import {
  Component,
  Input,
  Optional,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  ControlContainer,
} from "@angular/forms";

import { ToastrService } from "ngx-toastr";

import { isBoolean } from "lodash";

import { RecommendationCreateService } from "../../../recommendation-create/recommendation-create.service";

import { SubmissionRecommendation } from "../../submission-recommendation.type";
import { SubmissionDecision } from "../../submission-decision.type";
import { CommentInfo } from "app/shared/comment.type";
import { Submission } from "app/shared/submission.type";

@Component({
  selector: "[data-app-subsection-recommendation-form]",
  templateUrl: "./subsection-recommendation-form.component.html",
  styleUrls: ["./subsection-recommendation-form.component.css"],
})
export class SubsectionRecommendationFormComponent implements OnInit {
  @Input() submission: Submission
  @Input() recommendation: SubmissionRecommendation;
  @Input() credential: Credential | Number;
  @Input() leveled = false;
  @Input() comments: Comment[];
  @Input() commentsInfo: CommentInfo; 
  @Input() decision: SubmissionDecision;
  @Input() canCreate = false;
  @Input() showEditButton = false;
  @Input() showRecommendationComment = false;
  recommendationToAward: UntypedFormControl = new UntypedFormControl();
  skippedSections = [];
  editing = false;
  isRecommended = false;
  form: UntypedFormGroup;
  @Output() updated: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() notEditing: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Optional() public container: ControlContainer,
    private messageService: ToastrService,
    private fb: UntypedFormBuilder,
    private recommendationCreateService: RecommendationCreateService
  ) {}

  ngOnInit() {
    this.isRecommended =
      this.recommendation && isBoolean(this.recommendation.to_award);
    if (this.recommendation) {
      this.instantiateForm(this.recommendation);
      this.recommendationToAward.setValue(this.recommendation.to_award);
    }
    if(this.submission.sections) {
      for (let section of this.submission.sections) {
        if (section.skipped) {
          this.skippedSections.push(section.section);
        }
      }
    }
  }

  instantiateForm(recommendation: SubmissionRecommendation) {
    this.form = this.fb.group({
      assessor: recommendation.assessor,
      decision: recommendation.decision,
      leveled_badge: recommendation.leveled_badge,
      id: recommendation.id,
      rubric_recommendations: this.fb.array(
        recommendation.rubric_recommendations
      ),
      submission: recommendation.submission,
      to_award: recommendation.to_award,
    });
  }

  startUpdateSubmissionRecommendation() {
    this.editing = true;
    this.isEditing.emit(true);
  }

  exitUpdateSubmissionRecommendation() {
    this.editing = false;
    this.notEditing.emit(true);
    this.instantiateForm(this.recommendation);
  }

  saveUpdateSubmissionRecommendation() {
    if (this.recommendation && this.form) {
      this.recommendationCreateService
        .putRecommendation(this.form.value)
        .then((recommendation: SubmissionRecommendation) => {
          this.recommendation = recommendation;
          this.exitUpdateSubmissionRecommendation();
          this.updated.emit(true);
          this.messageService.success("Assessor's recommendation updated");
        })
        .catch((_err) => {
          this.exitUpdateSubmissionRecommendation();
          this.messageService.error(
            "Failed to update assessor's recommendation",
            ""
          );
        });
    }
  }
}
