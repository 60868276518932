import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PublicGuard } from "../public.guard";

import { FaqsComponent } from "./faqs.component";

const routes: Routes = [
  {
    path: "faqs",
    component: FaqsComponent,
    canActivate: [PublicGuard],
    data: { title: "FAQs" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FaqsRoutingModule {}
