import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent {
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Input() alt = false;
  @Output() choosePage = new EventEmitter<number>();
  @ViewChild("pageLink", { static: true }) pageLinkTemplate: TemplateRef<any>;
  @ViewChild("placeholder", { static: true })
  placeholderTemplate: TemplateRef<any>;

  get pageLinks(): PaginationItem[] {
    if (this.totalPages <= 7) {
      return this.iterate(this.totalPages).map((page) => new PageLink(page));
    }

    if (this.onFirstFourPages(this.currentPage)) {
      // 1, 2, 3, 4, 5 ..., 9
      return [
        new PageLink(1),
        new PageLink(2),
        new PageLink(3),
        new PageLink(4),
        new PageLink(5),
        new PageLinkPlaceholder(),
        new PageLink(this.totalPages),
      ];
    }

    if (this.onLastFourPages(this.currentPage)) {
      // 1, ..., 5, 6, 7, 8, 9
      return [
        new PageLink(1),
        new PageLinkPlaceholder(),
        new PageLink(this.totalPages - 4),
        new PageLink(this.totalPages - 3),
        new PageLink(this.totalPages - 2),
        new PageLink(this.totalPages - 1),
        new PageLink(this.totalPages),
      ];
    }

    // 1, ..., 5, 6, 7, ..., 12
    return [
      new PageLink(1),
      new PageLinkPlaceholder(),
      new PageLink(this.currentPage - 1),
      new PageLink(this.currentPage),
      new PageLink(this.currentPage + 1),
      new PageLinkPlaceholder(),
      new PageLink(this.totalPages),
    ];
  }

  getTemplate(item: PaginationItem) {
    return item instanceof PageLinkPlaceholder
      ? this.placeholderTemplate
      : this.pageLinkTemplate;
  }

  iterate(totalPages): number[] {
    return new Array(totalPages).fill("").map((_, index) => index + 1);
  }

  selectPage(pageNumber) {
    this.choosePage.emit(pageNumber);
    return false;
  }

  onFirstFourPages(currentPage) {
    // return (currentPage >= this.totalPages - 3) || (currentPage <= 4);
    return currentPage <= 4;
  }

  onLastFourPages(currentPage) {
    return currentPage >= this.totalPages - 3;
  }
}

abstract class PaginationItem {}

class PageLink extends PaginationItem {
  page: number;

  constructor(page) {
    super();
    this.page = page;
  }
}

class PageLinkPlaceholder extends PaginationItem {}
