<div *ngIf="decision && (ownAward || isStaff)" class="submissionstate-x-content">
  <div class="submissionstate-text-content">
    <h1 *ngIf="decision.to_award">
      Congratulations!
    </h1>
    <h1 *ngIf="!decision.to_award">
      Your application was denied.
    </h1>
    <p>
      {{submission.user.full_name}} was
      <ng-container *ngIf="decision.to_award">awarded</ng-container>
      <ng-container *ngIf="!decision.to_award">denied</ng-container>
      {{submission.credential.organization.name}}'s
      {{submission.credential.name}}
      {{credential_certification}}
      on {{ decision.created_at | date: "mediumDate" }}.
    </p>

    <ng-container *ngIf="decision.to_award">
      <ng-container *ngIf="!isLeveled">
       
      </ng-container>
      <ng-container *ngIf="isLeveled">
        <div class="submissionstate-stars">
          <div *ngFor="let star of stars">
            <div class="icon icon-star-filled"></div>
          </div>
          <div *ngFor="let star of max_stars">
            <div class="icon icon-star"></div>
          </div>
        </div>
        <p>
          Your product received a level {{ submission.related_level_badge.level }} badge, representing {{submission.related_level_badge.proficiency_qualifier}}
          proficiency in the criteria. 
          
          <span *ngIf="submission.related_level_badge.level >= submission.related_level_badge.max_level">
          We issue this certification at {{submission.related_level_badge.max_level}} possible
          levels, with level {{submission.related_level_badge.max_level}} being the highest.
          </span>

          <span *ngIf="submission.related_level_badge.level < submission.related_level_badge.max_level">
            You can review the feedback below, and reapply for a higher badge when you are ready.
          </span>
        </p>

        <ng-container *ngIf="!submission.reapplied && submission.related_level_badge.level < submission.related_level_badge.max_level">
          <div class="submissionstate-x-action">
            <button [routerLink]="['/explore', submission.credential.slug, 'application']" class="button button-secondary"
              type="button">
              <span class="button-x-text">Reapply &rsaquo;</span>
            </button>
            <p>${{ submission.credential.resubmission_price }} to reapply</p>
          </div>
        </ng-container>
      </ng-container>

      <p>
        Your product certification is an Open Badge. Open Badges are the
        world's leading format for digital badges, and can be shared across
        your product's website, social media, or edtech procurement
        platform. <a href="https://productcertifications.digitalpromise.org/research-based-design-criteria/"
          target="_blank">Learn more about Open Badges</a>.
      </p>
    </ng-container>

    <ng-container *ngIf="!decision.to_award">
      <p>
        Review the feedback below and reapply when you are ready. If you need help understanding our criteria
        or the feedback you recieved, please reach out to
        <a href="mailto:MCSupport@digitalpromise.org" target="_blank">MCSupport@digitalpromise.org</a>.
      </p>

      <ng-container *ngIf="!submission.reapplied">
        <div class="submissionstate-x-action">
          <button [routerLink]="['/explore', submission.credential.slug, 'application']" class="button button-secondary"
            type="button">
            <span class="button-x-text">
              Reapply &rsaquo;
            </span>
          </button>
          <p>${{ submission.credential.resubmission_price }} to reapply</p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="decision.to_award" class="submissionstate-badge">
    <div class="submissionsstate-badge-body">
      <div class="submissionsstate-badge-overlap-border"></div>
      <img src="{{ awardedCredential.image }}" width="240" height="240" />
      <h3>
        <a class="icon-x-text" href="{{ awardedCredential.resource_url }}" target="_blank">
          {{awardedCredential.name}}
        </a>
      </h3>
      <p>Awarded on {{ decision.created_at | date: "mediumDate" }} to</p>
      <h4>{{submission.user.full_name}}</h4>
      <p>
        by <a href="{{ submission.credential.organization.url }}" target="_blank">
          {{submission.credential.organization.name}}
        </a>
      </p>
    </div>
    <div class="submissionsstate-badge-footer"></div>
  </div>
</div>