<div
  class="l-dialog dialog"
  [ngClass]="{ 'dialog-is-open': modalIsOpen }"
  aria-modal="true"
  role="dialog"
  ngxFocusTrap
  #ngxFocus="ngxFocusTrap"
  [initActivated]="false"
>
  <div class="dialog-x-content wrap">
    <div class="dialog-x-close">
      <button aria-labelledby="" type="button" (click)="closeDialog()">
        Close Dialog
      </button>
    </div>
    <form
      [formGroup]="assessorForm"
      (ngSubmit)="onSubmit()"
      class="l-dialogform"
      novalidate
    >
      <div class="dialogheader">
        <h3
          class="dialogheader-x-heading"
          *ngIf="currentSubmission?.assessor; else assignTitle"
        >
          Reassign Assessor
        </h3>
        <ng-template #assignTitle>
          <h3 class="dialogheader-x-heading">Assign Assessor</h3>
        </ng-template>
        <p></p>
      </div>
      <div class="l-dialogform-x-maininfo" *ngIf="roles.length > 0">
        <p>
          Select an assessor to assign to this submission.
          <span>{{
            currentSubmission ? currentSubmission.readable_id : ""
          }}</span>
        </p>
      </div>
      <fieldset class="forminput" *ngIf="roles.length > 0">
        <legend class="forminput-x-label">Available Assessor</legend>
        <div class="forminput-x-input">
          <div class="formradio" *ngFor="let role of roles">
            <input
              class="visuallyhidden"
              type="radio"
              formControlName="assessor"
              id="assessor_{{ role.user.id }}"
              [value]="role.user.id"
            />
            <label for="assessor_{{ role.user.id }}">
              <ng-container
                *ngIf="
                  resubmission?.assessor.id === role.user.id;
                  else notPreviousAssessor
                "
              >
                <strong
                  >{{ role.user.last_name }}, {{ role.user.first_name
                  }}<span *ngIf="role.role_type === 'issuer'"> (Issuer)</span
                  ><span> - assessed previous submission</span></strong
                >
              </ng-container>
              <ng-template #notPreviousAssessor>
                {{ role.user.last_name }}, {{ role.user.first_name
                }}<span *ngIf="role.role_type === 'issuer'"> (Issuer) </span
                ><span> ({{ role.assigned_submissions_count }})</span>
              </ng-template>
            </label>
          </div>
        </div>
      </fieldset>

      <div class="l-dialogform-x-maininfo" *ngIf="roles.length === 0">
        <p>No Assessor</p>
      </div>

      <div class="l-dialogform-x-button">
        <button class="button" type="submit" [disabled]="assessorForm.invalid">
          <span class="button-x-text">Assign</span>
        </button>
      </div>
    </form>
  </div>
</div>
