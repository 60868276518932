import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PublicGuard } from "../public.guard";

import { AwardedCredentialDetailComponent } from "./awarded-credential-detail.component";
import { SiteGuard } from "app/site.guard";

const routes: Routes = [
  {
    path: "awarded/:id",
    component: AwardedCredentialDetailComponent,
    canActivate: [PublicGuard, SiteGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AwardedCredentialDetailRoutingModule {}
