<div *ngIf="entries.length > 0" class="l-detail-x-subsection wrap textblock">
  <h2>
    <span>{{ title || "Submission History" }}</span>
  </h2>
  <table class="tablehistory tablehistory-big">
    <thead>
      <tr>
        <th scope="row" class="visuallyhidden">Date</th>
        <th scope="row" class="visuallyhidden">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let entry of entries">
        <td>{{ entry.created_at | date: "mediumDate" }}</td>
        <td>{{ entry.log_message }}</td>
      </tr>
    </tbody>
  </table>
</div>
