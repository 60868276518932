<div
  *ngIf="comments.length || isAddAllowed"
  class="textblock textinfo-x-comment {{ clsWrap }}"
>
  <ng-content></ng-content>
  <form #form="ngForm" class="textblock list">
    <div *ngIf="comments.length" class="comments">
      <div
        *ngFor="let comment of comments; trackBy: commentTrackBy"
        class="attachment attachment-big wrap wrap-light2 comment"
      >
        <app-submission-comment
          [data]="comment"
          [actionScope]="actionsScopeFor(comment)"
          (submitCommentEvent)="onSubmitComment($event)"
          (removeCommentEvent)="onRemoveComment($event)"
        >
        </app-submission-comment>
      </div>
    </div>
    <div *ngIf="isAddAllowed">
      <app-submission-comment-new
        [data]="newComment"
        [actionScope]="actionsScopeFor(newComment)"
        (submitCommentEvent)="onSubmitComment($event)"
      >
      </app-submission-comment-new>
    </div>
  </form>
</div>
