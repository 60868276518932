import { tap, debounceTime } from "rxjs/operators";
/* eslint-disable curly */
import {
  Component,
  Input,
  OnInit,
  ContentChild,
  ViewChild,
  HostListener,
  ElementRef,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { SearchOptionDirective } from "./search-option.directive";
import { SearchEmptyStateDirective } from "./search-empty-state.directive";

import { Subject, Observable } from "rxjs";

import { SearchOption } from "./search-option.type";
import * as KEY from "./keycodes";

type SearchOptionFun = (term: Observable<string>) => Observable<SearchOption[]>;
type MapToSearchOption = (option: any) => SearchOption;
const DO_NOT_TRIGGER_SEARCH = [
  KEY.UP_ARROW,
  KEY.DOWN_ARROW,
  KEY.LEFT_ARROW,
  KEY.RIGHT_ARROW,
  KEY.ESCAPE,
];

@Component({
  selector: "app-search-menu",
  templateUrl: "./search-menu.component.html",
  styleUrls: ["./search-menu.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchMenuComponent),
      multi: true,
    },
    // {
    //   provide: NG_VALIDATORS,
    //   useExisting: forwardRef(() => SearchMenuComponent),
    //   multi: true
    // }
  ],
})
export class SearchMenuComponent implements OnInit, ControlValueAccessor {
  private changeSubject = new Subject<string>();
  options: SearchOption[] = [];
  currentlyActive: SearchOption;
  currentViewValue = "";
  options$: Observable<SearchOption[]>;
  isOpen = false;
  cursor = 0;
  searchBoxCursorPosition = 0;
  searchOptionHeight = null;
  searchBoxHeight = null;

  @ContentChild(SearchOptionDirective, { static: true })
  searchOption: SearchOptionDirective;
  @ContentChild(SearchEmptyStateDirective, { static: true })
  searchEmptyState: SearchEmptyStateDirective;

  @ViewChild("searchInput", { static: true }) searchInput: any;
  @ViewChild("searchBox", { static: true }) searchBox: any;
  @ViewChild("localSearchOption", { static: true }) localSearchOption: any;

  @Input() searchPlaceholder: string;
  @Input() mapFun: MapToSearchOption;
  @Input() disableSearch = false;

  @HostListener("window:click", ["$event"])
  handleClick(event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.searchInput.nativeElement.focus();
      if (!this.isOpen && !this.disableSearch) {
        this.changeSubject.next(this.currentViewValue);
        this.isOpen = true;
      }
    } else {
      if (this.isOpen) this.closeDropdown();
      this.searchInput.nativeElement.blur();
    }
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyupEvent(event) {
    if (!this.isOpen) return;

    if (
      !this.searchOptionHeight &&
      this.localSearchOption &&
      this.localSearchOption.nativeElement.offsetHeight > 0
    ) {
      this.searchOptionHeight =
        this.localSearchOption.nativeElement.offsetHeight;
    }

    switch (event.keyCode) {
      case KEY.UP_ARROW:
        if (this.cursor > 0) {
          this.cursor--;
          if (this.searchBoxCursorPosition === 0) {
            this.searchBox.nativeElement.scrollTop -= this.searchOptionHeight;
          } else {
            this.searchBoxCursorPosition -= 1;
          }
        }
        break;
      case KEY.DOWN_ARROW:
        if (this.cursor < this.options.length - 1) {
          this.cursor++;
          const optionsInBox = Math.floor(
            this.searchBoxHeight / this.searchOptionHeight
          );

          if (this.searchBoxCursorPosition === optionsInBox - 1) {
            this.searchBox.nativeElement.scrollTop += this.searchOptionHeight;
          } else {
            this.searchBoxCursorPosition += 1;
          }
        }
        break;
      case KEY.ESCAPE:
        this.closeDropdown();
        this.searchInput.nativeElement.blur();
        break;
      case KEY.ENTER:
        event.preventDefault();
        this.currentlyActive = this.options[this.cursor];
        this.currentViewValue = this.options[this.cursor].viewValue;
        this.onChange(this.options[this.cursor].model);
        this.closeDropdown();
        this.searchInput.nativeElement.blur();
        break;
    }
  }

  constructor(private elementRef: ElementRef) {}

  get valueChanges$() {
    return this.changeSubject.asObservable();
  }

  @Input()
  set onValueChange(fn: SearchOptionFun) {
    this.options$ = this.valueChanges$.pipe(
      debounceTime(300),
      (value) => fn(value),
      tap((options: SearchOption[]) => {
        this.options = options;
        this.cursor = 0;
        this.searchBoxCursorPosition = 0;
        if (!this.isOpen) this.isOpen = true;
        setTimeout(
          () =>
            (this.searchBoxHeight = this.searchBox.nativeElement.offsetHeight)
        );
      })
    );
  }

  onInputChange(event) {
    if (DO_NOT_TRIGGER_SEARCH.indexOf(event.keyCode) !== -1) return;
    this.changeSubject.next(event.target.value);
  }

  onInputBlur(searchInput) {
    if (searchInput.value === "") {
      this.currentlyActive = null;
      this.onChange(null);
    } else {
      if (this.currentlyActive) {
        this.currentViewValue = this.currentlyActive.viewValue;
        searchInput.value = this.currentlyActive.viewValue;
        return;
      }
      this.currentViewValue = null;
    }
  }

  onInputFocus(searchInput) {
    searchInput.select();
  }

  closeDropdown() {
    this.isOpen = false;
    this.cursor = 0;
    this.searchBoxCursorPosition = 0;
  }

  resetValues() {
    this.currentViewValue = "";
    this.currentlyActive = null;
    this.onChange(null);
  }

  isActive(option: SearchOption, index: number) {
    if (this.currentlyActive) {
      return (
        this.currentlyActive.model === option.model || index === this.cursor
      );
    }
    return index === this.cursor;
  }

  handleOptionClicked(option: SearchOption) {
    this.currentlyActive = option;
    this.currentViewValue = option.viewValue;
    this.onChange(option.model);
    this.closeDropdown();
  }

  writeValue(value: any) {
    if (value) {
      this.currentlyActive = this.mapFun(value);
      this.currentViewValue = this.currentlyActive.viewValue;
      return;
    }
    this.currentlyActive = null;
    this.currentViewValue = null;
  }

  onChange = (val: any) => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() {}

  ngOnInit() {
    this.options$.subscribe();
  }
}
