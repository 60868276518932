import { Directive, Input } from "@angular/core";
import { TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[ngRepeat]",
})
export class NgRepeat {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set ngRepeat(times: number) {
    this.viewContainer.clear();
    for (let x = 0; x < times; x++) {
      this.viewContainer.createEmbeddedView(this.templateRef, { index: x });
    }
  }
}
