<app-toast-message-container></app-toast-message-container>
<router-outlet name="popup"></router-outlet>

<div [attr.aria-hidden]="mobileMenu || modalOpen ? 'true' : 'false'">
  <h1 class="visuallyhidden">Digital Promise</h1>
</div>

<div
  class="l-inertcontainer"
  [attr.aria-hidden]="mobileMenu ? 'true' : 'false'"
>
  <nav
    class="quicklinks"
    style="position: relative"
    [attr.aria-hidden]="mobileMenu || modalOpen ? 'true' : 'false'"
  >
    <h1 class="visuallyhidden">Quick Links</h1>
    <ul>
      <li>
        <button type="button" role="link" (click)="skipToContent()">
          Go to page content
        </button>
      </li>
    </ul>
  </nav>
  <nav class="l-menudesktop wrap" role="navigation" aria-labelledby="header">
    <div class="l-menudesktop-x-logo menudesktoplogo" id="header">
      <a [routerLink]="['/', 'explore']">Digital Promise, Homepage</a>
    </div>
    <div class="l-menudesktop-x-primary l-containerhorizontal">
      <div
        *ngIf="!noSite"
        class="l-menudesktop-x-primarynav l-menudesktopitems"
      >
        <a
          class="menudesktopitem"
          routerLinkActive="menudesktopitem-is-active"
          #rla="routerLinkActive"
          routerLink="explore"
          >Explore
          <span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          >
        </a>
        <a
          class="menudesktopitem"
          *ngIf="!user"
          type="button"
          title="Open Profile Menu"
          href="{{ apiHost + '/get-auth-code?nextUrl=/explore' }}"
        >
          Sign In or Sign Up
        </a>
        <a
          class="menudesktopitem"
          *ngIf="user"
          routerLinkActive="menudesktopitem-is-active"
          #rla="routerLinkActive"
          routerLink="dashboard"
          >Dashboard
          <span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          >
        </a>
        <a
          class="menudesktopitem"
          *ngIf="userCanAssess()"
          routerLinkActive="menudesktopitem-is-active"
          #rla="routerLinkActive"
          routerLink="assess"
          >Assess
          <span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          >
        </a>
        <a
          class="menudesktopitem"
          *ngIf="userCanReview()"
          routerLinkActive="menudesktopitem-is-active"
          #rla="routerLinkActive"
          routerLink="review"
          >Review
          <span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          >
        </a>
        <a
          class="menudesktopitem"
          *ngIf="userCanReview()"
          routerLinkActive="menudesktopitem-is-active"
          #rla="routerLinkActive"
          routerLink="manage"
          >Manage
          <span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          >
        </a>
      </div>

      <div
        *ngIf="user"
        class="l-menudesktop-x-profile l-menudropdown"
        id="menudropdown"
        [ngClass]="{ 'l-menudropdown-is-active': desktopDropdown }"
        (click)="desktopDropdown = !desktopDropdown; $event.stopPropagation()"
      >
        <button
          class="menutrigger menutrigger-profile"
          type="button"
          title="Open Profile Menu"
          data-menudropdown="menudropdown"
        >
          <span class="menutrigger-x-letter">{{
            user?.first_name.slice(0, 1)
          }}</span>
          <span class="menutrigger-x-text">{{ user?.first_name }}</span>
        </button>
        <div class="l-menudropdown-x-menu">
          <div class="dropdown">
            <a routerLink="profile" class="dropdownitem">
              <span class="dropdownitem-x-text">My Profile</span>
            </a>
            <a (click)="signOut()" class="dropdownitem" href="javascript:;">
              <span class="dropdownitem-x-text">Sign Out</span>
            </a>
          </div>
        </div>
      </div>
      <button
        *ngIf="user"
        class="l-menudesktop-x-trigger menutrigger"
        type="button"
        id="menumobile-open"
        title="Open Menu"
        (click)="openMobileMenu()"
      >
        <span class="menutrigger-x-letter">{{
          user.first_name.slice(0, 1)
        }}</span>
        <span class="menutrigger-x-text">{{ user.first_name }}</span>
      </button>
      <a
        *ngIf="!user"
        class="l-menudesktop-x-trigger menudesktopitem"
        type="button"
        id="menumobile-open"
        title="Open Menu"
        href="{{ apiHost + '/get-auth-code?nextUrl=/explore' }}"
      >
        Sign In or Sign Up
      </a>
    </div>
    <div class="l-menudesktop-x-secondary wrap l-containerhorizontal">
      <div class="linklist">
        <!-- <p><a routerLink="faqs">FAQS</a></p> -->
        <p>
          <a (click)="openTabTo('http://digitalpromise.org/terms-of-use/')" href
            >Terms of Use</a
          >
        </p>
        <p>
          <a
            (click)="
              openTabTo('https://digitalpromisehelp.zendesk.com/hc/en-us')
            "
            href
            >Help Center</a
          >
        </p>
        <!-- <p><a routerLink="contact">Contact</a></p> -->
      </div>
    </div>
  </nav>

  <main role="main" #main tabindex="0">
    <router-outlet></router-outlet>
  </main>
</div>

<div
  class="l-menumobile"
  id="menumobile"
  tabindex="-1"
  [ngClass]="{ 'l-menumobile-is-open': mobileMenu }"
  (click)="mobileMenu = !mobileMenu; focusTrap.deactivateFocusTrap()"
  aria-modal="true"
  role="dialog"
  ngxFocusTrap
  #ngxFocus="ngxFocusTrap"
  [initActivated]="false"
>
  <nav
    class="l-menumobile-x-primary"
    role="navigation"
    aria-labelledby="menumobile-heading-primary"
    [attr.aria-hidden]="modalOpen ? 'true' : 'false'"
  >
    <h3 class="visuallyhidden" id="menumobile-heading-primary">
      Primary Navigation
    </h3>
    <p>
      <a class="menumobileitem menumobileitem-profile" href="#">
        <span class="menumobileitem-x-letter">{{
          user?.first_name.slice(0, 1)
        }}</span>
        <span class="menumobileitem-x-text">{{ user?.first_name }}</span>
      </a>
    </p>
    <p>
      <a
        class="menumobileitem"
        routerLinkActive="menumobileitem-is-active"
        #rla1="routerLinkActive"
        routerLink="explore"
      >
        <span class="menumobileitem-x-text"
          >Explore<span class="visuallyhidden" *ngIf="rla1.isActive"
            >,Active menu item</span
          ></span
        >
      </a>
    </p>
    <p>
      <a
        class="menumobileitem"
        routerLinkActive="menumobileitem-is-active"
        #rla="routerLinkActive"
        routerLink="dashboard"
      >
        <span class="menumobileitem-x-text"
          >Dashboard<span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          ></span
        >
      </a>
    </p>
    <p *ngIf="userCanAssess()">
      <a
        class="menumobileitem"
        routerLinkActive="menumobileitem-is-active"
        #rla="routerLinkActive"
        routerLink="assess"
      >
        <span class="menumobileitem-x-text"
          >Assess<span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          ></span
        >
      </a>
    </p>
    <p *ngIf="userCanReview()">
      <a
        class="menumobileitem"
        routerLinkActive="menumobileitem-is-active"
        #rla="routerLinkActive"
        routerLink="review"
      >
        <span class="menumobileitem-x-text"
          >Review<span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          ></span
        >
      </a>
    </p>
    <p *ngIf="userCanReview()">
      <a
        class="menumobileitem"
        routerLinkActive="menumobileitem-is-active"
        #rla="routerLinkActive"
        routerLink="manage"
      >
        <span class="menumobileitem-x-text"
          >Manage<span class="visuallyhidden" *ngIf="rla.isActive"
            >,Active menu item</span
          ></span
        >
      </a>
    </p>
  </nav>
  <div class="l-menumobile-x-secondary wrap wrap-light2">
    <div class="linklist">
      <!--
            <p><a routerLink="faqs">FAQS</a></p>
            <p><a routerLink="contact">Contact</a></p>
            -->
      <p>
        <a
          (click)="openTabTo('https://digitalpromisehelp.zendesk.com/hc/en-us')"
          href
          >Help Center</a
        >
      </p>
      <p><a routerLink="profile">Profile</a></p>
      <p><a (click)="signOut()" href="javascript:">Sign Out</a></p>
    </div>
    <button
      class="l-menumobile-x-close menutrigger menutrigger-close"
      type="button"
      id="menumobile-close"
      title="Close Menu"
    >
      <span class="menutrigger-x-text">Close</span>
    </button>
  </div>
</div>

<app-loading-overlay></app-loading-overlay>

<app-edit-credential></app-edit-credential>
