<table class="submissiontable">
  <caption>{{title}}</caption>
  <thead class="visuallyhidden">
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Status</th>
      <th scope="col">ID</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let submission of submissions" [class.showCurrent]="submission.id === thisSubmission?.id">
      <td scope="row">
        <ng-container *ngIf="submission.id === thisSubmission?.id; else noHighlight">
          <strong>{{ submission.created_at | date: "mediumDate" }}</strong>
        </ng-container>
        <ng-template #noHighlight>
          {{ submission.created_at | date: "mediumDate" }}
        </ng-template>
      </td>
      <td>
        {{ submission.status }}
      </td>
      <td>
        {{ submission.readable_id }}
      </td>
      <td width="*">
        <ng-container class="" *ngIf="
            submission.id === thisSubmission?.id || !showLinks;
            else showLink
          ">
          <span class="fs-italic">This Application</span>
        </ng-container>
        <ng-template #showLink>
          <a class="button button-small" href target="_blank" [routerLink]="[
              '/',
              'dashboard',
              'submission',
              submission.readable_id
            ]">View Application</a>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>