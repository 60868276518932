import { NgModule } from "@angular/core";

import { ApiGetService } from "./apiget.service";
import { ConfigService } from "./config.service";
import { UserService } from "./user.service";
import { WindowRefService } from "./window-ref.service";
import { SiteService } from "./site.service";

@NgModule({
  imports: [],
  providers: [
    ApiGetService,
    ConfigService,
    UserService,
    SiteService,
    WindowRefService,
  ],
  declarations: [], // This is a CoreModule, by convention it's Service-only
})
export class CoreModule {}
