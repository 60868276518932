import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";

@Component({
  selector: "[data-app-submission-links]",
  templateUrl: "./submission-links.component.html",
})
export class SubmissionLinksComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() urls: string[] = [];
  @Input() helpText: string;
  @Output() urlsChange = new EventEmitter<string[]>();
  @Input() value: string[] = [];

  ngOnInit() {
    this.urls = this.urls.reduce((acc, next) => {
      acc.push(next);
      return acc;
    }, this.value);
  }

  addLink(link: string) {
    if (this.disabled) {
      return;
    }
    this.urlsChange.emit([...this.urls, link]);
  }

  removeLink(index: number) {
    if (this.disabled) {
      return;
    }
    this.urlsChange.emit(
      this.urls.slice(0, index).concat(this.urls.slice(index + 1))
    );
  }
}
