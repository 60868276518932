import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationExtras,
  ParamMap,
} from "@angular/router";

import { Credential } from "../shared/credential.type";
import { Organization } from "../shared/organization.type";
import { Pagination } from "../shared/pagination.type";
import { DraftLite } from "../shared/draft.type";

import { ProgressPageService } from "../progress-page/progress-page.service";

import { BrowsePageService } from "./browse-page.service";

@Component({
  selector: "app-browse-page",
  templateUrl: "./browse-page.component.html",
  styleUrls: ["./browse-page.component.css"],
})
export class BrowsePageComponent implements OnInit {
  @ViewChild("search", { static: true }) search: ElementRef;
  organizations: Organization[] | void;
  paginationDefault = { page_size: 24, page: 1 };
  currentPage = 1;
  pageSize: number;
  totalPages = 1;
  filters: any = Object.assign({}, this.paginationDefault);
  loaded = false;
  credentialCount: number;
  tags: string[] | void;
  pageTags: string[];
  credentialsTags: TagCredentialMap;
  draftsLite: DraftLite[] | void;

  constructor(
    private browseService: BrowsePageService,
    private route: ActivatedRoute,
    private progressPageService: ProgressPageService,
    private router: Router
  ) {}

  ngOnInit() {
    const organizationPromise = this.browseService.getOrganizations(),
      tagPromise = this.browseService.getTags();

    const drafts$ = this.progressPageService
      .getDraftsLite()
      .then((draftsLite: DraftLite[] | void) => {
        this.draftsLite = draftsLite;
        return draftsLite;
      })
      .catch((e) => {});

    Promise.all([organizationPromise, tagPromise, drafts$]).then(
      ([organizations, tags, drafts]: [
        Organization[] | void,
        string[] | void,
        DraftLite[] | void
      ]) => {
        this.organizations = organizations;
        this.tags = tags;
      }
    );

    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.updateFilters(paramMap);
      this.updateCredentials();
    });
  }

  getDraft(credentialId: number): DraftLite | void {
    if (this.draftsLite) {
      return this.draftsLite.find(
        (draft: DraftLite) => draft.credential == credentialId
      );
    }
  }

  clearFilters() {
    this.filters = Object.assign({}, this.paginationDefault);
    this.updateCredentials();
  }

  updateFilters(queryParams: ParamMap) {
    this.search.nativeElement.value = queryParams.get("search");
    this.filters = Object.assign({}, this.paginationDefault);
    queryParams.keys.forEach((key: string) => {
      this.filters[key] = queryParams.get(key);
    });
    this.currentPage = parseInt(queryParams.get("page") || "1", 10);
    this.pageSize = parseInt(queryParams.get("page_size") || "24", 10);
  }

  updateUrl(
    key: string,
    value: string | number,
    moreKeys: { [key: string]: string | number } = {}
  ) {
    const thisUrl = `/${this.route.snapshot.url[0].path}`,
      navigationExtras: NavigationExtras = {
        queryParams: Object.assign(
          {},
          this.filters,
          { [key]: value },
          moreKeys
        ),
        queryParamsHandling: "merge",
      };

    this.router.navigate([thisUrl], navigationExtras);
    return false;
  }

  choosePage(page: number) {
    return this.updateUrl("page", page);
  }

  setPageSize(pageSize: number) {
    this.updateUrl("page_size", pageSize, { page: 1 });
  }

  setOrganization(organization) {
    return this.updateUrl("organization__name", organization, { page: 1 });
  }

  setStack(stack) {
    return this.updateUrl("tag", stack, { page: 1 });
  }

  setSearch(term) {
    return this.updateUrl("search", term, { page: 1 });
  }

  updateCredentials() {
    this.browseService
      .getCredentials(this.filters)
      .then((response: Pagination) => {
        if (response) {
          this.credentialCount = response.count;
          this.totalPages = response.page_count;
          this.credentialsTags = this.groupByTag(response.results);
          this.loaded = true;
        }
      });
  }

  groupByTag(credentials: Credential[]): TagCredentialMap {
    this.pageTags = [];
    let previousTag;
    return credentials.reduce((map, credential) => {
      const tag = credential.tag || "Results";
      if (tag !== previousTag) {
        this.pageTags.push(tag);
        previousTag = tag;
        map[tag] = [];
      }
      map[tag].push(credential);
      return map;
    }, {});
  }
}

interface TagCredentialMap {
  [tag: string]: Credential[];
}
