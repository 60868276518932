import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";
import { Submission } from "../shared/submission.type";
import { SubmissionRecommendation } from "../shared/submission-recommendation.type";
import { SubmissionDecision } from "../shared/submission-decision.type";

@Injectable()
export class RecommendationCreateService {
  constructor(private apiGetService: ApiGetService) {}

  postRecommendation(
    data: SubmissionRecommendation
  ): Promise<SubmissionRecommendation> {
    return this.apiGetService.post<SubmissionRecommendation>(
      "assessor/recommendation/",
      data
    );
  }

  putAssessorRecommendation(
    recommendation: SubmissionRecommendation
  ): Promise<SubmissionRecommendation> {
    return this.apiGetService.post<SubmissionRecommendation>(
      `assessor/recommendation/${recommendation.id}`,
      recommendation,
      { usePut: true }
    );
  }

  putRecommendation(
    recommendation: SubmissionRecommendation
  ): Promise<SubmissionRecommendation> {
    return this.apiGetService.put<SubmissionRecommendation>(
      `issuer/recommendation/${recommendation.id}`,
      recommendation
    );
  }

  postDecision(data: SubmissionDecision): Promise<SubmissionDecision> {
    return this.apiGetService.post("issuer/decision/", data);
  }

  getSubmissions(): Promise<Submission[] | void> {
    return this.apiGetService.get<Submission[]>("assessor/submission/latest/");
  }
}
