import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";

import { Organization } from "../shared/organization.type";
import { Pagination } from "../shared/pagination.type";

@Injectable()
export class BrowsePageService {
  constructor(private apiGetService: ApiGetService) {}

  getCredentials(
    filters: any = {},
    page: number = 0,
    per_page: number = 1000
  ): Promise<Pagination | void> {
    const params = new URLSearchParams();
    params.set("ordering", "tag,name");
    Object.keys(filters).forEach((key) => {
      params.set(key, filters[key]);
    });
    return this.apiGetService
      .get<Pagination>(`public-credential/?${params.toString()}`, {
        withPagination: true,
      })
      .catch((err) => {});
  }

  getOrganizations(): Promise<Organization[] | void> {
    const params = new URLSearchParams();
    params.set("has_public_credentials", "true");
    return this.apiGetService
      .get<Organization[]>(`organization/?${params.toString()}`)
      .catch((err) => {});
  }

  getTags(): Promise<string[] | void> {
    return this.apiGetService
      .get<string[]>("credential/tags/")
      .catch((err) => {});
  }
}
