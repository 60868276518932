import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";

import { SubmissionDetailRoutingModule } from "./submission-detail-routing.module";
import { SubmissionDetailComponent } from "./submission-detail.component";
import { SubmissionDetailService } from "./submission-detail.service";

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, SubmissionDetailRoutingModule],
  providers: [SubmissionDetailService],
  declarations: [SubmissionDetailComponent],
})
export class SubmissionDetailModule {}
