<div class="l-dialogform-x-note textsmall">
  <h2 class="textruled">History</h2>
  <table class="tablehistory">
    <thead>
      <tr>
        <th scope="row" class="visuallyhidden">Date</th>
        <th scope="row" class="visuallyhidden">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let entry of entries">
        <td class="small">{{ entry.created_at | date: "mediumDate" }}</td>
        <td>{{ entry.log_message }}</td>
      </tr>
    </tbody>
  </table>
</div>
