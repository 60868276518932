import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";

import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "ngx-webstorage";
import { decamelizeKeys } from "humps";

import { ConfigService } from "./config.service";
import { WindowRefService } from "./window-ref.service";
import { Pagination } from "../pagination.type";

interface ApiGetServiceOptions {
  apiHostKey?: string;
  apiVersionKey?: string;
  headers?: any;
  responseType?: string;
  observe?: string;
  asBlob?: boolean;
  withCredentials?: boolean;
  usePut?: boolean;
  withPagination?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiGetService {
  _window: any;
  _token: any;

  get token(): any {
    this._token = this._token || this.localStorageService.retrieve("token");
    return this._token;
  }

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private windowRef: WindowRefService,
    private messageService: ToastrService,
    private localStorageService: LocalStorageService
  ) {
    this._window = windowRef.nativeWindow;
  }

  getUrl(url: string, options: ApiGetServiceOptions = {}): Promise<any> {
    const request = url;

    let requestOptions: any = {
      headers: new HttpHeaders(options.headers),
      responseType: options.responseType || "json",
      observe: options.observe || "body",
    };

    if (options.withCredentials) {
      requestOptions.withCredentials = true;
    }

    if (this.token) {
      requestOptions.headers = requestOptions.headers.set(
        "Authorization",
        `Token ${this.token}`
      );
    }

    if (options.asBlob) {
      requestOptions.responseType = "blob";
    }

    return this.http.request("GET", request, requestOptions).toPromise();
  }

  getPlain(
    apiPath: string,
    options: ApiGetServiceOptions = {}
  ): Promise<HttpResponse<any>> {
    const defaultOptions = this.configService.getConfig(
      "apiGetServiceOptions",
      {}
    );

    options = Object.assign({}, defaultOptions, options);

    const apiHost = this.configService.getConfig(
        options.apiHostKey || "apiHost"
      ),
      apiVersion = this.configService.getConfig(
        options.apiVersionKey || "apiVersion"
      ),
      request = `${apiHost}/api/${apiVersion}/${apiPath}`;

    return this.getUrl(request, options);
  }

  get<T>(apiPath: string, options: ApiGetServiceOptions = {}): Promise<T> {
    return this.getPlain(apiPath, options)
      .then((jsonResponse: any) => {
        if (jsonResponse && jsonResponse.error) {
          if ([401, 403].indexOf(jsonResponse.error.status_code) !== -1) {
            // Redirect, log error to user, etc
          }
        }
        if (jsonResponse && jsonResponse.results && !options.withPagination) {
          const paginatedResponse: Pagination = jsonResponse;
          return paginatedResponse.results as T;
        } else {
          return jsonResponse as T;
        }
      })
      .catch((error: any) => {
        if ([401, 403].indexOf(error.status) !== -1) {
          // Redirect, log error to user, etc
        }
        return Promise.reject(error);
      });
  }

  post<T>(
    apiPath: string,
    body: any,
    options: ApiGetServiceOptions = {}
  ): Promise<T> {
    const defaultOptions = this.configService.getConfig(
      "apiGetServiceOptions",
      {}
    );

    options = Object.assign({}, defaultOptions, options);

    const apiHost = this.configService.getConfig(
        options.apiHostKey || "apiHost"
      ),
      apiVersion = this.configService.getConfig(
        options.apiVersionKey || "apiVersion"
      ),
      requestUrl = `${apiHost}/api/${apiVersion}/${apiPath}`;

    let requestOptions: any = {
        headers: new HttpHeaders(options.headers),
        responseType: "json",
        observe: "response",
      },
      httpFn = this.http.post;

    if (options.withCredentials) {
      requestOptions.withCredentials = true;
    }

    if (this.token) {
      requestOptions.headers = requestOptions.headers.set(
        "Authorization",
        `Token ${this.token}`
      );
    }

    if (options.usePut) {
      httpFn = this.http.put;
    }

    return httpFn
      .call(
        this.http,
        requestUrl,
        body instanceof FormData ? body : decamelizeKeys(body || {}),
        requestOptions
      )
      .toPromise()
      .then((response) => response.body as T)
      .catch(function (error: any) {
        return Promise.reject(error);
      });
  }

  delete<T>(apiPath: string, options: ApiGetServiceOptions = {}): Promise<T> {
    const defaultOptions = this.configService.getConfig(
      "apiGetServiceOptions",
      {}
    );

    options = Object.assign({}, defaultOptions, options);

    const apiHost = this.configService.getConfig(
        options.apiHostKey || "apiHost"
      ),
      apiVersion = this.configService.getConfig(
        options.apiVersionKey || "apiVersion"
      ),
      requestUrl = `${apiHost}/api/${apiVersion}/${apiPath}`;

    let requestOptions: any = {
      headers: new HttpHeaders(options.headers),
    };
    const httpFn = this.http.delete;

    if (options.withCredentials) {
      requestOptions.withCredentials = true;
    }

    if (this.token) {
      requestOptions.headers = requestOptions.headers.set(
        "Authorization",
        `Token ${this.token}`
      );
    }

    return httpFn
      .call(this.http, requestUrl, requestOptions)
      .toPromise()
      .then((response) => response as T)
      .catch(function (error: any) {
        return Promise.reject(error);
      });
  }

  put<T>(
    apiPath: string,
    body: any,
    options: ApiGetServiceOptions = {}
  ): Promise<T> {
    const defaultOptions = this.configService.getConfig(
      "apiGetServiceOptions",
      {}
    );

    options = Object.assign({}, defaultOptions, options);

    const apiHost = this.configService.getConfig(
        options.apiHostKey || "apiHost"
      ),
      apiVersion = this.configService.getConfig(
        options.apiVersionKey || "apiVersion"
      ),
      requestUrl = `${apiHost}/api/${apiVersion}/${apiPath}`;

    let requestOptions: any = {
      headers: new HttpHeaders(options.headers),
      responseType: "json",
      observe: "response",
    };
    const httpFn = this.http.put;

    if (options.withCredentials) {
      requestOptions.withCredentials = true;
    }

    if (this.token) {
      requestOptions.headers = requestOptions.headers.set(
        "Authorization",
        `Token ${this.token}`
      );
    }

    return httpFn
      .call(this.http, requestUrl, body, requestOptions)
      .toPromise()
      .then((response) => response.body as T)
      .catch(function (error: any) {
        return Promise.reject(error);
      });
  }

  patch<T>(
    apiPath: string,
    body: any,
    options: ApiGetServiceOptions = {}
  ): Promise<T> {
    const defaultOptions = this.configService.getConfig(
      "apiGetServiceOptions",
      {}
    );

    options = Object.assign({}, defaultOptions, options);

    const apiHost = this.configService.getConfig(
        options.apiHostKey || "apiHost"
      ),
      apiVersion = this.configService.getConfig(
        options.apiVersionKey || "apiVersion"
      ),
      requestUrl = `${apiHost}/api/${apiVersion}/${apiPath}`;

    let requestOptions: any = {
      headers: new HttpHeaders(options.headers),
      responseType: "json",
      observe: "response",
    };
    const httpFn = this.http.patch;

    if (options.withCredentials) {
      requestOptions.withCredentials = true;
    }

    if (this.token) {
      requestOptions.headers = requestOptions.headers.set(
        "Authorization",
        `Token ${this.token}`
      );
    }

    return httpFn
      .call(this.http, requestUrl, body, requestOptions)
      .toPromise()
      .then((response) => response.body as T)
      .catch(function (error: any) {
        return Promise.reject(error);
      });
  }
}
