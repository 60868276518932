<div
  class="l-dialog dialog dialog-is-open"
  aria-modal="true"
  role="dialog"
  ngxFocusTrap
  #ngxFocus="ngxFocusTrap"
>
  <div class="dialog-x-content wrap">
    <div class="dialog-x-close" (click)="closePopup()">
      <button aria-labelledby="" type="button">Close Dialog</button>
    </div>
    <!--<div class="l-dialogform">-->
    <form
      #shareForm
      action=""
      class="l-dialogform"
      role="form"
      aria-labelledby=""
      (ngSubmit)="createShareToken(shareForm)"
    >
      <div class="dialogheader">
        <h3 class="dialogheader-x-heading">Share Your Award</h3>
        <p>Let us know with whom and what you would like to share.</p>
      </div>
      <div class="forminput">
        <label for="recipient_email">Recipient Email</label>
        <div class="forminput-x-input">
          <input
            type="text"
            name="recipient_email"
            id="recipient_email"
            placeholder="Enter Email"
          />
        </div>
      </div>
      <div class="forminput">
        <p class="forminput-x-label">Include with award</p>
        <div class="forminput-x-input">
          <div class="formcheckbox">
            <input
              class="visuallyhidden"
              type="checkbox"
              id="with_evidence"
              name="with_evidence"
              value="with_evidence"
            />
            <label for="with_evidence">submission evidence</label>
          </div>
        </div>
        <div class="forminput-x-input">
          <div class="formcheckbox">
            <input
              class="visuallyhidden"
              type="checkbox"
              id="with_feedback"
              name="with_feedback"
              value="with_feedback"
            />
            <label for="with_feedback"
              >assessor feedback and related submissions</label
            >
          </div>
        </div>
      </div>
      <p class="textsmall">
        <span class="small">Note:</span> Recipients will only be able to view
        the submission evidence and assessor feedback for 24 hours.
      </p>
      <div class="dialog-x-button">
        <button
          class="button"
          type="submit"
          (click)="createShareToken(shareForm)"
        >
          <span class="button-x-text">Share</span>
        </button>
      </div>
    </form>
  </div>
</div>
