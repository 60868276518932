import { Component, OnInit } from "@angular/core";
import { LoadingOverlayService } from "../loading-overlay.service";

@Component({
  selector: "app-loading-indicator-demo",
  templateUrl: "./loading-indicator-demo.component.html",
  styleUrls: ["./loading-indicator-demo.component.css"],
})
export class LoadingIndicatorDemoComponent implements OnInit {
  constructor(private loadingService: LoadingOverlayService) {}

  ngOnInit() {
    this.loadingService.startLoading();
  }
}
