import { Injectable } from "@angular/core";

import { ApiGetService } from "../core/apiget.service";

import { Submission } from "../submission.type";
import { AssessmentExtension } from "../assessment.type";
import { SubmissionEvidence } from "../submission-evidence.type";
import { SubmissionRecommendationRubric } from "../submission-recommendation-rubric.type";
import { HistoryData } from "../history.type";
import { Comment } from "../comment.type";

@Injectable()
export class SubmissionDetailMixinService {
  constructor(private apiGetService: ApiGetService) {}

  getSubmissionRequestString(
    readable_id: string,
    shareToken: string,
    apiPath: string
  ): string {
    let requestString = `submission/${readable_id}/${apiPath}/`;
    if (shareToken) {
      requestString += `?sharetoken=${shareToken}`;
    }
    return requestString;
  }

  getAssessment(id: number): Promise<AssessmentExtension | void> {
    return this.apiGetService.get<AssessmentExtension>(
      `credential/extension/assessment/${id}/`
    );
  }

  getRubricRecommendations(
    readable_id: string,
    shareToken: string
  ): Promise<SubmissionRecommendationRubric[] | void> {
    return this.apiGetService.get<SubmissionRecommendationRubric[]>(
      this.getSubmissionRequestString(readable_id, shareToken, "feedback")
    );
  }

  getEvidence(
    readable_id: string,
    shareToken: string
  ): Promise<SubmissionEvidence | void> {
    return this.apiGetService.get<SubmissionEvidence>(
      this.getSubmissionRequestString(readable_id, shareToken, "evidence")
    );
  }

  getSubmission(readable_id: string): Promise<Submission | void> {
    return this.apiGetService.get<Submission>(`submission/${readable_id}/`);
  }

  getSubmissionHistory(submissionId: number): Promise<HistoryData[]> {
    return this.apiGetService.get<HistoryData[]>(
      `history/?related_object_type__model=submission&related_object_pk=${submissionId}`
    );
  }

  getSubmissionComments(
    readable_id: string,
    shareToken: string
  ): Promise<Comment[]> {
    return this.apiGetService.get<Comment[]>(
      this.getSubmissionRequestString(readable_id, shareToken, "comments")
    );
  }

  fetchSubmissions(
    status: string[],
    filters: any = {},
    onlyLatest = true,
    endpointPermission = "educator"
  ): Promise<any | void> {
    const tupleList = [];
    for (const key in filters) {
      tupleList.push([key, encodeURIComponent(filters[key])]);
    }
    const filterString = tupleList.map((tuple) => tuple.join("=")).join("&");

    let path = `${endpointPermission}/submission/`;

    if (onlyLatest) {
      path += "latest/";
    }

    if (status !== null) {
      path = `${path}?status__in=${status.join(",")}`;

      if (filterString) {
        path = `${path}&${filterString}`;
      }
    }

    if (status === null && filterString) {
      path = `${path}?${filterString}`;
    }

    return this.apiGetService
      .get<any[]>(path, { withPagination: true })
      .catch((err) => {});
  }
}
