import {
  Component,
  Input,
  Optional,
  Output,
  EventEmitter,
  OnInit,
  SimpleChange,
} from "@angular/core";
import {
  ControlContainer,
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";

import { AssessmentQuestion } from "../../assessment.type";

import { SubmissionFile } from "../../submission-file.type";
import { DraftFile } from "../../draft.type";
import { SubmissionRecommendationRubric } from "../../submission-recommendation-rubric.type";

@Component({
  selector: "app-answer-input-form",
  templateUrl: "./answer-input-form.component.html",
  styleUrls: ["./answer-input-form.component.css"],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class AnswerInputFormComponent implements OnInit {
  @Output() uploaded: EventEmitter<SubmissionFile> =
    new EventEmitter<SubmissionFile>();
  @Output() removed: EventEmitter<SubmissionFile> =
    new EventEmitter<SubmissionFile>();
  @Output() urlsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() question: AssessmentQuestion;
  @Input() sectionNumber: number;
  @Input() previousRubricRecommendations: SubmissionRecommendationRubric[] = [];
  @Input() value: string = "";
  @Input() valueUrls: string[] = [];
  @Input() draftFiles: DraftFile[] = [];
  @Input() disabled: boolean = false;
  private textInput: UntypedFormControl = new UntypedFormControl();
  private urlInput: UntypedFormControl = new UntypedFormControl();
  private urls: string[] = [];

  asSubmissionFiles(draftFiles: DraftFile[]) {
    return draftFiles.map((draftFile) => draftFile.submission_file);
  }

  constructor(@Optional() private container: ControlContainer) {}

  ngOnInit() {
    if (this.container) {
      this.textInput.valueChanges.subscribe((text: string) => {
        const formGroup = <UntypedFormGroup>this.container.control;

        const isNotSubmissionForm = formGroup.contains("text");

        if (isNotSubmissionForm) {
          formGroup.get("text").setValue(text);
        }
      });

      this.textInput.setValue(this.value);

      this.urlsChange.subscribe((urls: string[]) => {
        let ourNewUrls: UntypedFormGroup[],
          formUrls: UntypedFormGroup[],
          otherQuestionUrls: UntypedFormGroup[],
          newQuestionUrls: UntypedFormGroup[];

        ourNewUrls = urls.map((url: string) => {
          return new UntypedFormGroup({
            assessment_question: new UntypedFormControl(this.question.id),
            url: new UntypedFormControl(url),
          });
        });

        // Preserve other question URL references
        formUrls = <UntypedFormGroup[]>(
          (<UntypedFormArray>this.container.control.get("urls")).controls
        );
        otherQuestionUrls = formUrls.filter((urlGroup: UntypedFormGroup) => {
          return (
            urlGroup.controls.assessment_question.value !== this.question.id
          );
        });

        newQuestionUrls = otherQuestionUrls.concat(ourNewUrls);
        (<UntypedFormGroup>this.container.control).setControl(
          "urls",
          new UntypedFormArray(newQuestionUrls)
        );
      });
    }
  }

  enableFormInputs() {
    this.textInput.enable();
  }

  disableFormInputs() {
    this.textInput.disable();
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['disabled'] !== undefined) {
      if (changes['disabled'].currentValue === true) {
        this.disableFormInputs();
      } else {
        this.enableFormInputs();
      }
    }
  }
}
