import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";
import { District } from "../shared/district.type";
import { School } from "../shared/school.type";

@Injectable()
export class ProfileService {
  schoolsPromise: Promise<School[] | void>;
  districtsPromise: Promise<District[] | void>;
  statePromise: Promise<any[] | void>;

  constructor(private apiGetService: ApiGetService) {}

  getStates(value: string): Promise<any[] | void> {
    this.statePromise = this.apiGetService.get<any[]>(`state/?search=${value}`);
    return this.statePromise;
  }

  getSchools(value: string, districtId: number): Promise<School[] | void> {
    let args = `?search=${value}`;
    if (districtId !== null) {
      args += `&district=${districtId}`;
    }

    this.schoolsPromise = this.apiGetService.get<School[]>(`school/${args}`);
    return this.schoolsPromise;
  }

  getDistricts(value: string, stateId: number): Promise<District[] | void> {
    let args = `?search=${value}`;
    if (stateId !== null) {
      args += `&state=${stateId}`;
    }
    this.districtsPromise = this.apiGetService.get<District[]>(
      `district/${args}`
    );
    return this.districtsPromise;
  }

  getEducatorChoices(): any {
    return this.apiGetService.get("educator-choices/");
  }
}
