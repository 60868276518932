/* eslint-disable curly */
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { ToastrService } from "ngx-toastr";

import { Credential } from "../shared/credential.type";
import {
  AssessmentExtension,
  AssessmentSection,
} from "../shared/assessment.type";
import { Submission } from "../shared/submission.type";
import { User } from "../shared/user.type";
import { HistoryData } from "../shared/history.type";
import { UserService } from "../shared/core/user.service";
import { EditCredentialService } from "../shared/edit-credential/edit-credential.service";
import { SubmissionDetailMixinService } from "../shared/submission-detail-mixin/submission-detail-mixin.service";
import { PreviousRecommendationRubricParentMixin } from "../shared/previous-recommendation-rubric-mixin/previous-recommendation-rubric-parent.mixin";

import { CredentialDetailService } from "./credential-detail.service";
import { ModalService } from "../shared/core/modal.service";

import { Subscription } from "rxjs";

@Component({
  selector: "app-credential-detail",
  templateUrl: "./credential-detail.component.html",
  styleUrls: ["./credential-detail.component.css"],
})
export class CredentialDetailComponent
  extends PreviousRecommendationRubricParentMixin
  implements OnInit, OnDestroy, AfterViewInit
{
  user: User;
  assessmentExtension: AssessmentExtension;
  allSections: AssessmentSection[] = [];
  credential: Credential;
  credentialHistory: HistoryData[] = [];
  submission: Submission;
  isActive = "overview";
  credentialSub: Subscription;
  editCredentialErrorSub: Subscription;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private credentialDetailService: CredentialDetailService,
    private editCredentialService: EditCredentialService,
    private modalService: ModalService,
    private messageService: ToastrService,
    private router: Router,
    protected submissionMixinService: SubmissionDetailMixinService,
    private userService: UserService
  ) {
    super(submissionMixinService);
  }

  showErrorMessage(msg) {
    this.messageService.error(msg, "");
  }

  canEdit() {
    if (!this.user) return false;

    if (this.user.is_admin) return true;

    let isIssuer = false;
    this.user.issuer_organizations.forEach((org) => {
      if (org.id === this.credential.organization.id) isIssuer = true;
    });

    return isIssuer;
  }

  logApplyClick(credentialId: number) {
    this.credentialDetailService.logApplyClick(credentialId);
  }

  ngOnInit() {
    this.user = this.userService.user;

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.credential = this.route.snapshot.data["credential"];

      if (!this.credential) return;

      this.editCredentialService.selectedCredential.next(this.credential);

      if (this.user) {
        this.getPreviousRecommendationRubric(this.credential, this.user);
      }

      this.credentialDetailService
        .getCredentialHistory(this.credential.id)
        .then((credentialHistory: HistoryData[]) => {
          this.credentialHistory = credentialHistory;
        });

      this.submission =
        (this.credential && this.credential.latest_request_user_submission) ||
        null;

      this.titleService.setTitle(
        `${this.credential.name} - ${this.credential.organization.name} Application | Digital Promise`
      );

      if (this.credential.extension) {
        this.credentialDetailService
          .getAssessment((<AssessmentExtension>this.credential.extension).id)
          .then((assessmentExtension: AssessmentExtension) => {
            this.assessmentExtension = assessmentExtension;

            for (const assessment of this.assessmentExtension.assessments) {
              for (const section of assessment.sections) {
                this.allSections.push(section);
              }
            }
          });
      }
    });

    this.credentialSub =
      this.editCredentialService.credentialUpdated$.subscribe(
        (credential: Credential) => {
          if (
            credential.availability === "inactive" ||
            credential.availability === "locked"
          ) {
            this.router.navigate(["credentials"]);
            return;
          }
          location.reload();
        }
      );

    this.editCredentialErrorSub = this.editCredentialService.errorMessage
      .asObservable()
      .subscribe((message) => {
        this.showErrorMessage(message);
      });
  }

  ngAfterViewInit() {
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      this.navigateTo(fragment);
    }
  }

  ngOnDestroy() {
    this.credentialSub.unsubscribe();
    this.editCredentialErrorSub.unsubscribe();
  }

  navigateTo(fragmentName) {
    const element = this.elementRef.nativeElement.querySelector(fragmentName);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return false;
  }

  openModal() {
    this.modalService.anyModalIsOpen.next(true);
    this.editCredentialService.editCredentialModalisOpen.next(true);
  }
}
