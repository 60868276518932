import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Notifier } from "@airbrake/browser";
import { ConfigService } from "./shared/core/config.service";

@Injectable()
export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;

  constructor(private injector: Injector) {
    const configService = this.injector.get(ConfigService);
    const projectId = configService.getConfig("airbrakeProjectId", "test");
    if (projectId !== "test") {
      const projectKey = configService.getConfig("airbrakeProjectKey");
      const environment = configService.getConfig(
        "airbrakeEnvironment",
        "development"
      );
      this.airbrake = new Notifier({
        projectId,
        projectKey,
        environment,
      });
    }
  }

  handleError(error: any): void {
    if (this.airbrake) this.airbrake.notify(error);
  }
}
