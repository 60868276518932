import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "dig-pdf-viewer",
  templateUrl: "./dig-pdf-viewer.component.html",
  styleUrls: ["./dig-pdf-viewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigPdfViewerComponent {
  @Input() pdfURL: string;
  @Input() width = 700;
  @Input() height = 500;

  constructor(public sanitizer: DomSanitizer) {}
}
