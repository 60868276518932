/* eslint-disable @typescript-eslint/member-ordering */
import { RoleType } from "../manage-page/roles-table/role.type";

export abstract class Confirmation {
  static SUBMISSION = `
    We have received your micro-credential submission. You will be notified by
    email whether your submission has been granted or denied within 14 business
    days.`;
  static ASSESSMENT = `Assessment received.`;
  static ADDIONAL_ASSESSMENT_REQUEST = `Additional Assessment Request received.`;
  static CREDENTIAL = (action: "shared" | "awarded" | "denied" | "updated") =>
    `Micro-credential ${action}.`;
  static WELCOME = `
    Welcome! To get started, let us know a little bit more about you.`;
  static PROFILE = `Profile updated.`;
  static RE_SHARE = `
    To view submission details, please contact award recipient to have this
    information re-shared with you.`;
  static ASSIGNED = (assessor: string) => `${assessor} assigned.`;
  static ROLE = (email: string, action: "added" | "removed", role: RoleType) =>
    `${email} ${action} as ${role}.`;
  static PURCHASED = (credentialName: string) =>
    `${credentialName} application successfully purchased.`;
  static COUPON = (action: "created" | "updated") => `Coupon ${action}.`;
  static DOWNLOAD = (contentName: string) => `${contentName} downloaded.`;
  static CONTACT = `Message received. Thanks for contacting us. We'll be in touch within 14
    business days.`;
}

export abstract class Error {
  static DUPLICATE_ROLE = (email) =>
    `${email} already has a role for this issuing organization. Use Edit to
    make changes to their role.`;
  static INVALID_ROLE_EMAIL = (email) =>
    `There are no users with this email address: ${email}. Users must have an
     account before roles can be added to them.`;
  static DOWNLOAD = (contentName) => `Unable to download ${contentName}. `;
  static UPLOAD_SIZE = `This file is too large to upload. The size limit is 20 MB.`;
  static UPLOAD = `Unable to upload file. Please upload again.`;
  static SUBMISSION = (errorId) =>
    `Unable to accept application submission at this time. Please try again.
     Error ID: ${errorId}`;
  static DECISION = `There was a failure in posting a decision on this Micro-credential. Please contact an administrator.`;
  static PREVIEW = `Error with file download. Unable to preview or download file.`;
  static REQUIRED = `This field is required.`;
  static MIN_ZERO = `This number must be zero or greater.`;
  static EMAIL = `Enter a valid email address. `;
  static URL = `Enter a valid URL.`;
  static COUPON_UNIQUE = `Coupon code must be unique.`;
  static COUPON_EXPIRED = `Coupon code is invalid or expired.`;
  static COUPON_LENGTH = `Coupon codes must be between 4 and 20 characters.`;
  static COUPON_FORMAT = `Coupon codes can only include letters and numbers. Spaces and punctuation
     are not supported.`;
  static NUMBER = `Value must be a number.`;
  static DATE = `Use the following date format: MM/DD/YYYY.`;
  static INVALID_CREDENTIAL = `This micro-credential ID does not exist.`;
  static ASSESSMENT = `There is no assessment associated with this Micro-credential. Until there
     is an assessment, this micro-credential cannot be made active.`;
  static ASSESS = (
    err: string | null
  ) => `Unable to accept assessment at this time. Please try again.
  Error ID: ${err}`;
  static MIN_ONE_CREDENTIAL = "At least one micro-credential is required.";
  static ALPHANUMERIC_PATTERN = (number) =>
    `Enter ${number} alphanumeric characters.`;
  static RECAPTCHA = "There was an error with reCAPTCHA. Please try again.";
  // Comments
  static COMMENT_ADD_UPDATE = (err: string | null) =>
    `Unable to update or create comment. Please try again. Error: ${err}`;
  static COMMENT_REMOVE = (err: string | null) =>
    `Unable to remove comment. Please try again. Error: ${err}`;
}

export abstract class Empty {
  static AWARD = `No awarded micro-credentials to display.`;
  static DRAFTS = `No micro-credential drafts to display.`;
  static AWARD_DESC = `Find micro-credentials that match your skill set.`;
  static ASSESS = `No micro-credentials to assess at this time.`;
  static ASSESS_DESC = `You'll receive an email when there is a new submission for assessment.`;
  static SUBMISSION = `No micro-credential submissions to display.`;
  static SUBMISSION_DESC = `Micro-credential applications will appear here once there is activity.`;
  static ASSIGN = `No micro-credentials to assign at this time.`;
  static ASSIGN_DESC = `You'll receive an email when there is a new micro-credential submission to
     assign.`;
  static REVIEW = `No micro-credentials to review at this time.`;
  static REVIEW_DESC = `Assessed micro-credentials will appear here when they're ready to be
     reviewed.`;
}

export abstract class History {
  // TODO
  // static CREDENTIAL_ADD = (date, user, role: RoleType) =>
  //   `${date} ${user} (${role}) added micro-credential.`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) set price to $[price].`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) set availability to "[availability status name]."`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) created coupon.`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) set coupon code to "[coupon code]."`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) set maximum number of redemptions to [number].`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) set expiration date to [expiration date]. `;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) [addeded/removed]: [micro-credential ID(s)].`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) set applicable for all micro-credentials.`;
  // static _ = `[Date] Application submitted.`;
  // static _ = `[Date] Application purchased.`;
  // static _ = `[Date] Application in progress.`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) assigned by [User] ([User role]).`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) recommended award.`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) recommended deny.`;
  // static _ = `[Date] Submission denied.`;
  // static _ = `[Date] Micro-credential awarded.`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) awarded micro-credential.`;
  // static _ = (date, user, role: RoleType) => `${date} ${user} (${role}) denied micro-credential.`;
}
