import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { SubmissionCreateService } from "../submission-create.service";
import { Error } from "../../shared/messages.constants";
import { ModalService } from "../../shared/core/modal.service";

@Component({
  selector: "app-purchase-with-coupon",
  templateUrl: "./purchase-with-coupon.component.html",
  styleUrls: ["./purchase-with-coupon.component.css"],
})
export class PurchaseWithCouponComponent implements OnInit {
  couponForm: UntypedFormGroup;
  invalidCode = false;
  messages = {
    expired: Error.COUPON_EXPIRED,
    required: Error.REQUIRED,
  };

  @Output() closeModalAndSubmit = new EventEmitter<string>();

  @ViewChild("ngxFocus", { static: true }) focusTrap;
  modalIsOpen = false;

  // Back button pressed.
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.closeDialog();
  }

  @Input()
  set isOpen(value: boolean) {
    this.modalService.anyModalIsOpen.next(value);
    this.modalIsOpen = value;

    if (value) {
      this.focusTrap.activateFocusTrap();
    }
  }

  // @HostBinding('class.l-dialog') lDialog = true;
  // @HostBinding('class.dialog') dialog = true;
  // @HostBinding('class.dialog-is-open') get dialogIsOpen() {
  //   return this.isOpen;
  // }

  get code() {
    return this.couponForm.get("code");
  }

  constructor(
    private submissionCreateService: SubmissionCreateService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService
  ) {}

  closeDialog(code?: string) {
    this.closeModalAndSubmit.emit(code);
    this.focusTrap.deactivateFocusTrap();
    this.modalService.anyModalIsOpen.next(false);
    this.modalIsOpen = false;
  }

  onSubmit() {
    if (this.couponForm.valid) {
      this.couponForm.patchValue({
        code: this.couponForm.get("code").value.toUpperCase(),
      });
      // if success invalidCode = false;
      this.closeDialog(this.couponForm.get("code").value);
      this.resetForm();
      // if error invalidCode = true;
    }
  }

  resetForm() {
    this.couponForm.reset({
      code: "",
    });
  }

  ngOnInit() {
    this.couponForm = this.fb.group({
      code: ["", Validators.required],
    });
  }
}
