import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostBinding,
  OnChanges,
} from "@angular/core";

import { UploadFile, UPLOAD_FILE_STATE } from "../shared";

@Component({
  selector: "[data-dig-file-item]",
  templateUrl: "./file-item.component.html",
  styleUrls: ["./file-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigFileItemComponent implements OnChanges {
  @Input() file: UploadFile;
  @Output() remove = new EventEmitter();
  @Output() view = new EventEmitter();
  @HostBinding("class.tableattachment-x-progress") inProgress = true;

  STATE = UPLOAD_FILE_STATE;

  ngOnChanges() {
    this.inProgress = this.file.state == this.STATE.PROGRESS;
  }

  removeFile(file) {
    this.remove.emit(file);
  }

  viewFile(file) {
    this.view.emit(file);
  }
}
