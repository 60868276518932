<div class="marketingbanner wrap">
  <div class="marketingbanner-x-image">
    <picture>
      <source
        media="(min-width: 1024px)"
        srcset="assets/images/img-home-banner-large.png"
      />
      <source
        media="(min-width: 768px)"
        srcset="assets/images/img-home-banner-small.png"
      />
      <!-- NOTE: Alt text should describe the image contents -->
      <img srcset="assets/images/img-home-banner-small.png" />
    </picture>
  </div>
  <div class="marketingbanner-x-content">
    <h1><span>Micro-credentials.</span><span>Macro-Rewards.</span></h1>
    <p>Competency-based recognition for professional learning</p>
    <div class="marketingbanner-x-button">
      <button class="button" type="button">
        <span routerLink="explore" class="button-x-text">Get Started</span>
      </button>
    </div>
  </div>
</div>
<div class="marketingborder"></div>
<div class="marketingsection l-containerhorizontal wrap wrap-light2">
  <div class="marketingsection-x-heading">
    <h2>What are Micro-credentials?</h2>
    <p>
      Micro-credentials provide educators with recognition for the skills they
      develop throughout their careers, regardless of where or how they learned
      them. Micro-credentials are:
    </p>
  </div>
  <div class="marketingcardabout">
    <div class="marketingcardabout-x-text">
      <h3>Competency-based</h3>
      <p>
        Micro-credentials articulate a discrete skill to support educator
        practice and the specific evidence educators must submit to demonstrate
        their competence in that skill.
      </p>
    </div>
    <div class="marketingcardabout-x-image">
      <img src="assets/images/img-mc-competency.png" />
    </div>
  </div>
  <div class="marketingcardabout">
    <div class="marketingcardabout-x-text">
      <h3>Research-backed</h3>
      <p>
        Each micro-credential is grounded in sound research that illustrates how
        that competency supports student learning.
      </p>
    </div>
    <div class="marketingcardabout-x-image">
      <img src="assets/images/img-mc-research.png" />
    </div>
  </div>
  <div class="marketingcardabout">
    <div class="marketingcardabout-x-text">
      <h3>Personalized</h3>
      <p>
        Educators select micro-credentials from the catalogue aligned to
        personal goals, student needs, or school-wide instructional priorities.
      </p>
    </div>
    <div class="marketingcardabout-x-image">
      <img src="assets/images/img-mc-personalized.png" />
    </div>
  </div>
  <div class="marketingcardabout">
    <div class="marketingcardabout-x-text">
      <h3>On-demand</h3>
      <p>
        Educators can start and continue their micro-credential journeys on
        their own time and in their own ways.
      </p>
    </div>
    <div class="marketingcardabout-x-image">
      <img src="assets/images/img-mc-on-demand.png" />
    </div>
  </div>
</div>
<div class="marketingborder"></div>
<div class="marketingsection l-containerhorizontal wrap">
  <div class="marketingsection-x-heading">
    <h2>Steps to earn a micro-credential</h2>
  </div>
  <div class="l-marketinggridfour">
    <div>
      <div class="marketingcardstep">
        <h3>Select</h3>
        <div class="marketingcardstep-x-image">
          <img src="assets/images/img-mc-select.png" />
        </div>
        <div class="marketingcardstep-x-text">
          <p>Select a skill you have developed or would like to develop.</p>
        </div>
      </div>
    </div>
    <div>
      <div class="marketingcardstep">
        <h3>Collect</h3>
        <div class="marketingcardstep-x-image">
          <img src="assets/images/img-mc-collect.png" />
        </div>
        <div class="marketingcardstep-x-text">
          <p>
            Collect the required evidence demonstrating your competence in the
            selected area.
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="marketingcardstep">
        <h3>Submit</h3>
        <div class="marketingcardstep-x-image">
          <img src="assets/images/img-mc-submit.png" />
        </div>
        <div class="marketingcardstep-x-text">
          <p>Upload and submit your collected evidence.</p>
        </div>
      </div>
    </div>
    <div>
      <div class="marketingcardstep">
        <h3>Share</h3>
        <div class="marketingcardstep-x-image">
          <img src="assets/images/img-mc-share.png" />
        </div>
        <div class="marketingcardstep-x-text">
          <p>
            Share your micro-credential as a digital badge once you have earned
            it.
          </p>
        </div>
      </div>
    </div>
  </div>
  <button class="button" type="button">
    <span routerLink="explore" class="button-x-text">Get Started</span>
  </button>
</div>
<div class="footeraward">
  <div class="l-containerhorizontalmaxwidth">
    <h2>Become a micro-credential issuer</h2>
    <p>
      The Digital Promise educator micro-credential ecosystem is committed to
      competency-based professional learning and the potential of
      micro-credentials to transform educator learning.
    </p>
    <button class="button button-secondary" type="button">
      <span routerLink="contact" class="button-x-text">Join Our Ecosystem</span>
    </button>
  </div>
</div>
<div class="marketingfooter wrap wrap-light2">
  <div class="marketingfooter-x-image">
    <img
      width="245px"
      height="60px"
      src="assets/images/logo.f4208ca9494cd32fcb32.svg"
      alt="Digital Promise"
    />
  </div>
  <div class="marketingfooter-x-text">
    <p>
      Digital Promise’s mission is to spur innovation in education and improve
      the opportunity to learn for all through technology and research.
    </p>
  </div>
</div>
<div class="footer">
  <div class="footer-x-socials">
    <a href="https://www.facebook.com/digitalpromise"
      ><img style="margin-right: 10px" src="assets/images/icon-facebook.svg"
    /></a>
    <a href="https://twitter.com/DigitalPromise"
      ><img style="margin-right: 10px" src="assets/images/icon-twitter.svg"
    /></a>
    <a href="https://www.instagram.com/digitalpromise/"
      ><img style="margin-right: 10px" src="assets/images/icon-instagram.svg"
    /></a>
  </div>
  <div class="footerlinklist">
    <p><a (click)="openTabTo('https://digitalpromisehelp.zendesk.com/hc/en-us')" href
        >Contact Us</a></p>
    <p><a routerLink="faqs">Terms of Use</a></p>
  </div>
</div>
