import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";

import { LocalFile } from "../shared";

@Injectable()
export class FileService {
  getLocalFile(file: File): Observable<LocalFile> {
    const type = file.type;
    const name = file.name;

    return Observable.create((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.readAsDataURL(file as Blob);

      reader.onloadend = () => {
        observer.next(<string>reader.result);
        observer.complete();
      };

      reader.onerror = () => observer.error("Failed to parse the file.");
    }).pipe(map((body) => ({ name, body, type })));
  }

  getBase64File(file: File): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.readAsDataURL(file as Blob);

      reader.onloadend = () => {
        observer.next(<string>reader.result);
        observer.complete();
      };

      reader.onerror = () => observer.error("Failed to parse the file.");
    });
  }
}
