import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";

import { Credential } from "../shared/credential.type";
import { Submission } from "../shared/submission.type";
import { AssessmentExtension } from "../shared/assessment.type";
import { Draft } from "../shared/draft.type";

@Injectable()
export class SubmissionCreateService {
  constructor(private apiGetService: ApiGetService) {}

  getCredential(slug: string): Promise<Credential | void> {
    return this.apiGetService.get<Credential>(`credential/${slug}/`);
  }

  getAssessment(id: number): Promise<AssessmentExtension | void> {
    return this.apiGetService.get<AssessmentExtension>(
      `credential/extension/assessment/${id}`
    );
  }

  postSubmission(data: any = {}): Promise<Submission> {
    return this.apiGetService.post<Submission>("submission/", data);
  }

  postDraft(data: any = {}): Promise<Draft> {
    return this.apiGetService.post<Draft>("draft/", data);
  }

  getDraft(credential: number): Promise<Draft | void> {
    return this.apiGetService.get<Draft>(`credential/${credential}/draft`);
  }
}
