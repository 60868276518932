import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { ProfileRoutingModule } from "./profile-routing.module";
import { ProfileComponent } from "./profile.component";
import { ProfileService } from "./profile.service";
import { PreviousRouteGuard } from "./previous-route.guard";

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    HttpClientModule,
  ],
  providers: [ProfileService, PreviousRouteGuard],
  declarations: [ProfileComponent],
})
export class ProfileModule {}
