import { Component, OnInit } from "@angular/core";

import { Empty } from "../../shared/messages.constants";
import { Draft } from "../../shared/draft.type";

import { ProgressPageService } from "../progress-page.service";

@Component({
  selector: "app-draft-list",
  templateUrl: "./draft-list.component.html",
  styleUrls: ["./draft-list.component.css"],
})
export class DraftListComponent implements OnInit {
  drafts: Draft[];
  emptyHeading = Empty.DRAFTS;
  emptyDescription = Empty.AWARD_DESC;

  constructor(private progressPageService: ProgressPageService) {}

  ngOnInit() {
    this.progressPageService.getDraftsFull().then((drafts: Draft[]) => {
      this.drafts = drafts;
    });
  }
}
