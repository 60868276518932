import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "removeText",
})
export class RemoveTextPipe implements PipeTransform {
  transform(value: string, removeText: string): any {
    return value.replace(removeText, "");
  }
}
