import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { SubmissionCreateRoutingModule } from "./submission-create-routing.module";
import { SubmissionCreateComponent } from "./submission-create.component";
import { PurchaseWithCouponComponent } from "./purchase-with-coupon/purchase-with-coupon.component";
import { SubmissionCreateService } from "./submission-create.service";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, SubmissionCreateRoutingModule],
  providers: [SubmissionCreateService],
  declarations: [SubmissionCreateComponent, PurchaseWithCouponComponent],
})
export class SubmissionCreateModule {}
