<ng-container *ngIf="payload$ | async as payload; else loading">
  <ng-container *ngIf="payload.valid; else invalid">
    <div class="document-x-name">
      <div class="document-x-icon documenticon-x-left">
        <button
          type="button"
          class="icon icon-back"
          (click)="closeCarouselDialog()"
        >
          <span class="visuallyhidden">Button Back</span>
        </button>
      </div>
      <p>{{ payload.file?.name | truncate: 70 }}</p>
      <div class="document-x-icon documenticon-x-right">
        <a
          type="button"
          class="icon icon-download"
          [attr.href]="payload.file?.url | safeUrl"
          download
          target="_blank"
        >
          <span class="visuallyhidden">Download [File name]</span>
        </a>
      </div>
    </div>
    <div class="document-x-content l-iframe" #viewerComponents>
      <ng-container [ngSwitch]="payload.file?.type">
        <dig-image-viewer
          *ngSwitchCase="TYPE.IMAGE_JPEG"
          [imageURL]="payload.file.url"
        ></dig-image-viewer>
        <dig-image-viewer
          *ngSwitchCase="TYPE.IMAGE_PNG"
          [imageURL]="payload.file.url"
        ></dig-image-viewer>
        <dig-pdf-viewer
          *ngSwitchCase="TYPE.PDF"
          [pdfURL]="payload.file.url"
          [width]="width"
          [height]="height"
        ></dig-pdf-viewer>
        <dig-video-viewer
          *ngSwitchCase="TYPE.VIDEO"
          [videoURL]="payload.file.url"
        ></dig-video-viewer>
        <dig-other-viewer
          *ngSwitchDefault
          [file]="payload.file"
        ></dig-other-viewer>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #invalid>
    <div class="error-box">
      <h3>{{ payload.message }}</h3>
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <h3 class="dig-loader">Loading...</h3>
</ng-template>
