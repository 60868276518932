import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

import { SiteService } from "../shared/core/site.service";

@Component({
  selector: "app-no-whitelabel",
  templateUrl: "./no-whitelabel.component.html",
  styleUrls: ["./no-whitelabel.component.css"],
})
export class NoWhitelabelComponent implements OnInit {
  domain: string = "";
  masterSite: string | void;

  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService
  ) {}

  ngOnInit() {
    this.siteService.error$.next(true);

    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.domain = paramMap.get("domain") || window.location.host;
    });
  }
}
