<div class="forminput-small forminput l-attachment">
  <div class="tableattachment tableattachment-dark wrap wrap-light2">
    <table>
      <thead>
        <tr>
          <th scope="row" class="visuallyhidden">Name</th>
          <th scope="row" class="visuallyhidden">Size</th>
          <th scope="row" class="visuallyhidden">Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr
          data-dig-file-item
          *ngFor="let file of uploadFile; let i = index"
          [file]="file"
          (view)="viewFile($event, i, digCarousel)"
        ></tr>
      </tbody>
    </table>
  </div>
</div>

<dig-carousel
  [urls]="carouselUrls"
  [selectedUrlIndex]="selectedFileIndex"
  #digCarousel
></dig-carousel>
<div class="error-box">
  <h3 *ngFor="let error of errorMessages">{{ error }}</h3>
</div>
