import { Injectable } from "@angular/core";

class LoaderToken {}

@Injectable()
export class LoadingOverlayService {
  private loaders = [];

  get isLoading() {
    return !!this.loaders.length;
  }

  startLoading() {
    const token = new LoaderToken();
    this.loaders.push(token);
    return token;
  }

  doneLoading(token) {
    this.loaders = this.loaders.filter((t) => t !== token);
  }
}
