import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";
import { Submission } from "../shared/submission.type";
import { Subject } from "rxjs";

export interface SubmissionUpdate {
  submission: Submission;
  reason: string;
}

@Injectable()
export class ReviewPageService {
  submissionPromise: Promise<Submission[] | void>;
  private submissionUpdatedSubject = new Subject<SubmissionUpdate>();
  submissionUpdated$ = this.submissionUpdatedSubject.asObservable();

  public orderingFilter: any;

  constructor(private apiGetService: ApiGetService) {}

  getSubmissions(
    status: string | string[],
    filters: any = {},
    onlyLatest = true
  ): Promise<any | void> {
    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      params.set(key, filters[key]);
    });
    if (status !== null) {
      if (status instanceof Array) {
        for (const statusName of status) {
          params.append("status", statusName);
        }
      } else {
        params.set("status", status);
      }
    }

    let path = "issuer/submission/";

    if (onlyLatest) {
      path += "latest/";
    }

    this.submissionPromise = this.apiGetService.get<any[]>(
      `${path}?${params.toString()}`,
      { withPagination: true }
    );

    return this.submissionPromise;
  }

  getSubmissionCounts(): Promise<any | void> {
    return this.apiGetService.get<any>(
      "issuer/submission-status-counts/latest/"
    );
  }

  getOrganizationRoles(organizationId): Promise<any | void> {
    return this.apiGetService.get<any>(
      `organization/${organizationId}/user-roles/?ordering=user__last_name,user__first_name`
    );
  }

  assignAssessor(submissionId, data, reason: string = null) {
    return this.apiGetService
      .put(`issuer/submission/${submissionId}/`, data)
      .then((value: Submission) => {
        this.submissionUpdatedSubject.next({
          submission: value,
          reason: reason,
        });
        return value;
      });
  }

  bulkAssignSubmissions(data, reason) {
    return this.apiGetService
      .patch(`issuer/submission/bulk-assign/`, data)
      .then((value) => {
        this.submissionUpdatedSubject.next({
          submission: null,
          reason: reason,
        });
        return value;
      });
  }
}
