<div *ngIf="submission && submissionComments" class="l-detail">
  <section class="l-detail-x-content">
    <app-section-overview
      [decision]="decision"
      [previousSubmissions]="previousSubmissions"
      [submission]="submission"
      [user]="user"
      [submission]="submission"
    ></app-section-overview>

      <div
        *ngIf="(ownAward || user?.is_admin || user?.is_issuer || user?.is_assessor) && previousSubmissions && previousSubmissions.length > 1"
        id="certification-history"
        class="l-detail-x-subsection wrap"
      >
        <app-previous-submissions-table
          [submissions]="previousSubmissions"
          [thisSubmission]="submission"
          title="Related submissions"
        ></app-previous-submissions-table>
      </div>

    <div
      *ngIf="submission?.resubmission_note"
      id="resubmission-note"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Resubmission Note</span></h2>
      <h3>Here's What Changed</h3>
      <p>
        A short explanation about the changes made since the last submission.
      </p>
      <div class="forminput-small forminput forminput-attachment">
        <!-- <label for="comment" class="forminput-x-label">Response</label> -->
        <div class="forminput-x-input" style="position: relative">
          <textarea
            placeholder="None provided"
            style="position: absolute; height: 100%"
            [value]="submission.resubmission_note"
            disabled
          ></textarea>
          <div
            style="visibility: hidden; padding: 8px; min-height: 164px"
            [innerHTML]="submission.resubmission_note | newlineToBr"
          ></div>
        </div>
      </div>
    </div>

    <div
      *ngIf="assessmentExtension?.description"
      id="assessment-extension-overall-description"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Score Description</span></h2>
      <div [outerHTML]="assessmentExtension?.description | MarkdownIt"></div>
    </div>

    <app-sections-assessment-extension
      [recommendation]="recommendation"
      [canShowRecommendation]="canShowRecommendation"
      [rubricRecommendations]="rubricRecommendations"
      [previousRubricRecommendations]="previousRubricRecommendations"
      [assessmentExtension]="assessmentExtension"
      [fileEvidence]="fileEvidence"
      [urlEvidence]="urlEvidence"
      [textEvidence]="textEvidence"
      [showAssessorComment]="userIsStaff || (canShowRecommendation && ownAward)"
      [showEditButton]="user.is_admin || user.is_issuer"
      [submission]="submission"
      [comments]="submissionComments"
    ></app-sections-assessment-extension>

    <app-section-recommend-and-decide
      *ngIf="ownAward || user.is_admin || user.is_issuer || user.is_assessor"
      [recommendation]="recommendation"
      [canShowRecommendation]="canShowRecommendation"
      [decision]="decision"
      [showRecommendationComment]="userIsStaff"
      [showEditButton]="user.is_admin || user.is_issuer"
      [submission]="submission"
      [comments]="submissionComments"
      [ownAssess]="ownAssess"
    ></app-section-recommend-and-decide>

    <app-submission-history
      *ngIf="user.is_admin || user.is_issuer || user.is_assessor"
      [entries]="submissionHistory"
    ></app-submission-history>
  </section>

  <app-navigation-sections
    [completionText]="submission?.recommendation ? 'Recommendation' : ''"
    [allSections]="allSections"
    (navigateTo)="navigateTo($event)"
    [showNote]="!!submission?.resubmission"
  ></app-navigation-sections>
</div>
