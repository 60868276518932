import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";

import { Site } from "./shared/site.type";
import { SiteService } from "./shared/core/site.service";

@Injectable({
  providedIn: "root",
})
export class SiteGuard implements CanActivate, CanActivateChild {
  constructor(
    private siteService: SiteService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.tryGetSite(next, state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.tryGetSite(next, state.url);
  }

  tryGetSite(route, url): Promise<boolean> {
    const domain = window.location.host;

    this.siteService
      .getSite(domain)
      .then((site: Site) => {})
      .catch((err) => {
        this.router.navigate(["site"], { queryParams: { domain: domain } });
      });

    return Promise.resolve(true);
  }
}
