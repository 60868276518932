import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { BrowsePageRoutingModule } from "./browse-page-routing.module";
import { BrowsePageComponent } from "./browse-page.component";
import { BrowsePageService } from "./browse-page.service";

@NgModule({
  imports: [SharedModule, BrowsePageRoutingModule],
  providers: [BrowsePageService],
  declarations: [BrowsePageComponent],
})
export class BrowsePageModule {}
