import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  Router,
  CanActivate,
  CanActivateChild,
  CanLoad,
} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "../core/user.service";

@Injectable()
export class AdminAuthorizationGuard
  implements CanActivate, CanActivateChild, CanLoad
{
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAdmin();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAdmin();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAdmin();
  }

  isAdmin() {
    return this.userService.login().then((success: boolean) => {
      const user = this.userService.user,
        canAccess = success && user && user.is_admin;

      if (!canAccess) {
        this.router.navigate(["explore"]);
      }

      return canAccess;
    });
  }
}
