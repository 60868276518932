import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { TryLoginGuard } from "../try-login.guard";
import { SiteGuard } from "../site.guard";

import { BrowsePageComponent } from "./browse-page.component";

const routes: Routes = [
  {
    path: "explore",
    component: BrowsePageComponent,
    canActivate: [TryLoginGuard, SiteGuard],
    data: { title: "Explore Micro-credentials" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrowsePageRoutingModule {}
