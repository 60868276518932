import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

import { WindowRefService } from "./shared/core/window-ref.service";

@Injectable({
  providedIn: "root",
})
export class HelpdeskRedirectGuard implements CanActivate {
  constructor(private windowService: WindowRefService) {}

  canActivate(): boolean {
    this.windowService.nativeWindow.open(
      "https://digitalpromisehelp.zendesk.com/hc/en-us",
      "_self"
    );
    return false;
  }
}
