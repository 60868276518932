import { AssessmentSection } from "./assessment.type";
import { LevelBadgeType } from "./assessment.type";
import { Credential } from "./credential.type";
import { SubmissionRecommendation } from "./submission-recommendation.type";
import { SubmissionSections } from "./submission-section.type";
import { User } from "./user.type";

export enum SubmissionStatus {
  DENIED = "Denied",
  AWARDED = "Awarded",
  ASSESSED = "Assessed",
  ASSIGNED = "Assigned",
  SUBMITTED = "Submitted",
  RESUBMITTED = "Resubmitted",
  ADDITIONAL_ASSESSMENT_REQUESTED = "Additional Assessment Requested",
}

export interface Submission {
  credential: Credential | number;
  user: User | number;
  id?: number;
  created_at?: string;
  status?: string;
  status_date?: string;
  readable_id?: string;
  assessor?: User;
  recommendation?: SubmissionRecommendation;
  resubmission: Submission;
  sections: SubmissionSections[]
  related_level_badge: LevelBadgeType;
}
