<div
  class="tableattachment"
  [ngClass]="{ 'tableattachment-dark wrap wrap-light2': readonly }"
>
  <table>
    <thead>
      <tr>
        <th scope="row" class="visuallyhidden">Name</th>
        <th scope="row" class="visuallyhidden" *ngIf="!readonly">Remove</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let url of urls; let i = index">
        <td>
          <a href="{{ url }}" target="_blank">{{ url }}</a>
        </td>
        <td *ngIf="!readonly">
          <button
            class="button button-text"
            type="button"
            (click)="removeUrl.emit(i)"
          >
            <span class="button-x-text">Remove</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
