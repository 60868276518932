<form
  *ngIf="credential; else notfound"
  class="l-detail-x-content"
  (ngSubmit)="createSubmission()"
  [formGroup]="submissionForm"
  method="POST"
  action="/"
  role="form"
  aria-labelledby=""
  style="margin: 0"
  novalidate
>
  <app-section-overview
    [credential]="credential"
    [previousSubmissions]="previousSubmissions"
    [user]="user"
  ></app-section-overview>

  <div
    *ngIf="assessmentExtension?.description"
    id="assessment-extension-overall-description"
    class="l-detail-x-subsection wrap textblock"
  >
    <div class="section-group">
      <div class="section-header">Score Description</div>
    </div>

    <p><!-- no split level heading -- for pad --></p>

    <div [outerHTML]="assessmentExtension?.description | MarkdownIt"></div>
  </div>

  <ng-container *ngFor="let assessment of assessmentExtension?.assessments">
    <div
      id="part-{{ sectionIndex + 1 }}"
      class="l-detail-x-subsection wrap textblock"
      *ngFor="let section of assessment.sections; let sectionIndex = index; let last = last"
    >
      <div class="section-group">
        <div class="section-header" >Part {{ sectionIndex + 1 }}: {{ section.title }} </div>
        <div class="section-level-split"  [ngClass]="{
          'section-level-last': last
        }" *ngIf="section.leveled_badge?.length > 0"></div>
        <div class="level-header" [ngClass]="{
          'level-last': last
        }" *ngIf="section.leveled_badge?.length > 0" >{{ section?.leveled_badge?.length > 0 ? 'Level ' + section?.leveled_badge[0]?.level : '' }}</div>
      </div>

      <div *ngIf="getSectionLocked(section)">
        <div class="app-section-locked forminput forminput-x-input l-detail-x-subsectionbig" *ngIf="section?.leveled_badge[0]?.level && section?.leveled_badge[0]?.level != '1'">
          <div class="attachment attachment-big wrap wrap-light2">
            <div class="icon">
              <img src="/assets/images/icon-lock.svg" alt="Icon - Lock">
            </div>
            <div class="detail">
              <p>
                <strong>This Section is Locked</strong>
              </p>

              <p>
                Applying for this level of certification also requires applying for all of the previous levels. To unlock this section, unselect the “Skip this Section” option in a previous section.
              </p>
            </div>
          </div>

          <div class="textblock">
            <p>Section details <a (click)="toggleSectionShow(section)">
              <ng-container *ngIf="!getSectionShow(section)">(Show)</ng-container>
              <ng-container *ngIf="getSectionShow(section)">(Hide)</ng-container>
            </a></p>
          </div>
        </div>
      </div>

      <div *ngIf="!getSectionLocked(section) || getSectionShow(section)" class="section-info textblock">
        <div
          class="app-previous-not-passing-rubric-recommendation"
          [previousRubricRecommendations]="previousRubricRecommendations"
          [thisRubric]="section.rubric"
        ></div>

        <ng-container *ngFor="let question of section.questions">
          <h3>Prompt</h3>
          <div
            class="app-previous-not-passing-rubric-recommendation"
            [previousRubricRecommendations]="previousRubricRecommendations"
            [thisRubric]="question.rubric"
          ></div>
          <p data-app-question-text [questionText]="question.text"></p>
          <ng-template
            #textResponse
            [ngIf]="textQuestionIndexes[question.id] !== undefined"
            [ngIfElse]="fileResponse"
          >
            <ng-container formArrayName="texts">
              <ng-container [formGroupName]="textQuestionIndexes[question.id]">
                <app-answer-input-form
                  [question]="question"
                  [sectionNumber]="sectionIndex + 1"
                  [previousRubricRecommendations]="previousRubricRecommendations"
                  [value]="getQuestionEvidence(question.id, draft?.texts)?.text"
                  [disabled]="getSectionSkipped(section)"
                ></app-answer-input-form>
              </ng-container>
            </ng-container>
          </ng-template>

          <div class="app-skip-section forminput forminput-x-input l-detail-x-subsectionbig" *ngIf="section?.leveled_badge[0]?.level && section?.leveled_badge[0]?.level != '1'">
            <div class="attachment attachment-big wrap wrap-light2">
              <div class="textblock">
                <p>
                  If your product is not applying for this level at this time, 
                  check the box below to skip this section.
                </p>
              </div>
    
              <div class="formcheckbox formcheckbox-bold">
                <input
                  formcontrolname="skip_section" 
                  type="checkbox" 
                  id="skip_section_{{ section?.leveled_badge[0]?.level }}"
                  class="visuallyhidden ng-untouched ng-pristine ng-invalid" 
                  [checked]="getSectionSkipped(section)"
                  [disabled] = "getSectionDisabled(section)"
                  (change)="toggleSectionSkipped(section)" />
                  <label
                    for="skip_section_{{ section?.leveled_badge[0]?.level }}">
                    Skip this section 
                    <span
                      class="forminput-x-sublabel">
                      When you check this box, all prompts within this section, 
                      and any additional sections that apply to higher levels 
                      will be disabled and locked.
                    </span>
                  </label> 
              </div>
            </div>
          </div>

          <ng-template #fileResponse>
            <ng-template [ngIf]="question.question_type === 'FileQuestion'">
              <app-answer-input-form
                (uploaded)="handleUpload($event)"
                (removed)="removeUpload($event)"
                [question]="question"
                [sectionNumber]="sectionIndex + 1"
                [previousRubricRecommendations]="previousRubricRecommendations"
                [valueUrls]="
                  getQuestionEvidence(question.id, draft?.urls, true, 'url')
                "
                [draftFiles]="
                  getQuestionEvidence(question.id, draft?.files, true)
                "
                [disabled]="getSectionSkipped(section)"
              ></app-answer-input-form>
            </ng-template>
          </ng-template>
          <p *ngIf="question.external_answer_url">
            <a href="{{ question.external_answer_url }}">{{
              question.external_answer_url
            }}</a>
          </p>
        </ng-container>

        <div
          data-app-rubric-section
          *ngIf="section.rubric"
          [section]="section"
          class="l-detail-x-subsectionbig"
          [previousRubricRecommendations]="previousRubricRecommendations"
        ></div>
      </div> <!-- /section-info -->
    </div>
  </ng-container>

  <div
    *ngIf="deniedSubmission"
    id="resubmission-note"
    class="l-detail-x-subsection wrap textblock"
  >
    <h2><span>Resubmission Note</span></h2>
    <h3>Let Us Know What Changed</h3>
    <p>
      Please provide a short explanation about the changes you made since your
      last submission.
    </p>
    <div class="forminput-small forminput forminput-attachment">
      <!-- <label for="comment" class="forminput-x-label">Response</label> -->
      <div class="forminput-x-input" style="position: relative">
        <input type="hidden" formControlName="resubmission" />
        <textarea
          style="position: absolute; height: 100%"
          formControlName="resubmission_note"
          placeholder="Enter your response..."
        ></textarea>
        <div
          style="visibility: hidden; padding: 8px; min-height: 164px"
          [innerHTML]="
            submissionForm.controls.resubmission_note.value | newlineToBr
          "
        ></div>
      </div>
    </div>
  </div>

  <div id="complete" class="l-detail-x-subsection wrap textblock">
    <h2><span>Complete Submission</span></h2>
    <h3>
      <span>Student Privacy and Academic Integrity Statement</span>
      <p style="max-height: 0px">&nbsp;</p>
      <span
        >Declaración de Privacidad e Integridad Académica del estudiante</span
      >
    </h3>
    <fieldset class="forminput forminput-small forminput-inputsspaced">
      <legend class="visuallyhidden">Privacy</legend>
      <div class="forminput-x-input">
        <div class="formcheckbox formcheckbox-bold">
          <input
            formControlName="confirm_complete"
            class="visuallyhidden"
            type="checkbox"
            id="formcheckbox"
          />
          <label for="formcheckbox">
            Ready for Review
            <span class="forminput-x-sublabel">
              I confirm that my application is ready for review and I have set
              permissions appropriately for my evidence.
            </span>
            <p style="max-height: 10px">&nbsp;</p>
            Listo para revisión
            <span class="forminput-x-sublabel">
              Confirmo que mi solicitud está lista para ser revisada y que he
              establecido los permisos apropiados para mi evidencia.
            </span>
          </label>
        </div>
      </div>
      <div class="forminput-x-input">
        <div class="formcheckbox formcheckbox-bold">
          <input
            formControlName="confirm_lawful"
            class="visuallyhidden"
            type="checkbox"
            id="formcheckbox2"
          />
          <label for="formcheckbox2">
            Confirm Release
            <span class="forminput-x-sublabel">
              I confirm that this submission does not include any personally
              identifiable student information and does not violate any federal,
              state, or local laws. I confirm that I have collected signed media
              releases for any students featured in this submission, if
              necessary and appropriate.
            </span>
            <p style="max-height: 10px">&nbsp;</p>
            Confirmar publicación
            <span class="forminput-x-sublabel">
              Confirmo que este envío no incluye ninguna información personal
              identificable del estudiante y no viola ninguna ley federal,
              estatal o local. Confirmo que he recopilado consentimientos
              informados firmados de cada estudiante que aparece en esta
              presentación, de ser necesario y apropiado.
            </span>
          </label>
        </div>
      </div>
      <div class="forminput-x-input">
        <div class="formcheckbox formcheckbox-bold">
          <input
            formControlName="confirm_integrity"
            class="visuallyhidden"
            type="checkbox"
            id="formcheckbox3"
          />
          <label for="formcheckbox3">
            Confirm Work
            <span class="forminput-x-sublabel">
              I confirm that all content within this micro-credential submission
              is my own original work and is submitted in compliance with the
              Terms of Use and any applicable academic integrity policy of my
              school or district. All artifacts and evidence contained in the
              submission are an authentic representation of my work. If Digital
              Promise believes that any content in the micro-credential
              submission is plagiarized or otherwise violates the Terms of Use,
              I understand that Digital Promise may report the submission to my
              applicable school or district and take any other action permitted
              by the Terms of Use.
            </span>
            <p style="max-height: 10px">&nbsp;</p>
            Confirmar el trabajo
            <span class="forminput-x-sublabel">
              Confirmo que todo el contenido dentro de esta micro credencial es
              mi trabajo original y se envía de conformidad con los términos de
              uso y cualquier política de integridad académica aplicable de mi
              escuela o distrito. Todo el material de apoyo y las evidencias
              incluidas en esta MC son una representación auténtica de mi
              trabajo. Si Digital Promise (DP) considera que cualquier contenido
              en el envío de micro credenciales está plagiado o de otra manera
              viola los Términos de Uso, entiendo que DP pueden reportar este
              incidente a mi escuela o distrito y tomar cualquier otra acción
              permitida por los Términos de Uso.
            </span>
          </label>
        </div>
      </div>
    </fieldset>
    <div class="l-detail-x-button">
      <div
        class="calltoaction calltoaction-pay calltoaction-white"
        *ngIf="!(getPrice() > 0); else needsPayment"
      >
        <div class="calltoaction-x-buttonbox">
          <div class="calltoaction-x-twobuttons">
            <button
              class="button button-large"
              type="submit"
              [disabled]="submissionForm.invalid"
            >
              <span class="button-x-text">Submit Application</span><br />
              <span class="button-x-text">Someter la solicitud</span>
            </button>
            <div style="min-width: 30px; max-width: 30px"></div>
            <button
              class="button button-large"
              [class.button-secondary]="submissionForm.valid"
              type="submit"
              (click)="createDraft()"
              *ngIf="!(getPrice() > 0)"
            >
              <span class="button-x-text">Save Draft</span><br />
              <span class="button-x-text">Guardar borrador</span>
            </button>
          </div>
        </div>
      </div>
      <ng-template #needsPayment>
        <div class="calltoaction calltoaction-pay calltoaction-white">
          <div class="calltoaction-x-text">
            ${{ getPrice() }} to
            <ng-container *ngIf="deniedSubmission; else reapplyPriceText"
              >reapply</ng-container
            ><ng-template #reapplyPriceText>apply</ng-template>
          </div>

          <div class="calltoaction-x-buttonbox">
            <p>Submit application and pay assessment fee using:</p>
            <div class="calltoaction-x-twobuttons">
              <button
                class="calltoaction-x-button button button-large"
                type="button"
                [disabled]="submissionForm.invalid"
                (click)="showStripeCheckout()"
              >
                <span class="button-x-text">Card</span>
              </button>
              <button
                class="calltoaction-x-button button button-large"
                type="button"
                [disabled]="submissionForm.invalid"
                (click)="purchaseModalIsOpen = true"
              >
                <span class="button-x-text">Coupon</span>
              </button>
              <div style="min-width: 30px; max-width: 30px"></div>
              <button
                class="calltoaction-x-button button button-large"
                [class.button-secondary]="submissionForm.valid"
                type="button"
                (click)="createDraft()"
              >
                <span class="button-x-text">Save Draft</span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</form>

<app-navigation-sections
  completionText="Complete Submission"
  [allSections]="allSections"
  (navigateTo)="navigateTo($event)"
  [showNote]="deniedSubmission"
  [showNoteOnTop]="false"
></app-navigation-sections>

<app-purchase-with-coupon
  [isOpen]="purchaseModalIsOpen"
  (closeModalAndSubmit)="closePurchaseModal($event)"
>
</app-purchase-with-coupon>

<ng-template #notfound>
  <app-not-found></app-not-found>
</ng-template>
