<div *ngIf="submission && submissionComments" class="l-detail">
  <section class="l-detail-x-content">
    <div
      *ngIf="credential"
    >
      <div class="l-detail-x-subsection l-detail-x-subsectionsm wrap textblock">
        <div
          decision-status-bar
          class="submissionstate"
          [submission]="submission"
          [decision]="decision"
          [user]="user"
        ></div>
      </div>

      <div class="l-detail-x-subsection l-detail-x-subsectionsm wrap textblock">
        <app-explore-more></app-explore-more>
      </div>

      <div class="l-detail-x-subsection l-detail-x-subsectionsm wrap textblock">
        <h1>{{ credential.name }}</h1>
        <div class="l-overview l-overview-award l-overview-big">
          <div class="l-overview-x-image">
            <img src="{{ credential.image }}" width="240" height="240" />
          </div>
          <div class="l-overview-x-content">
            <div class="l-overview-x-section">
              <p>
                {{ credential.description }} <br /><a
                  [routerLink]="['/explore', credential.slug]"
                  >View Details</a
                >
              </p>
              <ul>
                <li>
                  <a class="icon icon-organization small" href="#">
                    <span class="icon-x-text">{{
                      credential.organization.name
                    }}</span>
                  </a>
                </li>
                <li>
                  <a
                    *ngIf="credential.tag"
                    class="icon icon-stack small"
                    href="#"
                  >
                    <span class="icon-x-text">{{ credential.tag }}</span>
                  </a>
                </li>
              </ul>
              <a
                *ngIf="ownAward"
                class="button l-overview-x-buttonicon"
                download
                href="{{ award.assertion }}/image"
              >
                <span class="button-x-text icon icon-downloadwhite"
                  >Download Badge</span
                >
              </a>
            </div>
            <div *ngIf="ownAward" class="l-overview-x-section l-overview-x-share">
              <h6>Share</h6>
              <div class="l-overview-x-actions">
                <button
                  type="button"
                  class="button l-overview-x-buttonicon"
                  [routerLink]="[
                    '/',
                    { outlets: { popup: ['share', award.id] } }
                  ]"
                >
                  <span class="button-x-text icon icon-email">Email Badge</span>
                </button>
              </div>
              <p>
                Learn more about open badges at
                <a href="http://digitalpromise.org/"
                  >http://digitalpromise.org/</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="ownAward || shareToken?.includes_feedback">
      <div
        *ngIf="submission?.resubmission"
        id="resubmission-note"
        class="l-detail-x-subsection wrap textblock"
      >
        <h2><span>Resubmission Note</span></h2>
        <h3>Here's What Changed</h3>
        <p>
          A short explanation about the changes made since the last submission.
        </p>
        <div class="forminput-small forminput forminput-attachment">
          <!-- <label for="comment" class="forminput-x-label">Response</label> -->
          <div class="forminput-x-input" style="position: relative">
            <textarea
              placeholder="None provided"
              style="position: absolute; height: 100%"
              [value]="submission.resubmission_note"
              disabled
            ></textarea>
            <div
              style="visibility: hidden; padding: 8px; min-height: 164px"
              [innerHTML]="submission.resubmission_note | newlineToBr"
            ></div>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      id="certification-history"
      class="l-detail-x-subsection wrap"
      *ngIf="(ownAward || shareToken?.includes_feedback) && previousSubmissions && previousSubmissions.length > 1"
    >
        <app-previous-submissions-table
          [submissions]="previousSubmissions"
          [showLinks]="ownAward"
          title="Certification History"
        ></app-previous-submissions-table>
    </div>

    <div
      *ngIf="assessmentExtension?.description"
      id="assessment-extension-overall-description"
      class="l-detail-x-subsection wrap textblock"
    >
      <h2><span>Score Description</span></h2>
      <div [outerHTML]="assessmentExtension?.description | MarkdownIt"></div>
    </div>

    <app-sections-assessment-extension
      [recommendation]="recommendation"
      [canShowRecommendation]="ownAward || shareToken?.includes_feedback"
      [showAssessorComment]="ownAward || shareToken?.includes_feedback"
      [rubricRecommendations]="rubricRecommendations"
      [assessmentExtension]="assessmentExtension"
      [fileEvidence]="fileEvidence"
      [urlEvidence]="urlEvidence"
      [textEvidence]="textEvidence"
      [submission]="submission"
      [comments]="submissionComments"
    ></app-sections-assessment-extension>

    <app-submission-history
      *ngIf="user?.is_admin || user?.is_issuer || user?.is_assessor"
      [entries]="submissionHistory"
    ></app-submission-history>

    <div
      *ngIf="credential"
    >
      <div class="l-detail-x-subsection l-detail-x-subsectionsm wrap textblock">
        <div
          decision-status-bar
          class="submissionstate"
          [submission]="submission"
          [decision]="decision"
          [user]="user"
        ></div>
      </div>
    </div>

    <app-submission-comments
      *ngIf="user.is_admin || user.is_issuer || user.is_assessor"
      clsWrap="textinfo-outer"
      [data]="submissionComments"
      [info]="{
        contentType: ContentType.submission,
        commentType: CommentType.ASSESSOR,
        pk: submission.id,
        submission: submission
      }"
    >
      <h4>Comments for Issuer Only:</h4>
    </app-submission-comments>

  </section>
</div>
