import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { cloneDeep, compact, first, flatten, map, uniq } from "lodash";

import { Credential } from "../shared/credential.type";
import { Submission } from "../shared/submission.type";
import { SubmissionRecommendation } from "../shared/submission-recommendation.type";
import { UserService } from "../shared/core/user.service";
import { Rubric, RubricCriterion } from "../shared/assessment.type";

import { SubmissionDetailMixinComponent } from "../shared/submission-detail-mixin/submission-detail-mixin.component";
import { SubmissionDetailMixinService } from "../shared/submission-detail-mixin/submission-detail-mixin.service";

import { RecommendationCreateService } from "./recommendation-create.service";
import { Confirmation, Error } from "../shared/messages.constants";
import { requiredIfValidator } from "app/shared/utils/validators";
import { Comment, CommentType, ContentType } from "app/shared/comment.type";
import { SubmissionRecommendationRubric } from "app/shared/submission-recommendation-rubric.type";

@Component({
  selector: "app-recommendation-create",
  templateUrl: "./recommendation-create.component.html",
  styleUrls: ["./recommendation-create.component.css"],
})
export class RecommendationCreateComponent
  extends SubmissionDetailMixinComponent
  implements OnInit
{
  recommendForm: UntypedFormGroup;
  formInitialized: Boolean = false;

  constructor(
    protected route: ActivatedRoute,
    private titleService: Title,
    protected elementRef: ElementRef,
    protected userService: UserService,
    protected submissionMixinService: SubmissionDetailMixinService,
    private messageService: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private recommendationCreateService: RecommendationCreateService
  ) {
    super(userService, submissionMixinService, elementRef, route);
  }

  ngOnInit() {
    this.submission$.subscribe((submission: Submission) => {
      this.titleService.setTitle(
        `${(<Credential>submission.credential).name} Submission Assessment - ${
          (<Credential>submission.credential).organization.name
        } | Digital Promise`
      );
    });

    super.ngOnInit();
    this.instantiateForm();
  }

  instantiateForm() {
    this.recommendForm = this.fb.group({
      id: null,
      submission: null,
      assessor: this.user.id,
      rubric_recommendations: this.fb.array([]),
      to_award: [null, Validators.required],
      leveled_badge: null,
      request_additional_assessment: false,
    });
  }

  initializeForm() {
    this.recommendForm.controls.submission.setValue(this.submission.id);
    this.recommendForm.controls.id.setValue(
      first(this.rubricRecommendations)?.recommendation
    );

    let rubricRecommendationIndex = 0;
    const rubricRecommendations = [];
    const form = this.recommendForm;
    const submissionComments = this.submissionComments;
    const that = this;

    function rubricCriterionLevelChoice(
      rubric: Rubric,
      recommendation: SubmissionRecommendationRubric
    ) {
      const choiceControl = rubric.criterions
        .map((criterion: RubricCriterion) => criterion.id)
        .reduce(function (criterionMap, criterionId) {
          criterionMap[`criterion_${criterionId}`] = [
            recommendation.criterion_levels || [],
            requiredIfValidator(
              () => !form.get("request_additional_assessment").value
            ),
          ];
          return criterionMap;
        }, {});
      return choiceControl;
    }

    function rubricComments(rubric: Rubric) {
      const choiceControl = rubric.criterions
        .map((criterion: RubricCriterion) => criterion.id)
        .reduce((criterionMap, criterionId) => {
          const comment: Comment | null = submissionComments.find(
            (c: Comment) =>
              c.comment_type === CommentType.SUBMITTER &&
              c.content_type === ContentType.rubricCriterion &&
              c.object_pk === criterionId
          );
          criterionMap[criterionId] = [comment?.comment];
          return criterionMap;
        }, {});
      return choiceControl;
    }

    for (const assessment of this.assessmentExtension.assessments) {
      for (const section of assessment.sections) {
        for (const question of section.questions) {
          if (question.rubric) {
            question.rubric.formArrayIndex = rubricRecommendationIndex++;
            const recommendation = that.getRubricRecommendation(
              `assessment question`,
              question.id,
              question.rubric.id
            );
            rubricRecommendations.push(
              this.fb.group({
                id: recommendation?.id,
                rubric: question.rubric.id,
                assessment_type: "assessmentquestion",
                assessment_type_pk: question.id,
                comments: this.fb.group(rubricComments(question.rubric)),
                criterion_levels: this.fb.group(
                  rubricCriterionLevelChoice(question.rubric, recommendation)
                ),
              })
            );
          }
        }
        if (section.rubric) {
          section.rubric.formArrayIndex = rubricRecommendationIndex++;
          const recommendation = that.getRubricRecommendation(
            "assessment section",
            section.id,
            section.rubric.id
          );
          rubricRecommendations.push(
            this.fb.group({
              id: recommendation?.id,
              rubric: section.rubric.id,
              assessment_type: "assessmentsection",
              assessment_type_pk: section.id,
              comments: this.fb.group(rubricComments(section.rubric)),
              criterion_levels: this.fb.group(
                rubricCriterionLevelChoice(section.rubric, recommendation)
              ),
            })
          );
        }
      }
    }

    this.recommendForm.setControl(
      "rubric_recommendations",
      this.fb.array(rubricRecommendations)
    );

    this.formInitialized = true;
  }

  requestAdditionalAssessment() {
    this.recommendForm.controls.request_additional_assessment.setValue(true);
    this.recommendForm.controls.to_award.removeValidators(Validators.required);
  }

  // create recommendation, allow partial save in case of requestng additional assessment
  createRecommendation() {
    const recommendation = cloneDeep(this.recommendForm.value);

    for (const rubricRecommendation of recommendation.rubric_recommendations) {
      rubricRecommendation.criterion_levels = uniq(
        flatten(
          (<any>Object).values(rubricRecommendation.criterion_levels)
        ).filter((value: string | null) => value)
      );
    }

    for (const rubricRecommendation of recommendation.rubric_recommendations) {
      rubricRecommendation.comments = compact(
        map(rubricRecommendation.comments, (value, rubricCriterionId) => {
          if (!value) return null;
          return { object_pk: rubricCriterionId, comment: value };
        })
      );
    }

    if (recommendation.request_additional_assessment) {
      delete recommendation.to_award;
    }

    const fn: Promise<SubmissionRecommendation> = recommendation?.id
      ? this.recommendationCreateService.putAssessorRecommendation(
          recommendation
        )
      : this.recommendationCreateService.postRecommendation(recommendation);

    fn.then((recommendation: SubmissionRecommendation) => {
      this.recommendation = recommendation;
      this.messageService.success(
        recommendation.request_additional_assessment
          ? Confirmation.ADDIONAL_ASSESSMENT_REQUEST
          : Confirmation.ASSESSMENT
      );
      this.router.navigate(["/assess/to-assess"]);
    }).catch((err) => {
      if (recommendation.request_additional_assessment) {
        this.recommendForm.controls.request_additional_assessment.setValue(
          false
        );
        this.recommendForm.controls.to_award.addValidators(Validators.required);
      }
      this.messageService.error(Error.ASSESS(err.message), "");
    });

    return false;
  }
}
