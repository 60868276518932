import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";

import { RecommendationCreateRoutingModule } from "./recommendation-create-routing.module";
import { RecommendationCreateComponent } from "./recommendation-create.component";
import { RecommendationCreateService } from "./recommendation-create.service";

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    RecommendationCreateRoutingModule,
  ],
  providers: [RecommendationCreateService],
  declarations: [RecommendationCreateComponent],
})
export class RecommendationCreateModule {}
