import { Submission } from "./submission.type";

export enum CommentType {
  ASSESSOR = "Assessor",
  ISSUER = "Issuer",
  SUBMITTER = "Submitter",
}

export enum ContentType {
  submission = "mainsite.submission",
  rubricCriterion = "mainsite.rubriccriterion",
}

export enum CommentAction {
  edit = "Edit",
  remove = "Remove",
  view = "View",
  add = "Add",
}

export interface CommentInfo {
  commentType: CommentType;
  contentType: ContentType;
  pk: number;
  submission?: Submission;
}

export interface CommentSubmitter {
  object_pk: number | string;
  comment: string | null;
}

export interface Comment {
  comment: string;
  comment_type: CommentType;
  id: number | null;
  object_pk: number;
  content_type: ContentType;
  submission_id: number;
  submit_date: Date;
  created_at: Date;
  updated_at: Date;
  user_email: string | null;
  user_name: string | null;
  user_id: number | null;
}
