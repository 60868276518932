<ng-container>
  <section class="statusfilterslarge" *ngIf="filters.length > 0">
    <ng-container *ngFor="let filter of filters">
      <button
        *ngIf="filter.show"
        [routerLink]="filter.link"
        [routerLinkActive]="['']"
        [ngClass]="rla.isActive ? 'statusfilterlarge-is-active' : ''"
        #rla="routerLinkActive"
        class="statusfilterlarge statusfilterlarge-{{ filter.color }}"
        type="button"
      >
        <span class="statusfilterlarge-x-container">
          <span class="statusfilterlarge-x-count">{{ filter.count }}</span>
          <span class="statusfilterlarge-x-text">{{ filter.name }}</span>
        </span>
      </button>
    </ng-container>
  </section>

  <div class="l-filter" *ngIf="filters.length > 0">
    <div
      class="l-menudropdown l-menudropdown-left"
      [ngClass]="{ 'l-menudropdown-is-active': isOpen }"
      id="menudropdown-status"
    >
      <button
        class="menutrigger menutrigger-count"
        *ngIf="activeFilter"
        type="button"
        (click)="isOpen = !isOpen"
        title="Filter by status"
        data-menudropdown="menudropdown-status"
      >
        <span class="statuscount statuscount-{{ activeFilter.color }}">{{
          activeFilter.count
        }}</span>
        <span class="menutrigger-x-text">{{ activeFilter.name }}</span>
      </button>
      <div class="l-menudropdown-x-menu">
        <div class="dropdown dropdown-left">
          <ng-container *ngFor="let filter of filters">
            <a
              *ngIf="filter.show"
              class="dropdownitem"
              [routerLink]="filter.link"
              (click)="isOpen = !isOpen"
            >
              <span class="dropdownitem-x-text">{{ filter.name }}</span>
              <span class="dropdownitem-x-count">
                <span
                  class="statuscount statuscountnumber statuscountnumber-{{
                    filter.color
                  }}"
                  >{{ filter.count }}</span
                >
              </span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
