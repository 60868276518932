/* eslint-disable curly */
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";

import { ToastrService, ToastrConfig } from "ngx-toastr";

import { School } from "../shared/school.type";
import { District } from "../shared/district.type";
import { User } from "../shared/user.type";
import { SearchOption } from "../shared/search-menu/search-option.type";

import { UserService } from "../shared/core/user.service";
import { ProfileService } from "./profile.service";
import { ConfigService } from "../shared/core/config.service";
import { Confirmation, Error } from "../shared/messages.constants";

import { from as fromPromise, Observable, Subscription } from "rxjs";

import { PardotService } from "../shared/pardot.service";

import { switchMap, map } from "rxjs/operators";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: User;
  userSchool: { name: string; value: number };
  userDistrict: { name: string; value: number };
  userForm: UntypedFormGroup;
  submitted = false;
  educatorChoices: any;
  onStateValueChange: Function;
  onDistrictValueChange: Function;
  onSchoolValueChange: Function;
  stateSub: Subscription;
  districtSub: Subscription;
  messages: any = {
    required: Error.REQUIRED,
  };

  get firstName() {
    return this.userForm.get("first_name");
  }
  get lastName() {
    return this.userForm.get("last_name");
  }
  get email() {
    return this.userForm.get("email");
  }
  get educatorFields() {
    return this.userForm.get("educator");
  }
  get state() {
    return this.educatorFields.get("state");
  }
  get district() {
    return this.educatorFields.get("district");
  }
  get school() {
    return this.educatorFields.get("school");
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private userService: UserService,
    private pardotService: PardotService,
    private profileService: ProfileService,
    private messageService: ToastrService
  ) {}

  ngOnInit() {
    this.user = this.userService.user;
    // this.userSub = this.userService.user$.subscribe((user: User) => this.user = user);

    this.profileService.getEducatorChoices().then((educatorChoices: any) => {
      this.educatorChoices = educatorChoices;
    });

    const educatorProfile = this.user.educator;
    if (educatorProfile && educatorProfile.school) {
      this.userSchool = {
        name: educatorProfile.school.name,
        value: educatorProfile.school.id,
      };
    }
    if (educatorProfile && educatorProfile.district) {
      this.userDistrict = {
        name: educatorProfile.district.name,
        value: educatorProfile.district.id,
      };
    }

    this.createForm(this.user);

    this.onStateValueChange = (
      src$: Observable<string>
    ): Observable<SearchOption[]> =>
      src$.pipe(
        map((q) => q || ""),
        switchMap((payload) =>
          fromPromise(this.profileService.getStates(payload))
        ),
        map((list: any[]) =>
          list.map((option) => this.mapToSearchOption(option))
        )
      );

    this.onDistrictValueChange = (
      src$: Observable<string>
    ): Observable<SearchOption[]> =>
      src$.pipe(
        map((q) => q || ""),
        switchMap((payload) =>
          fromPromise(
            this.profileService.getDistricts(payload, this.state.value.id)
          )
        ),
        map((list: District[]) =>
          list.map((option) => this.mapToSearchOption(option))
        )
      );

    this.onSchoolValueChange = (
      src$: Observable<string>
    ): Observable<SearchOption[]> =>
      src$.pipe(
        map((q) => q || ""),
        switchMap((payload) =>
          fromPromise(
            this.profileService.getSchools(payload, this.district.value.id)
          )
        ),
        map((list: School[]) =>
          list.map((option) => this.mapToSearchOption(option))
        )
      );

    this.stateSub = this.district.valueChanges.subscribe((value) => {
      if (!value) this.district.setValue(null, { emitEvent: false });
    });

    this.districtSub = this.district.valueChanges.subscribe((value) => {
      if (!value) this.school.setValue(null, { emitEvent: false });
    });

    if (this.route.snapshot.queryParamMap.get("first_login")) {
      this.router
        .navigate([], {
          queryParams: { first_login: null },
          queryParamsHandling: "merge",
        })
        .then(() => {
          const options: ToastrConfig = <ToastrConfig>{
            timeOut: ConfigService.MESSAGE_FOREVER_TIMEOUT,
          };
          this.messageService.success(Confirmation.WELCOME, "", options);
          this.pardotService.postPardot(this.user);
        });
    }
  }

  mapToSearchOption(option): SearchOption {
    return { viewValue: option.name, model: option };
  }

  createForm(user: User) {
    // NOTE: This form is also associated with a Pardot form handler. Email,
    // first name and last name are marked as required on that form handler. If
    // these fields become optional here, the form handler will need to be
    // updated or removed.
    this.userForm = new UntypedFormGroup({
      id: new UntypedFormControl(user.id),
      first_name: new UntypedFormControl(user.first_name, Validators.required),
      last_name: new UntypedFormControl(user.last_name, Validators.required),
      email: new UntypedFormControl(user.email, Validators.required),
      receive_email_notifications: new UntypedFormControl(
        user.receive_email_notifications
      ),
      educator: new UntypedFormGroup({
        id: new UntypedFormControl(user.educator && user.educator.id),
        title: new UntypedFormControl(
          (user.educator && user.educator.title) || ""
        ),
        grade_level: new UntypedFormControl(
          user.educator && user.educator.grade_level
        ),
        content_area: new UntypedFormControl(
          user.educator && user.educator.content_area
        ),
        years_of_experience: new UntypedFormControl(
          (user.educator && user.educator.years_of_experience) || ""
        ),
        degree_earned: new UntypedFormControl(
          user.educator && user.educator.degree_earned
        ),
        state: new UntypedFormControl(user.educator && user.educator.state),
        district: new UntypedFormControl(
          user.educator && user.educator.district
        ),
        school: new UntypedFormControl(user.educator && user.educator.school),
        private_demographic_information: new UntypedFormControl(
          (user.educator && user.educator.private_demographic_information) ||
            false
        ),
      }),
    });
  }

  submit() {
    this.userService
      .postUser(this.formData)
      .then((user: User) => {
        return this.pardotService.postPardot(user);
      })
      .then(() => {
        this.submitted = true;
        this.messageService.success(Confirmation.PROFILE);
        this.navigateAway();
      });
  }

  get formData() {
    const result = this.userForm.value;
    ["school", "district", "state"].forEach((key) => {
      if (result.educator && result.educator[key]) {
        result.educator[key] = result.educator[key].id;
      }
    });
    return result;
  }

  cancel() {
    this.navigateAway();
  }

  navigateAway() {
    const previousParams = this.route.snapshot.queryParamMap,
      continuation = previousParams.get("continuation"),
      targetRoute = continuation || "/";
    this.router.navigateByUrl(targetRoute);
  }

  ngOnDestroy() {
    this.districtSub.unsubscribe();
    this.stateSub.unsubscribe();
  }
}
