import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class PreviousRouteGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // if no "continuation" data, add previous route and reroute
    if (!next.queryParamMap.has("continuation")) {
      const previousRoute = <any>(
        window.location.toString().replace(/^[^/]+:\/\/[^/]+/, "")
      );
      if (previousRoute !== state.url) {
        this.router.navigate([state.url], {
          queryParams: { continuation: previousRoute },
        });
      }
    }
    return true;
  }
}
