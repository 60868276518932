import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";

import { Draft, DraftLite } from "../shared/draft.type";

@Injectable()
export class ProgressPageService {
  orderingFilter: any;

  constructor(private apiGetService: ApiGetService) {}

  getSubmissionCounts(): Promise<any | void> {
    return this.apiGetService
      .get<any>("educator/submission-status-counts/latest/")
      .catch((err) => {});
  }

  getDraftsFull(): Promise<Draft[] | void> {
    return this.apiGetService.get<Draft[]>("drafts/full").catch((err) => {});
  }

  getDraftsLite(): Promise<DraftLite[] | void> {
    return this.apiGetService
      .get<DraftLite[]>("drafts/lite")
      .catch((err) => {});
  }
}
