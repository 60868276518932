import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { TryLoginGuard } from "../try-login.guard";

import { CredentialDetailComponent } from "./credential-detail.component";
import { CredentialResolveGuard } from "../shared/resolvers/credential-resolve.guard";

const routes: Routes = [
  {
    path: "explore/:slug",
    component: CredentialDetailComponent,
    canActivate: [TryLoginGuard],
    resolve: {
      credential: CredentialResolveGuard,
    },
    data: { title: "Explore Micro-credentials" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CredentialDetailRoutingModule {}
