import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

import { HistoryData } from "../history.type";

@Component({
  selector: "app-submission-history",
  templateUrl: "./submission-history.component.html",
  styleUrls: ["./submission-history.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmissionHistoryComponent {
  @Input() title = "Submission History";
  @Input() entries: HistoryData[] = [];
}
