<div
  class="l-dialog dialog"
  [ngClass]="{ 'dialog-is-open': modalIsOpen }"
  aria-modal="true"
  role="dialog"
  ngxFocusTrap
  #ngxFocus="ngxFocusTrap"
  [initActivated]="false"
>
  <div class="dialog-x-content wrap">
    <div class="dialog-x-close" (click)="closeDialog()">
      <button aria-labelledby="" type="button">Close Dialog</button>
    </div>
    <form
      class="l-dialogform"
      [formGroup]="couponForm"
      #couponFormLocal
      (ngSubmit)="onSubmit()"
      novalidate
    >
      <div class="dialogheader">
        <h3 class="dialogheader-x-heading">Purchase with Coupon</h3>
        <p></p>
      </div>
      <div
        class="forminput"
        [ngClass]="{
          'forminput-x-error': (code.touched && code.invalid) || invalidCode
        }"
      >
        <legend for="code" class="forminput-x-label">Coupon Code</legend>
        <div
          class="forminput-x-input"
          [ngClass]="{
            'forminput-x-error': (code.touched && code.invalid) || invalidCode
          }"
        >
          <input
            type="text"
            name="code"
            id="code"
            placeholder="Enter Coupon Code"
            formControlName="code"
            #input
            (input)="input.value = $event.target.value.toUpperCase()"
          />
          <p
            class="forminput-x-errortext"
            *ngIf="(code.touched && code.invalid) || invalidCode"
          >
            <span *ngIf="code.hasError('required')">{{
              messages.required
            }}</span>
            <span *ngIf="invalidCode">{{ messages.expired }}</span>
          </p>
        </div>
      </div>
      <div class="l-dialogform-x-button">
        <button
          class="button"
          type="submit"
          [disabled]="couponForm.pristine || couponForm.invalid"
        >
          <span class="button-x-text">Submit</span>
        </button>
        <a
          class="button button-tertiary"
          (click)="closeDialog()"
          href="javascript:;"
        >
          <span class="button-x-text">Cancel</span>
        </a>
      </div>
    </form>
  </div>
</div>
