import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  anyModalIsOpen = new Subject<boolean>();

  constructor() {}
}
