import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { NoWhitelabelComponent } from "./no-whitelabel.component";

const routes: Routes = [
  {
    path: "",
    component: NoWhitelabelComponent,
    data: { title: "Not found" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NoWhitelabelRoutingModule {}
