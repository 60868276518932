import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { ToastrService } from "ngx-toastr";

import { Credential } from "../shared/credential.type";
import { Submission } from "../shared/submission.type";
import { SubmissionDecision } from "../shared/submission-decision.type";
import { SubmissionRecommendation } from "../shared/submission-recommendation.type";
import { AwardedCredential } from "../shared/awarded-credential.type";
import { ShareToken } from "../shared/share-token.type";
import { UserService } from "../shared/core/user.service";

import { WindowRefService } from "../shared/core/window-ref.service";

import { SubmissionDetailMixinComponent } from "../shared/submission-detail-mixin/submission-detail-mixin.component";
import { SubmissionDetailMixinService } from "../shared/submission-detail-mixin/submission-detail-mixin.service";
import { Confirmation } from "../shared/messages.constants";

import { AwardedCredentialDetailService } from "./awarded-credential-detail.service";

@Component({
  selector: "app-awarded-credential-detail",
  templateUrl: "./awarded-credential-detail.component.html",
  styleUrls: ["./awarded-credential-detail.component.css"],
})
export class AwardedCredentialDetailComponent
  extends SubmissionDetailMixinComponent
  implements OnInit
{
  shareToken: ShareToken;
  expired = false;

  constructor(
    private windowRef: WindowRefService,
    private titleService: Title,
    private messageService: ToastrService,
    protected userService: UserService,
    protected submissionMixinService: SubmissionDetailMixinService,
    protected route: ActivatedRoute,
    protected elementRef: ElementRef,
    private awardedService: AwardedCredentialDetailService
  ) {
    super(userService, submissionMixinService, elementRef, route);
  }

  ngOnInit() {
    this.submission$.subscribe((submission: Submission) => {
      this.titleService.setTitle(
        `${(<Credential>submission.credential).name} Award - ${
          (<Credential>submission.credential).organization.name
        } | Digital Promise`
      );
    });

    super.ngOnInit();

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const token = params.get("sharetoken");
      if (token) {
        this.awardedService.getShareToken(token).then(
          (shareToken: ShareToken) => {
            const expiresAt = new Date(shareToken.expires_at),
              today = new Date();

            this.shareToken = shareToken;

            if (today > expiresAt) {
              this.expired = true;
              this.messageService.success(Confirmation.RE_SHARE);
            }
          },
          (err: any) => {
            if (err.status === 404) {
              this.messageService.success(Confirmation.RE_SHARE);
            }
          }
        );
      }
    });
  }

  fetchContextObject(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.awardedService
        .getAwardedCredential(params.get("id"))
        .then((awarded: AwardedCredential) => {
          const submission: Submission = <Submission>(
            (<SubmissionRecommendation>(
              (<SubmissionDecision>awarded.decision).recommendation
            )).submission
          );

          this.awardedService
            .getSubmission(submission.readable_id)
            .then((submission: Submission) => {
              this.buildContext(submission);
            });
        });
    });
  }
}
