<div class="forminput-small forminput">
  <div class="dropzone" [ngClass]="disabled == false ? 'dragAreaClass' : 'dragAreaClass dropzone-disabled'" >
    <label for="dropzone" *ngIf="!disabled" class="dropzone-x-label" (click)="file.click()">
      Drag or click to upload attachments <span>(limit 20 MB)</span>
    </label>
    <label for="dropzone" *ngIf="disabled" class="dropzone-x-label dropzone-disabled" >
      Drag or click to upload attachments <span>(limit 20 MB)</span>
    </label>
    <input *ngIf="!disabled"
      type="file"
      #file
      name="dropzone"
      [multiple]="true"
      (change)="onFileChange($event)"
      [disabled]="disabled"
    />
  </div>
</div>

<div
  class="forminput-small forminput l-attachment"
  [ngClass]="{ 'forminput-x-error': hasError }"
>
  <div class="tableattachment">
    <table>
      <thead>
        <tr>
          <th scope="row" class="visuallyhidden">Name</th>
          <th scope="row" class="visuallyhidden">Size</th>
          <th scope="row" class="visuallyhidden">Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr
          data-dig-file-item
          *ngFor="let file of files; let i = index; trackBy: trackByRef"
          [file]="file"
          (remove)="removeFile($event)"
          (view)="viewFile($event, i, digCarousel)"
        ></tr>
      </tbody>
    </table>
  </div>
</div>

<dig-carousel
  [urls]="uploadedFiles"
  [selectedUrlIndex]="selectedFileIndex"
  #digCarousel
></dig-carousel>
