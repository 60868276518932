import { Component, Input, Output, EventEmitter, HostBinding } from "@angular/core";

@Component({
  selector: "app-links-input",
  templateUrl: "./links-input.component.html",
  styleUrls: ["./links-input.component.css"],
})
export class LinksInputComponent {
  showError = false;
  @Input() disabled: boolean = false;
  @Output() url = new EventEmitter();

  private isValidUrl =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/i;
  private hasProtocol = /^[^:]+:\/\//;

  @HostBinding("attr.class")
  get classList() {
    const error = this.showError ? " forminput-x-error" : "";
    return `forminput-x-inlinebox${error}`;
  }

  addLink(link: any) {
    let url = link.value;
    if (this.isValidUrl.test(url) && url.indexOf(" ") === -1) {
      if (!this.hasProtocol.test(url)) {
        url = `http://${url}`;
      }
      this.url.emit(url);
      link.value = null;
      this.showError = false;
      return;
    }
    this.showError = true;
    return false;
  }
}
