<ng-container *ngIf="editing && recommendation && form; else notUpdating">
  <div class="wrap wrap-interactive3">
    <div #rubricContainer class="textinfo textinfo-expanded textinfo-outer">
      <header class="textinfo-x-header">
        <h5>Recommendation Assessment</h5>
      </header>
      <form [formGroup]="form">
        <div
          class="attachment attachment-big attachment-inner wrap attachment-borderblue"
        >
          <div class="textinfo-x-headbutton">
            <app-leveled-recommendation
                    *ngIf="leveled"
                    [credential]="credential"
                    [isRecommended]="isRecommended"
                    [recommendation]="recommendation"
                    [canCreate] = "canCreate"
                    [editing]="editing"
                    [form]="form"
                    [skippedSections]="skippedSections"
                    [isDecision] = "false"
                    ></app-leveled-recommendation>
                    <app-boolean-recommendation
                    *ngIf="!leveled"
                    [isRecommended]="isRecommended"
                    [canCreate] = "canCreate"
                    [recommendation]="recommendation"
                    [editing]="editing"
                    [form]="form"
                    [isDecision] = "false"
                    ></app-boolean-recommendation>
            <div class="textinfo-x-buttonmediabig">
              <div>
                <button
                  *ngIf="showEditButton && !editing"
                  (click)="startUpdateSubmissionRecommendation()"
                  type="button"
                  class="button button-tertiary"
                  style="color: #2578a2"
                >
                  Edit
                </button>
                <button
                  #expandRubric
                  *ngIf="!editing"
                  type="button"
                  class="button button-secondary"
                  aria-labelledby="question"
                  (click)="
                    rubricContainer.classList.toggle('textinfo-is-active')
                  "
                >
                  <ng-container
                    *ngIf="
                      rubricContainer.classList.contains('textinfo-is-active');
                      else expandText
                    "
                    >Collapse Rubric</ng-container
                  >
                  <ng-template #expandText>Expand Rubric</ng-template>
                </button>
              </div>

              <div *ngIf="editing">
                <button
                  (click)="exitUpdateSubmissionRecommendation()"
                  type="button"
                  class="button button-secondary"
                >
                  Cancel
                </button>
                <button
                  (click)="saveUpdateSubmissionRecommendation()"
                  type="button"
                  class="button"
                  style="color: white"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-template #notUpdating>
  <ng-container *ngIf="isRecommended || (container && canCreate)">
    <div
      class="wrap"
      [class.wrap-interactive3]="!isRecommended"
      [class.wrap-accent3]="isRecommended"
    >
      <div #rubricContainer class="textinfo textinfo-expanded textinfo-outer">
        <header class="textinfo-x-header">
          <h5>Recommendation Assessment</h5>
        </header>
       
        <div class="textinfo-x-buttonmediasmall">
          <button
            type="button"
            class="button button-secondary"
            data-toggletext="Collapse Rubric"
            onclick="this.parentElement.parentElement.classList.toggle('textinfo-is-active'); if (this.innerHTML === 'Expand Rubric') {this.innerHTML = this.dataset.toggletext} else {this.innerHTML = 'Expand Rubric'}"
          >
            Expand Rubric
          </button>
        </div>
       
        <div
          class="attachment attachment-big attachment-inner wrap"
          [class.attachment-borderblue]="!isRecommended"
          [class.attachment-borderorange]="isRecommended"
        >
          <div class="textinfo-x-headbutton">
            <div class="textinfo-x-content">
              <div class="textinfo-x-section">
                <div class="forminput-small forminput">
                  <fieldset>
                    <app-leveled-recommendation
                    *ngIf="leveled"
                    [credential]="credential"
                    [isRecommended]="isRecommended"
                    [recommendation]="recommendation"
                    [editing]="editing"
                    [skippedSections]="skippedSections"
                    [canCreate] = "canCreate"
                    [isDecision] = "false"
                    ></app-leveled-recommendation>
                    <app-boolean-recommendation
                    *ngIf="!leveled"
                    [isRecommended]="isRecommended"
                    [editing]="editing"
                    [canCreate] = "canCreate"
                    [recommendation]="recommendation"
                    [isDecision] = "false"
                    ></app-boolean-recommendation>
                  </fieldset>
                </div>
              </div>
            </div>
            <div *ngIf="isRecommended" class="textinfo-x-buttonmediabig">
              <div>
                <button
                  *ngIf="showEditButton && !editing"
                  (click)="startUpdateSubmissionRecommendation()"
                  type="button"
                  class="button button-tertiary"
                  style="color: #2578a2"
                >
                  Edit
                </button>
                <button
                  #expandRubric
                  *ngIf="!editing"
                  type="button"
                  class="button button-secondary"
                  aria-labelledby="question"
                  (click)="
                    rubricContainer.classList.toggle('textinfo-is-active');
                    $event.target.innerText =
                      $event.target.innerText === 'Expand Rubric'
                        ? 'Collapse Rubric'
                        : 'Expand Rubric'
                  "
                >
                  Expand Rubric
                </button>
              </div>

              <div *ngIf="editing">
                <button
                  (click)="exitUpdateSubmissionRecommendation()"
                  type="button"
                  class="button button-secondary"
                >
                  Cancel
                </button>
                <button
                  (click)="saveUpdateSubmissionRecommendation()"
                  type="button"
                  class="button"
                  style="color: white"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="comments && commentsInfo && showRecommendationComment"
    class="wrap"
    [class.wrap-interactive3]="!isRecommended"
    [class.wrap-accent3]="isRecommended"
  >
    <app-submission-comments
      [data]="comments"
      [info]="commentsInfo"
      clsWrap="textblock textinfo-outer"
    >
      <h4 style="margin-top: 0">Comments for Issuer Only:</h4>
    </app-submission-comments>
  </div>
</ng-template>
