import { Injectable } from '@angular/core';
import { Site } from '../site.type';
import { SiteService } from './site.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SitetypeService  {
  private siteType: string = 'MCP';
  
  siteType$: BehaviorSubject<String> = new BehaviorSubject(null);

  private types = {
    'MCP': {
      'credential': 'Micro-credential',
      'certification': 'Micro-credential'
    },
    'PCP': {
      'credential': 'Certification',
      'certification': 'Product Certification'
    }
  }
  constructor(
    private siteService: SiteService) {
      this.siteService.site$.subscribe((site: Site) => {
        if (site && site.siteprofile) {
          this.siteType = site.siteprofile.site_type || 'MCP';
          this.siteType$.next(this.siteType);
        }
      });
     }

  getCredentialsName() : String {
    if (!this.siteType) return '';
    return this.types[this.siteType].credential;
  }

  getCredentialsCertification(): string {
    if (!this.siteType) return '';
    return this.types[this.siteType].certification;
  }
}
