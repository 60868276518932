<div class="forminput-x-inlineinput">
  <input
    type="text"
    name="links"
    placeholder="http://example.com"
    #url
    (keydown.enter)="addLink(url)"
    [disabled]="disabled"
  />
  <button class="button" type="button" (click)="addLink(url)" [disabled]="disabled">
    <span class="button-x-text">Add Link</span>
  </button>
</div>
<div class="forminput-x-inlineerror" *ngIf="showError">
  <p class="forminput-x-errortext">Invalid URL</p>
</div>
