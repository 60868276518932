import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ApiGetService } from "./shared/core/apiget.service";
import { ConfigService } from "./shared/core/config.service";

@Component({
  template: `
    <form
      action=""
      class="form l-form l-containerhorizontal"
      role="form"
      [formGroup]="form"
      (ngSubmit)="post()"
    >
      <div class="form-x-content">
        <div class="form-x-inputs">
          <fieldset>
            <legend class="formlegend">Contact Information</legend>
            <div class="forminput">
              <label for="amount">Amount</label>
              <div class="forminput-x-input">
                <input
                  type="number"
                  id="amount"
                  formControlName="amount"
                  placeholder="0.00"
                />
              </div>
            </div>
            <div class="forminput">
              <label for="description">Description</label>
              <div class="forminput-x-input">
                <input
                  type="text"
                  id="description"
                  formControlName="description"
                  placeholder="description"
                />
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <button type="submit">Submit</button>
    </form>
    <div>Results: {{ result | json }}</div>
  `,
})
export class StripeTestComponent implements OnInit {
  form: UntypedFormGroup;
  result = {};

  constructor(private api: ApiGetService, private config: ConfigService) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      amount: new UntypedFormControl(),
      description: new UntypedFormControl(),
      token: new UntypedFormControl(),
    });
  }

  post() {
    const handler = (<any>window).StripeCheckout.configure({
      key: this.config.getConfig("stripePublicKey"),
      locale: "auto",
      token: (token: any) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        this.form.patchValue({
          token: token.id,
        });
        console.log(this.form.value);
        this.api
          .post("test-charge/", this.form.value)
          .then((r) => (this.result = r));
      },
    });

    handler.open({
      name: "Digital Promise",
      description: this.form.get("description").value,
      image: "https://stripe.com/img/documentation/checkout/marketplace.png",
      amount: this.form.get("amount").value * 100,
    });
  }
}
