/* eslint-disable curly */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";

import { EditCredentialService } from "./edit-credential.service";
import { UserService } from "../core/user.service";
import { ConfigService } from "../core/config.service";

import { Credential } from "../credential.type";
import { User } from "../user.type";
import { Confirmation } from "../messages.constants";
import { HistoryData } from "../history.type";
import { Subscription } from "rxjs";
import { ModalService } from "../core/modal.service";

export function minOrZero(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isEmptyInputValue = (value) => value == null || value.length === 0;
    if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
      return null; // don't validate empty values to allow optional controls
    }
    const value = parseFloat(control.value);
    // Controls with NaN values after parsing should be treated as not having a
    // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
    const isNotMinOrZero = !isNaN(value) && value < min && value !== 0;
    return isNotMinOrZero
      ? { minOrZero: { min: min, actual: control.value } }
      : null;
  };
}

export function lessThanPrice(form: UntypedFormGroup): ValidationErrors | null {
  const price = parseFloat(form.get("price").value),
    resubmission_price = parseFloat(form.get("resubmission_price").value);
  if (resubmission_price > price) {
    return { lessThanPrice: { max: price || 0.0, actual: resubmission_price } };
  }
}

@Component({
  selector: "app-edit-credential",
  templateUrl: "./edit-credential.component.html",
  styleUrls: ["./edit-credential.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCredentialComponent implements OnInit, OnDestroy {
  credential: Credential;
  credentialForm: UntypedFormGroup;
  user: User;
  initialCredentialAvailability = "";
  noAssessment = false;
  historyData: Promise<HistoryData[]>;
  minPrice = 3.0;
  currentCredentialSub: Subscription;
  modalIsOpenSub: Subscription;
  modalIsOpen: boolean;
  @ViewChild("ngxFocus", { static: true }) focusTrap;

  // Back button pressed.
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.closeDialog();
  }

  constructor(
    protected credentialService: EditCredentialService,
    private modalService: ModalService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private configService: ConfigService
  ) {
    this.minPrice = parseFloat(
      this.configService.getConfig("minimumCredentialPrice", this.minPrice)
    );

    this.credentialForm = this.fb.group(
      {
        price: [
          null,
          Validators.compose([Validators.required, minOrZero(this.minPrice)]),
        ],
        resubmission_price: [
          null,
          Validators.compose([Validators.required, minOrZero(this.minPrice)]),
        ],
        availability: "",
      },
      { validator: lessThanPrice }
    );
  }

  closeDialog() {
    this.modalService.anyModalIsOpen.next(false);
    this.modalIsOpen = false;
    this.credentialService.editCredentialModalisOpen.next(false);
    this.resetForm();
    this.focusTrap.deactivateFocusTrap();
  }

  onSubmit() {
    this.credentialService
      .updateCredential(this.credential.slug, this.credentialForm.value)
      .then(
        (res: Credential) => {
          this.credentialService.updateList.next(
            Confirmation.CREDENTIAL("updated")
          );
          this.credentialService.credentialUpdated$.next(res);
          this.closeDialog();
          this.resetForm();
        },
        (err) => {
          const errors = err.error;
          for (const key of Object.keys(errors)) {
            this.credentialService.errorMessage.next(errors[key][0]);
          }
          this.closeDialog();
          this.resetForm();
        }
      );
  }

  resetForm() {
    this.credentialForm.reset({
      price: null,
      resubmission_price: null,
      availability: "",
    });
  }

  private setCurrentCredential(credential: any) {
    if (credential) {
      this.noAssessment = credential.extension ? false : true;
      this.credential = credential;
      this.initialCredentialAvailability = credential.availability;
      // mock history data
      this.historyData = this.credentialService.getCredentialHistory(
        credential.id
      );
      this.credentialForm.patchValue({
        price: credential.price,
        resubmission_price: credential.resubmission_price,
        availability: credential.availability,
      });
    }
  }

  ngOnInit() {
    this.currentCredentialSub = this.credentialService.selectedCredential
      .asObservable()
      .subscribe((credential) => {
        this.setCurrentCredential(credential);
        this.user = this.userService.user;
      });

    this.modalIsOpenSub = this.credentialService.editCredentialModalisOpen
      .asObservable()
      .subscribe((value) => {
        this.modalIsOpen = value;

        if (this.modalIsOpen) {
          this.focusTrap.activateFocusTrap();
        }
      });
  }

  ngOnDestroy() {
    this.currentCredentialSub.unsubscribe();
  }
}
