<form #form="ngForm" class="textblock list">
  <div class="forminput">
    <div class="forminput-x-input">
      <quill-editor
        [(ngModel)]="newComment"
        [styles]="{ height: '120px' }"
        name="new-comment"
        class="submission-comment-editor"
        placeholder="Enter your comment..."
      ></quill-editor>
    </div>
  </div>
  <button
    class="button button--right"
    [disabled]="!newComment"
    (click)="onSubmit()"
  >
    Comment
  </button>
</form>
