import { distinctUntilChanged, filter } from "rxjs/operators";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

import { User } from "./shared/user.type";

import { ConfigService } from "./shared/core/config.service";
import { UserService } from "./shared/core/user.service";
import { SiteService } from "./shared/core/site.service";
import { WindowRefService } from "./shared/core/window-ref.service";
import { ModalService } from "./shared/core/modal.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  apiHost: string;
  user: User;
  desktopDropdown = false;
  mobileMenu = false;
  @ViewChild("ngxFocus", { static: true }) focusTrap;

  noSite = false;
  @ViewChild("main", { static: true }) main: ElementRef;
  modalOpen = false;

  @HostListener("click")
  onHostClick() {
    this.desktopDropdown = false;
  }

  constructor(
    private userService: UserService,
    private siteService: SiteService,
    private configService: ConfigService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private windowService: WindowRefService,
    private modalService: ModalService
  ) {}

  hideNav() {
    this.siteService.error$.subscribe((error: boolean) => {
      if (error) {
        setTimeout(() => (this.noSite = true));
      }
    });
  }

  openMobileMenu() {
    this.mobileMenu = !this.mobileMenu;

    if (this.mobileMenu) {
      this.focusTrap.activateFocusTrap();
    }
  }

  ngOnInit() {
    this.apiHost = this.configService.getConfig("apiHost");
    this.userService.user$.subscribe((user: User) => (this.user = user));

    this.hideNav();

    this.router.events
      .pipe(
        filter((navigationEvent) => navigationEvent instanceof NavigationEnd)
      )
      .subscribe((navigationEvent) => {
        // Traverse the router tree to get the furthest leaf,
        // which will be the current route.
        let currentLeaf = this.route, // The root, AppComponent route
          latestTitle, // Any title we find along the way
          titlePrefix;
        while (currentLeaf.firstChild) {
          currentLeaf = currentLeaf.firstChild;
          latestTitle = currentLeaf.snapshot.data.title;
          if (latestTitle) {
            titlePrefix = latestTitle;
          }
        }

        if (titlePrefix) {
          this.titleService.setTitle(`${titlePrefix} | Digital Promise`);
        } else {
          this.titleService.setTitle("Digital Promise");
        }
      });

    // Send tracking events to Google Analytics and to Pardot
    this.router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          // Subscribe to any `NavigationEnd` events where the url has changed
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })
      )
      .subscribe((x: any) => {
        // GA
        const dataLayer = this.windowService.nativeWindow.dataLayer;
        if (dataLayer) {
          dataLayer.push({ event: "routeChange" });
        }

        // Pardot
        const piTracker = this.windowService.nativeWindow.piTracker;
        if (piTracker) {
          const url = this.windowService.nativeWindow.location;
          console.log(`log Pardot event ${url}`);
          piTracker(url);
        }
      });

    this.modalService.anyModalIsOpen.asObservable().subscribe((modalOpen) => {
      this.modalOpen = modalOpen;
    });
  }

  userCanAssess() {
    return this.user && this.user.is_assessor;
  }

  userCanReview() {
    return this.user && this.user.is_issuer;
  }

  nameFormat(user: User) {
    if (user) {
      return user && `${user.first_name} ${user.last_name}`;
    }
  }

  signOut() {
    this.userService.logout();
  }

  openTabTo(href: string) {
    this.windowService.nativeWindow.open(href, "_blank");
    return false;
  }

  skipToContent() {
    this.main.nativeElement.focus();
  }
}
