import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

import { HistoryData } from "../history.type";

@Component({
  selector: "app-dialog-history",
  templateUrl: "./dialog-history.component.html",
  styleUrls: ["./dialog-history.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHistoryComponent {
  @Input() entries: HistoryData[] = [];
}
