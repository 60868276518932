import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModule } from "../shared/shared.module";

import { AwardedCredentialDetailRoutingModule } from "./awarded-credential-detail-routing.module";
import { AwardedCredentialDetailComponent } from "./awarded-credential-detail.component";
import { AwardedCredentialDetailService } from "./awarded-credential-detail.service";

@NgModule({
  imports: [CommonModule, SharedModule,  AwardedCredentialDetailRoutingModule],
  declarations: [AwardedCredentialDetailComponent],
  providers: [AwardedCredentialDetailService],
})
export class AwardedCredentialDetailModule {}
