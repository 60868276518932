import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { Submission } from "../shared/submission.type";
import { UserService } from "../shared/core/user.service";
import { Credential } from "../shared/credential.type";

import { SubmissionDetailMixinComponent } from "../shared/submission-detail-mixin/submission-detail-mixin.component";
import { SubmissionDetailMixinService } from "../shared/submission-detail-mixin/submission-detail-mixin.service";

@Component({
  selector: "app-submission-detail",
  templateUrl: "./submission-detail.component.html",
  styleUrls: ["./submission-detail.component.css"],
})
export class SubmissionDetailComponent
  extends SubmissionDetailMixinComponent
  implements OnInit
{
  constructor(
    protected userService: UserService,
    private titleService: Title,
    protected submissionMixinService: SubmissionDetailMixinService,
    protected elementRef: ElementRef,
    protected route: ActivatedRoute
  ) {
    super(userService, submissionMixinService, elementRef, route);
  }

  ngOnInit() {
    this.submission$.subscribe((submission: Submission) => {
      this.titleService.setTitle(
        `${(<Credential>submission.credential).name} Application Submission - ${
          (<Credential>submission.credential).organization.name
        } | Digital Promise`
      );
    });
    super.ngOnInit();
  }
}
