import { BehaviorSubject } from "rxjs";

import { Credential } from "../credential.type";
import { Submission } from "../submission.type";
import { User } from "../user.type";
import { SubmissionRecommendationRubric } from "../submission-recommendation-rubric.type";
import { Pagination } from "../pagination.type";

import { SubmissionDetailMixinService } from "../submission-detail-mixin/submission-detail-mixin.service";

export class PreviousRecommendationRubricParentMixin {
  previousSubmissions: Submission[];
  deniedSubmission: Submission;
  deniedSubmission$: BehaviorSubject<Submission> = new BehaviorSubject(
    undefined
  );
  previousRubricRecommendations: SubmissionRecommendationRubric[];

  constructor(protected submissionMixinService: SubmissionDetailMixinService) {}

  getPreviousRecommendationRubric(
    credential: Credential,
    user: User | void,
    shareToken: string | void = null,
    endpointPermission: string = "educator"
  ) {
    this.submissionMixinService
      .fetchSubmissions(
        null,
        {
          ordering: "-status_date",
          credential__slug: credential.slug,
          user__email: user && user.email,
        },
        false,
        endpointPermission
      )
      .then((previousSubmissions: Pagination) => {
        this.previousSubmissions = previousSubmissions.results;

        let deniedSubmission;
        for (const previousSubmission of previousSubmissions.results) {
          if (previousSubmission.status === "Denied") {
            deniedSubmission = previousSubmission;
            this.deniedSubmission = deniedSubmission;
            this.deniedSubmission$.next(deniedSubmission);
            break;
          }
        }

        if (deniedSubmission) {
          return this.submissionMixinService
            .getRubricRecommendations(
              deniedSubmission.readable_id,
              shareToken || ""
            )
            .then(
              (
                previousRubricRecommendations: SubmissionRecommendationRubric[]
              ) => {
                this.previousRubricRecommendations =
                  previousRubricRecommendations;
                return previousRubricRecommendations;
              }
            );
        }
      });
  }
}
