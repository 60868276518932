import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CredentialDetailRoutingModule } from "./credential-detail-routing.module";
import { CredentialDetailComponent } from "./credential-detail.component";
import { CredentialDetailService } from "./credential-detail.service";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [CommonModule, SharedModule, CredentialDetailRoutingModule],
  providers: [CredentialDetailService],
  declarations: [CredentialDetailComponent],
})
export class CredentialDetailModule {}
