/* eslint-disable curly */
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  HostBinding,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { ToastrService } from "ngx-toastr";

import { User } from "../shared/user.type";
import { UserService } from "../shared/core/user.service";
import {
  AssessmentExtension,
  Assessment,
  AssessmentSection,
  AssessmentQuestion,
} from "../shared/assessment.type";
import { Credential } from "../shared/credential.type";
import { Submission } from "../shared/submission.type";
import { SubmissionFile } from "../shared/submission-file.type";
import { SubmissionText } from "../shared/submission-text.type";
import { Draft, DraftText, DraftUrl, DraftFile } from "../shared/draft.type";

import { SubmissionDetailMixinComponent } from "../shared/submission-detail-mixin/submission-detail-mixin.component";
import { SubmissionDetailMixinService } from "../shared/submission-detail-mixin/submission-detail-mixin.service";

import { SubmissionCreateService } from "./submission-create.service";
import { Confirmation } from "../shared/messages.constants";
import { ConfigService } from "../shared/core/config.service";
import { WindowRefService } from "../shared/core/window-ref.service";
import { ProgressPageService } from "../progress-page/progress-page.service";

import { LoadingOverlayService } from "../shared/loading-overlay/loading-overlay.service";
import { PardotService } from "../shared/pardot.service";

function requiredIfResubmission(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (this.deniedSubmission) {
      if (!control.value || !control.value.length) {
        return { mustProvideResubmissionNote: {} };
      }
    }
  };
}

function requireAnswerForAllAssessmentQuestions(): ValidatorFn {
  /*
   * For every FileQuestion, require it has a value in any 'url' or 'file'
   * FormArray control
   */

  return (control: AbstractControl): { [key: string]: any } => {
    if (!this.fileQuestionIndexes) {
      return;
    }
    
    const urlsFormArray = control["controls"].urls.controls;
    const filesFormArray = control["controls"].files.controls;

    const fileQuestionIds = Object.keys(this.fileQuestionIndexes).map(
      (assessmentQuestionId) => parseInt(assessmentQuestionId, 10)
    );

    const allFileQuestionsAnswered = fileQuestionIds
      // filter out skipped sections for validation
      .filter(id => !this.skippedSections.find(ss => ss.questions.map(q => q.id).includes(id)))
      .every(
        (assessmentQuestionId: number) => {

          const assessmentQuestionInUrls = urlsFormArray.some(
            (formGroup: UntypedFormGroup) => {
              return (
                formGroup.controls.assessment_question.value ===
                assessmentQuestionId
              );
            }
          );
          const assessmentQuestionInFiles = filesFormArray.some(
            (formGroup: UntypedFormGroup) => {
              return (
                formGroup.controls.assessment_question.value ===
                assessmentQuestionId
              );
            }
          );

          return assessmentQuestionInUrls || assessmentQuestionInFiles;
        }
      );

    if (!allFileQuestionsAnswered) {
      return { fileQuestionsNotAnswered: {} };
    }
  };
}

@Component({
  selector: "app-submission-create",
  templateUrl: "./submission-create.component.html",
  styleUrls: ["./submission-create.component.css"],
})
export class SubmissionCreateComponent
  extends SubmissionDetailMixinComponent
  implements OnInit, OnDestroy {
  @HostBinding("attr.class") className = "l-detail";

  credential: Credential;
  submissionForm: UntypedFormGroup;
  draft: Draft;
  draftWarning = true;

  user: User;
  assessmentExtension: AssessmentExtension;
  allSections: AssessmentSection[] = [];
  skippedSections: AssessmentSection[] = [];
  showSections: AssessmentSection[] = [];
  fileQuestionIndexes: { [questionId: string]: number } = {};
  textQuestionIndexes: { [questionId: string]: number } = {};

  purchaseModalIsOpen = false;
  submissionFiles: SubmissionFile[] = [];

  unloadListener: any;

  constructor(
    private router: Router,
    private titleService: Title,
    protected submissionMixinService: SubmissionDetailMixinService,
    protected userService: UserService,
    protected elementRef: ElementRef,
    protected route: ActivatedRoute,
    protected progressPageService: ProgressPageService,
    private configService: ConfigService,
    private pardotService: PardotService,
    private fb: UntypedFormBuilder,
    private loaderService: LoadingOverlayService,
    private messageService: ToastrService,
    private submissionCreateService: SubmissionCreateService,
    private windowService: WindowRefService
  ) {
    super(userService, submissionMixinService, elementRef, route);
  }

  ngOnInit() {
    this.submission$.subscribe((submission: Submission) => {
      this.titleService.setTitle(
        `${(<Credential>submission.credential).name} Application Submission - ${(<Credential>submission.credential).organization.name
        } | Digital Promise`
      );
    });

    this.unloadListener = (ev) => {
      if (this.hasUnsavedDraftChange()) {
        ev.returnValue = "";
        ev.preventDefault();
        return "";
      }
    };

    this.windowService.nativeWindow.addEventListener(
      "beforeunload",
      this.unloadListener
    );

    super.ngOnInit();
  }

  preventDraftWarning() {
    this.draftWarning = false;
  }

  ngOnDestroy() {
    this.windowService.nativeWindow.removeEventListener(
      "beforeunload",
      this.unloadListener
    );
  }

  fetchContextObject(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get("slug");
      this.credential = this.route.snapshot.data["credential"];

      if (this.user) {
        this.getPreviousRecommendationRubric(this.credential, this.user);
      }

      const getDraft$ = this.submissionCreateService
        .getDraft(this.credential.id)
        .catch((e) => { });

      const assessmentExtension = (<Credential>this.credential).extension;
      const fetchAssessmentExtension$ = this.fetchAssessmentExtension(
        (<AssessmentExtension>assessmentExtension).id
      );

      Promise.all([getDraft$, fetchAssessmentExtension$]).then(
        ([draft, assessmentExtension]: [Draft, AssessmentExtension]) => {
          this.initializeForm();
          if (draft) {
            this.draft = draft;
            this.populateDraft(this.draft);
          }
        }
      );
    });
  }

  instantiateForm() {
    this.submissionForm = this.fb.group(
      {
        credential: "",
        user: "",
        texts: this.fb.array([]),
        files: this.fb.array([]),
        urls: this.fb.array([]),
        resubmission_note: "",
        resubmission: "",
        confirm_complete: [false, Validators.requiredTrue],
        confirm_lawful: [false, Validators.requiredTrue],
        confirm_integrity: [false, Validators.requiredTrue],
        stripe_token: "",
        coupon: "",
      },
      { validator: requireAnswerForAllAssessmentQuestions.call(this) }
    );

    this.deniedSubmission$.subscribe((deniedSubmission: Submission) => {
      if (deniedSubmission) {
        this.submissionForm.patchValue({ resubmission: deniedSubmission.id });
      }
    });
  }

  refreshIndexes() {
    this.fileQuestionIndexes = this.buildIndexMap("FileQuestion");
    this.textQuestionIndexes = this.buildIndexMap("TextQuestion");
    this.submissionForm.markAsDirty();
  }

  initializeForm() {
    const textEvidenceGroups: UntypedFormGroup[] = [];

    this.submissionForm.patchValue({
      credential: this.credential.id,
      user: (this.user && this.user.id) || null,
    });

    this.refreshIndexes();

    for (const assessmentQuestionId in this.textQuestionIndexes) {
      textEvidenceGroups.push(
        this.fb.group({
          assessment_question: assessmentQuestionId,
          text: ["", Validators.required],
        })
      );
    }

    this.submissionForm.setControl("texts", this.fb.array(textEvidenceGroups));
  }

  hasUnsavedDraftChange() {
    const formValue = this.submissionForm.value;
    const draft = this.draft;

    // Don't warn us if we've stifled the warning
    if (!this.draftWarning) {
      return false;
    }

    // If there's no draft at all, ask to save.
    if (!draft) {
      return true;
    }

    // If there's a draft, but its values don't match the form, ask to save.
    const textsSame = formValue.texts
      .map((textEvidence, index) => {
        const draftText = draft.texts[index];
        return draftText && textEvidence.text === draftText.text;
      })
      .every((areEqual) => areEqual);

    const urlsSame = formValue.urls
      .map((urlEvidence, index) => {
        const draftUrl = draft.urls[index];
        return draftUrl && urlEvidence.url === draftUrl.url;
      })
      .every((areEqual) => areEqual);

    const filesSame = formValue.files
      .map((fileEvidence, index) => {
        const draftFile = draft.files[index];
        return (
          draftFile && fileEvidence.file === draftFile.submission_file.file
        );
      })
      .every((areEqual) => areEqual);

    const skippedSectionsSame = formValue.skipped_sections
      .map((ss, index) => {
        const draftSkipSection = draft.skipped_sections[index];
        return (
          draftSkipSection && ss.id === draftSkipSection.id
        )
      })
      .every((areEqual) => areEqual);

    const haveChanges = ![textsSame, urlsSame, filesSame, skippedSectionsSame].every(
      (areEqual) => areEqual
    );

    return haveChanges;
  }

  populateDraft(draft: Draft) {
    this.submissionForm.setControl(
      "urls",
      this.fb.array(
        draft.urls.map((url: DraftUrl) =>
          this.fb.group({
            assessment_question: url.assessment_question,
            url: url.url,
          })
        )
      )
    );

    this.submissionForm.setControl(
      "files",
      this.fb.array(
        draft.files.map((draftFile: DraftFile) =>
          this.fb.group(draftFile.submission_file)
        )
      )
    );

    this.submissionForm.patchValue({
      texts: draft.texts.map((text: DraftText) => {
        if (text.text == "…") text.text = "";
        return text;
      }),
    });

    this.submissionForm.setControl(
      "skipped_sections",
      this.fb.array(this.skippedSections)
    );
  }

  buildIndexMap(questionType): { [questionId: number]: number } {
    let indexMap = {},
      index = 0;

    this.assessmentExtension.assessments.forEach((assessment: Assessment) =>
      assessment.sections.forEach((section: AssessmentSection) =>
        section.questions.forEach((question: AssessmentQuestion) => {
          if (questionType) {
            if (question.question_type === questionType) {
              indexMap[question.id] = index++;
            }
          } else {
            indexMap[question.id] = index++;
          }
        })
      )
    );

    return indexMap;
  }

  closePurchaseModal(couponCode: string) {
    this.purchaseModalIsOpen = false;
    if (couponCode) {
      this.submissionForm.patchValue({
        stripe_token: "",
        coupon: couponCode,
      });
      this.createSubmission();
    }
  }

  createSubmission() {
    this.submissionForm.setControl("skipped_sections", this.fb.array(this.skippedSections));
    if (this.submissionForm.valid) {
      const loadingToken = this.loaderService.startLoading();
      this.submissionCreateService
        .postSubmission(this.submissionForm.value)
        .then(() => this.userService.refreshUser())
        .then((user: User) => this.pardotService.postPardot(user))
        .then(
          () => {
            this.messageService.success(Confirmation.SUBMISSION);
            this.loaderService.doneLoading(loadingToken);
            this.preventDraftWarning();
            this.router.navigate(["dashboard", "all", "all"]);
          },
          (err) => {
            if (err.status === 400) {
              const errors = err.error;
              for (const key of Object.keys(errors)) {
                this.messageService.error(errors[key][0], "");
              }
            }
            this.loaderService.doneLoading(loadingToken);
          }
        );
      return false;
    }
  }

  hasEvidence() {
    let pruneEmptyTextEvidence = (): SubmissionText[] => {
      return this.submissionForm.value.texts.filter(
        (textEvidence: SubmissionText) => textEvidence.text
      );
    };

    let formData = this.submissionForm.value;
    const texts = pruneEmptyTextEvidence();

    return texts && formData.files && formData.urls;
  }

  validateTexts() {
    const textControls = this.submissionForm.get("texts")['controls'];

    // check if each text control falls under a skipped section
    // disable if it is skipped, enable otherwise
    textControls.forEach(c => {
      const disabled = this.skippedSections.find(ss => ss.questions.map(q => q.id.toString()).includes(c.value.assessment_question))
      if (disabled) {
        c.disable();
      } else {
        c.enable();
      }
    })
  }

  toggleSectionSkipped(
    section: AssessmentSection): any {
    const higherLevelSections = this.allSections.filter(s => s !== section && s.leveled_badge[0]?.level > section.leveled_badge[0]?.level);

    if (!this.getSectionSkipped(section)) {
      // add section to list if not already there
      this.skippedSections?.push(section);
      this.showSections = this.showSections?.slice(0, this.showSections?.indexOf(section));

      // add any sections with higher badge level to skipped list as well
      higherLevelSections.map(s => {
        if (!this.getSectionSkipped(s)) {
          this.skippedSections?.push(s);
        }
      });
    } else {
      // remove section from list if already there 
      // (and any higher level skipped sections as well)
      this.skippedSections = this.skippedSections?.slice(0, this.skippedSections?.indexOf(section));
      !this.getSectionShow(section) ?? this.showSections?.push(section);
    }

    // sort the skipped sections array by level (ascending)
    this.skippedSections = this.skippedSections.sort((a, b) => {
      const levelA = a.leveled_badge[0]?.level;
      const levelB = b.leveled_badge[0]?.level;

      if (levelA > levelB) {
        return 1;
      } else if (levelA < levelB) {
        return -1;
      } else {
        return 0;
      }
    });

    this.validateTexts();
    this.refreshIndexes();
  }

  toggleSectionShow(
    section: AssessmentSection): any {
    if (this.getSectionShow(section)) {
      this.showSections = this.showSections?.slice(0, this.showSections?.indexOf(section))
    } else {
      this.showSections?.push(section);
    }

    this.refreshIndexes();
  }

  getSectionSkipped(
    section: AssessmentSection): any {
    return (this.skippedSections?.indexOf(section) > -1)
  }

  getSectionLocked(
    section: AssessmentSection): any {
    return (this.skippedSections?.indexOf(section) > 0)
  }

  getSectionDisabled(
    section: AssessmentSection): any {
      return (this.skippedSections?.indexOf(section,1) > -1);
  }

  getSectionShow(
    section: AssessmentSection): any {
    return (this.showSections?.indexOf(section) >= 0)
  }

  getQuestionEvidence(
    assessment_question: number,
    evidences: any,
    multiple = false,
    property: string
  ): any | any[] {
    let evidence = (evidences || []).filter(
      (evidence: any) => evidence.assessment_question === assessment_question
    );

    if (property) {
      evidence = evidence.map((evidence) => evidence[property]);
    }

    if (!multiple) return evidence.pop();
    else return evidence;
  }

  createDraft() {
    const formData = Object.assign({}, this.submissionForm.value);
    formData.texts = formData.texts.map((textEvidence: SubmissionText) => {
      return {
        assessment_question: textEvidence.assessment_question,
        text: !textEvidence.text ? "…" : textEvidence.text,
      };
    });

    const loadingToken = this.loaderService.startLoading();
    this.submissionCreateService.postDraft(formData).then(
      (draft: Draft) => {
        this.draft = draft;
        this.populateDraft(draft);
        this.messageService.success("Draft saved.");
        this.loaderService.doneLoading(loadingToken);
      },
      (err) => {
        this.messageService.error("Server error; unable to save draft.", "");
        this.loaderService.doneLoading(loadingToken);
      }
    );
    return false;
  }

  getPrice() {
    if (this.deniedSubmission) {
      return this.credential.resubmission_price;
    } else {
      return this.credential.price;
    }
  }

  showStripeCheckout() {
    const loadingToken = this.loaderService.startLoading();
    const handler = this.windowService.nativeWindow.StripeCheckout.configure({
      key: this.configService.getConfig("stripePublicKey"),
      locale: "auto",
      token: (token: any) => {
        this.submissionForm.patchValue({
          stripe_token: token.id,
          coupon: "",
        });
        this.createSubmission();
      },
      closed: () => {
        this.loaderService.doneLoading(loadingToken);
      },
    });

    handler.open({
      name: "Digital Promise",
      description: this.credential.name,
      // image: this.credential.image,
      image: "/assets/dp_logo.png",
      amount: parseFloat(this.getPrice()) * 100,
    });
  }

  removeUpload(submissionFile: SubmissionFile) {
    const removedFileIndex = this.submissionFiles.indexOf(submissionFile);

    if (removedFileIndex !== -1) {
      this.submissionFiles.splice(removedFileIndex, 1);
    }
  }

  handleUpload(submissionFile: SubmissionFile) {
    this.submissionFiles.push(submissionFile);

    this.submissionForm.setControl(
      "files",
      this.fb.array(
        this.submissionFiles.map((submissionFile: SubmissionFile) =>
          this.fb.group(submissionFile)
        )
      )
    );
  }
}
