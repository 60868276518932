<div
  *ngIf="question.rubric"
  class="attachment wrap wrap-light2"
  [class.previous-was-wrong]="previousRecommendation?.implies_not_passing"
>
  <div class="textinfo">
    <header class="textinfo-x-header">
      <h5>
        Assessment Rubric (<button
          type="button"
          data-toggletext="Hide"
          onclick="this.parentElement.parentElement.parentElement.classList.toggle('textinfo-is-active'); if (this.innerHTML === 'Show') {this.innerHTML = this.dataset.toggletext} else {this.innerHTML = 'Show'}"
        >
          Show</button
        >)
      </h5>
    </header>
    <div class="textinfo-x-content">
      <div class="textinfo-x-hidden l-hidden" tabindex="-1">
        <ng-container
          *ngFor="
            let criterion of question.rubric.criterions;
            let criterionIndex = index
          "
        >
          <div class="textinfo-x-section">
            <h3>
              Section {{ criterionIndex + 1
              }}<ng-container *ngIf="criterion.description">: </ng-container
              ><span *ngIf="criterion.description">
                {{ criterion.description }}</span
              >
            </h3>
            <ng-container
              *ngFor="let criterionLevel of criterion.criterion_levels"
            >
              <h4>{{ criterionLevel.quality }}</h4>
              <p [innerHTML]="criterionLevel.description | newlineToBr"></p>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
