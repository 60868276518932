import { Component } from "@angular/core";
import { CommentAction } from "../comment.type";
import { SubmissionCommentBaseComponent } from "./submission-comment-base.component";

@Component({
  selector: "app-submission-comment-new",
  templateUrl: "./submission-comment-new.component.html",
  styleUrls: ["./submission-comment-base.component.css"],
})
export class SubmissionCommentNewComponent extends SubmissionCommentBaseComponent {
  editMode: CommentAction = CommentAction.add;
}
