<input
  [value]="currentViewValue"
  type="text"
  (keyup)="onInputChange($event)"
  (blur)="onInputBlur(searchInput)"
  (focus)="onInputFocus(searchInput)"
  [placeholder]="searchPlaceholder"
  autocomplete="new-password"
  [disabled]="disableSearch ? true : null"
  #searchInput
/>
<div class="search-container" *ngIf="isOpen">
  <div class="search-box" #searchBox>
    <ng-container *ngIf="options.length > 0; else emptyState">
      <a
        (click)="handleOptionClicked(option)"
        *ngFor="let option of options; let i = index"
        [class.active]="isActive(option, i)"
        class="searchOption"
        #localSearchOption
      >
        <ng-container
          *ngTemplateOutlet="
            searchOption.template;
            context: { model: option, isActive: isActive(option, i) }
          "
        ></ng-container>
      </a>
    </ng-container>

    <ng-template #emptyState>
      <ng-container
        *ngTemplateOutlet="searchEmptyState.template"
      ></ng-container>
    </ng-template>
  </div>
</div>
