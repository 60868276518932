import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
} from "@angular/core";

import { SubmissionRecommendation } from "../../submission-recommendation.type";
import { SubmissionDecision } from "../../submission-decision.type";
import { Submission } from "../../submission.type";
import {
  Comment,
  CommentInfo,
  CommentType,
  ContentType,
} from "app/shared/comment.type";
import { AssessmentExtension } from "app/shared/assessment.type";
import { UntypedFormGroup } from "@angular/forms";
import { SubmissionRecommendationRubric } from "app/shared/submission-recommendation-rubric.type";

@Component({
  selector: "app-section-recommend-and-decide",
  templateUrl: "./section-recommend-and-decide.component.html",
  styleUrls: ["./section-recommend-and-decide.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SectionRecommendAndDecideComponent implements OnInit {
  commentsInfo: CommentInfo;
  leveled = false;
  @Input() parentForm: UntypedFormGroup;
  @Input() assessmentExtension: AssessmentExtension;
  @Input() submission: Submission;
  @Input() rubricRecommendations: SubmissionRecommendationRubric[];
  @Input() recommendation: SubmissionRecommendation;
  @Input() decision: SubmissionDecision;
  @Input() canRecommend = false;
  @Input() canDecide = false;
  @Input() showEditButton = false;
  @Input() buttonText = "Submit";
  @Input() canShowRecommendation = false;
  @Input() showRecommendationComment = false;
  @Input() formValid = false;
  @Input() ownAssess = false;
  @Input() comments: Comment[];
  @Output() updated: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() notEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() requestAdditionalAssessment: EventEmitter<boolean> =
    new EventEmitter();

  ngOnInit() {
    this.commentsInfo = <CommentInfo>{
      commentType: CommentType.ISSUER,
      contentType: ContentType.submission,
      pk: this.submission.id,
      submission: this.submission,
    };
    if(typeof this.submission.credential !== 'number'){
      this.leveled = this.submission.credential?.apply_only;
    }
  }

  emitUpdated() {
    this.updated.emit(true);
  }

  emitIsEditing() {
    this.isEditing.emit(true);
  }

  emitNotEditing() {
    this.notEditing.emit(true);
  }

  emitRequestAdditionalAssessment() {
    this.requestAdditionalAssessment.emit(true);
  }
}
