<a
  *ngIf="pageNumber !== currentPage; else asCurrentPage"
  [class.page-alt1]="alt"
  class="page"
  href="#page-{{ pageNumber }}"
>
  {{ pageNumber }}
</a>
<ng-template #asCurrentPage>
  <a [class.page-alt1]="alt" class="page page-is-active">
    {{ pageNumber }}
  </a>
</ng-template>
