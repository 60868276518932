import { filter } from "rxjs/operators";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { Subscription } from "rxjs";

import { StatusType } from "../status-filter.type";

@Component({
  selector: "app-status-filter",
  templateUrl: "./status-filter.component.html",
  styleUrls: ["./status-filter.component.css"],
})
export class StatusFilterComponent implements OnInit, OnDestroy {
  filters: StatusType[];
  isOpen = false;
  activeFilter: StatusType;
  routeSub: Subscription;
  @Input()
  set statusFilters(filters: StatusType[]) {
    const url = this.router.url.split("/").slice(-1)[0];
    this.setActive(url, filters);
    filters.forEach((f) => {
      if (f.show === undefined) {
        f.show = true;
        return f;
      }
      return f;
    });
    this.filters = filters;
  }

  constructor(private router: Router) {}

  setActive(url: string, filters: StatusType[]) {
    filters.forEach((filter) => {
      if (filter.link === url) {
        this.activeFilter = filter;
        return;
      }
    });
  }

  ngOnInit() {
    this.routeSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const newUrl = event.url.split("/").slice(-1)[0];
        this.setActive(newUrl, this.filters);
      });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
