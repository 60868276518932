import { Injectable } from "@angular/core";

import { ApiGetService } from "../shared/core/apiget.service";
import { Submission } from "../shared/submission.type";
import { SubmissionRecommendationRubric } from "../shared/submission-recommendation-rubric.type";
import { SubmissionDecision } from "../shared/submission-decision.type";
import { AssessmentExtension } from "../shared/assessment.type";

@Injectable()
export class DecisionCreateService {
  constructor(private apiGetService: ApiGetService) {}

  getSubmission(id: string): Promise<Submission | void> {
    return this.apiGetService.get<Submission>(`submission/${id}/`);
  }

  postDecision(data: SubmissionDecision): Promise<SubmissionDecision> {
    return this.apiGetService.post<SubmissionDecision>(
      "issuer/decision/",
      data
    );
  }

  getAssessment(id: number): Promise<AssessmentExtension | void> {
    return this.apiGetService.get<AssessmentExtension>(
      `credential/extension/assessment/${id}/`
    );
  }

  putRecommendationRubric(
    recommendationRubric: SubmissionRecommendationRubric
  ) {
    return this.apiGetService.put<SubmissionRecommendationRubric>(
      `issuer/recommendation-rubric/${recommendationRubric.id}`,
      recommendationRubric
    );
  }
}
