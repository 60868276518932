import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-links-list",
  templateUrl: "./links-list.component.html",
  styleUrls: ["./links-list.component.css"],
})
export class LinksListComponent {
  @Input() readonly: boolean;
  @Input() urls: string[] = [];
  @Output() removeUrl = new EventEmitter<number>();
}
