import { Component, Input } from "@angular/core";

import { Submission } from "../submission.type";

@Component({
  selector: "app-previous-submissions-table",
  templateUrl: "./previous-submissions-table.component.html",
  styleUrls: ["./previous-submissions-table.component.css"],
})
export class PreviousSubmissionsTableComponent {
  @Input() thisSubmission: Submission;
  @Input() submissions: Submission[];
  @Input() title = "Submissions";
  @Input() showLinks = true;

  constructor() {}

  getStatusColor(statusName) {
    switch (statusName) {
      case "Submitted":
      case "Resubmitted":
      case "Assigned":
      case "Assessed":
      case "In Review":
        return "progress";
      case "Awarded":
        return "confirm";
      case "Denied":
        return "error";
      default:
        return "default";
    }
  }
}
