<div class="l-containerhorizontal">
  <div class="l-gridfour" *ngIf="awardedSubmissions?.length > 0">
    <app-credential-card
      *ngFor="let submission of awardedSubmissions"
      [credential]="submission.credential"
      [submission]="submission"
    >
    </app-credential-card>
  </div>

  <app-empty-state
    *ngIf="awardedSubmissions?.length === 0"
    [heading]="emptyHeading"
    [description]="emptyDescription"
  >
    <button class="button" type="button" routerLink="/explore">
      <span class="button-x-text">Explore</span>
    </button>
  </app-empty-state>
</div>

<app-pagination
  *ngIf="totalPages > 1"
  [alt]="true"
  [totalPages]="totalPages"
  [currentPage]="currentPage"
  (choosePage)="updateUrl('page', $event)"
>
</app-pagination>
