import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { DialogHistoryModule } from "../dialog-history/dialog-history.module";

import { EditCredentialComponent } from "./edit-credential.component";

import { NgxFocusTrapModule } from "ngx-focus-trap";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogHistoryModule,
    NgxFocusTrapModule,
  ],
  declarations: [EditCredentialComponent],
  exports: [EditCredentialComponent],
})
export class EditCredentialModule {}
