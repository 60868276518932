import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import "./rxjs-operators";

// Components
import { DigViewerComponent } from "./dig-viewer";
import { DigViewerCarouselComponent } from "./carousel";
import { DigOtherViewerComponent } from "./dig-other-viewer";
import { DigImageViewerComponent } from "./dig-image-viewer";
import { DigPdfViewerComponent } from "./dig-pdf-viewer";
import { DigVideoViewerComponent } from "./dig-video-viewer";
import { DigFileUploaderComponent } from "./file-uploader";
import { DigFileItemComponent } from "./file-item";
import { DigFileListComponent } from "./file-list";

// Services
import { ViewerService, FileService } from "./shared";

// Pipes
import { TruncatePipe } from "./shared";
import { FileSizePipe } from "./shared/file-size.pipe";
import { FileNamePipe } from "./shared/file-name.pipe";
import { SafeUrl } from "./shared/safe.pipe";
import { NgxFocusTrapModule } from "ngx-focus-trap";

@NgModule({
  declarations: [
    DigViewerComponent,
    DigViewerCarouselComponent,
    DigOtherViewerComponent,
    DigImageViewerComponent,
    DigPdfViewerComponent,
    DigVideoViewerComponent,
    DigFileUploaderComponent,
    DigFileItemComponent,
    DigFileListComponent,
    TruncatePipe,
    FileSizePipe,
    FileNamePipe,
    SafeUrl,
  ],
  imports: [CommonModule, HttpClientModule, NgxFocusTrapModule],
  exports: [
    DigViewerCarouselComponent,
    DigFileUploaderComponent,
    DigFileListComponent,
  ],
  providers: [FileService],
})
export class DigViewerModule {
  static forRoot(): ModuleWithProviders<DigViewerModule> {
    return {
      ngModule: DigViewerModule,
      providers: [ViewerService],
    };
  }
}
