<div
  *ngIf="canRecommend || (recommendation && canShowRecommendation)"
  id="complete"
  class="l-detail-x-subsection wrap textblock"
>
  <h2><span>Recommendation</span></h2>
  <h3>Recommendation</h3>
  <p>
    Based on your assessment, would you recommend awarding this
    micro-credential?
  </p>
  <app-automated-score-calculator
    [credential]="submission.credential"
    [assessmentExtension]="assessmentExtension"
    [parentForm]="parentForm"
    [rubricRecommendations]="rubricRecommendations"
    [recommendation]="recommendation"
    [leveled]="leveled"
  ></app-automated-score-calculator>
  <div
    data-app-subsection-recommendation-form
    (updated)="emitUpdated()"
    (isEditing)="emitIsEditing()"
    (notEditing)="emitNotEditing()"
    [canCreate]="canRecommend"
    [credential]="submission.credential"
    [showEditButton]="showEditButton"
    [recommendation]="recommendation"
    [submission]="submission"
    [commentsInfo]="commentsInfo"
    [comments]="comments"
    [leveled]="leveled"
    [showRecommendationComment]="showRecommendationComment"
    class="l-detail-x-subsectionbig"
  ></div>

  <app-subsection-decision-form
    *ngIf="canDecide || decision"
    [canCreate]="canDecide"
    [credential]="submission.credential"
    [canDecide] = "canDecide"
    [submission]="submission"
    [decision]="decision"
  ></app-subsection-decision-form>

  <ng-template
    [ngIf]="
      (canRecommend && recommendation?.to_award === null) ||
      (canDecide && !decision)
    "
  >
    <div
      *ngIf="recommendation?.to_award === null || !decision"
      class="l-detail-x-button"
    >
      <button class="button button-large" type="submit" [disabled]="!formValid">
        <span class="button-x-text">{{ buttonText }}</span>
      </button>

      <button
        *ngIf="ownAssess"
        (click)="emitRequestAdditionalAssessment()"
        type="submit"
        class="button button-large button-request-additional-assessment"
      >
        Request Additional Assessment
      </button>
    </div>
  </ng-template>
</div>
