<div class="l-containerhorizontal">
  <div class="l-resultsheader"></div>

  <div class="searchempty">
    <div class="searchempty-x-content">
      <div class="searchempty-x-image wrap"></div>
      <div class="searchempty-x-text">
        <h3 class="searchempty-x-heading">
          Sorry, the platform <strong>{{ domain }}</strong> doesn’t exist
        </h3>
        <p class="textsmall">Suggestions:</p>
        <ul class="searchempty-x-description">
          <li>Double check your spelling</li>
          <li *ngIf="masterSite">
            Navigate to the <a [href]="masterSite">main platform</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
