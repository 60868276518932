<ng-container *ngFor="let assessment of assessmentExtension?.assessments">
  <div id="part-{{ sectionIndex + 1 }}" class="l-detail-x-subsection wrap textblock"
    *ngFor="let section of assessment.sections; let sectionIndex = index">
    <div style="display: flex; padding:  0px 0px 0px 24px;">
      <div class="section-header">Part {{ sectionIndex + 1 }}: {{ section.title }} </div>
      <div class="section-level-split" [ngClass]="{
        'section-level-last': last
      }" *ngIf="section.leveled_badge?.length > 0"></div>
      <div class="level-header" [ngClass]="{
        'level-last': last
      }" *ngIf="section.leveled_badge?.length > 0">{{ section?.leveled_badge?.length > 0 ? 'Level ' +
        section?.leveled_badge[0]?.level : '' }}</div>

    </div>
    <div class="app-previous-not-passing-rubric-recommendation"
      [previousRubricRecommendations]="previousRubricRecommendations" [thisRubric]="section.rubric"></div>
    <ng-container *ngFor="let question of section.questions">
      <h3>Prompt</h3>
      <div class="app-previous-not-passing-rubric-recommendation"
        [previousRubricRecommendations]="previousRubricRecommendations" [thisRubric]="question.rubric"></div>
      <p data-app-question-text [questionText]="question.text"></p>
      <p *ngIf="question.external_answer_url">
        <a [href]="question.external_answer_url">{{
          question.external_answer_url
          }}</a>
      </p>
      <div *ngIf="!getSectionSkipped(section)" class="forminput-small forminput-attachment forminput">
        <ng-template [ngIf]="
            question.question_type === 'TextQuestion' &&
            textEvidence &&
            textEvidence[question.id]
          ">
          <label for="question-{{ question.id }}-comment" class="forminput-x-label">Response
            <span *ngIf="question.word_limit">(Please keep response to {{ question.word_limit }} words. If
              response includes attachments, please upload or link in part 2 and
              make a note below.)</span></label>
          <div class="forminput-x-input" style="position: relative">
            <textarea style="position: absolute; height: 100%" [value]="textEvidence[question.id] || ''"
              id="question-{{ question.id }}-comment" disabled></textarea>
            <div style="visibility: hidden; min-height: 164px; padding: 8px"
              [innerHTML]="textEvidence[question.id] | newlineToBr"></div>
          </div>
        </ng-template>
        <ng-template [ngIf]="
            question.question_type === 'FileQuestion' &&
            ((fileEvidence && fileEvidence[question.id]) ||
              (urlEvidence && urlEvidence[question.id]))
          ">
          <dig-file-list *ngIf="fileEvidence[question.id]" [files]="fileEvidence[question.id]"></dig-file-list>
          <app-links-list *ngIf="urlEvidence[question.id]" [urls]="urlEvidence[question.id]"
            [readonly]="true"></app-links-list>
        </ng-template>

        <ng-template #assessedQuestionRubric [ngIf]="
            recommendation && rubricRecommendations && canShowRecommendation
          " [ngIfElse]="unassessedQuestionRubric">
          <app-rubric-question-assessed (updated)="emitUpdate()" [showAssessorComment]="showAssessorComment"
            [showEditButton]="showEditButton" (isEditing)="emitIsEditing()" (notEditing)="emitNotEditing()"
            [question]="question" [rubricRecommendations]="rubricRecommendations"
            [previousRubricRecommendations]="previousRubricRecommendations" [comments]="comments"
            [submission]="submission"></app-rubric-question-assessed>
        </ng-template>
        <ng-template #unassessedQuestionRubric>
          <ng-template #assessRubric [ngIf]="recommendForm" [ngIfElse]="evidenceDetail">
            <app-rubric-question-assess-form [parentForm]="recommendForm" [question]="question"
              [previousRubricRecommendations]="previousRubricRecommendations" [comments]="comments"
              [submission]="submission"></app-rubric-question-assess-form>
          </ng-template>
          <ng-template #evidenceDetail>
            <app-rubric-question *ngIf="question.rubric" [question]="question"
              [previousRubricRecommendations]="previousRubricRecommendations"></app-rubric-question>
          </ng-template>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="!getSectionSkipped(section)">
      <ng-template #assessedSectionRubric [ngIf]="recommendation && rubricRecommendations && canShowRecommendation"
        [ngIfElse]="unassessedSectionRubric">
        <app-rubric-section-assessed (updated)="emitUpdate()" [showAssessorComment]="showAssessorComment"
          [showEditButton]="showEditButton" (isEditing)="emitIsEditing()" (notEditing)="emitNotEditing()"
          *ngIf="section.rubric" [section]="section" [comments]="comments" [submission]="submission"
          [rubricRecommendations]="rubricRecommendations"
          [previousRubricRecommendations]="previousRubricRecommendations"></app-rubric-section-assessed>
      </ng-template>
      <ng-template #unassessedSectionRubric>
        <ng-template #assessSectionRubric [ngIf]="recommendForm" [ngIfElse]="sectionRubricDetail">
          <app-rubric-section-assess-form [parentForm]="recommendForm" [section]="section"
            [previousRubricRecommendations]="previousRubricRecommendations" [comments]="comments"
            [submission]="submission"></app-rubric-section-assess-form>
        </ng-template>
        <ng-template #sectionRubricDetail>
          <div data-app-rubric-section *ngIf="section.rubric" [section]="section" class="l-detail-x-subsectionbig"
            [previousRubricRecommendations]="previousRubricRecommendations"></div>
        </ng-template>
      </ng-template>
    </ng-container>
    <div *ngIf="getSectionSkipped(section)">
      <div class="app-section-locked forminput forminput-x-input l-detail-x-subsectionbig"
        *ngIf="section?.leveled_badge[0]?.level && section?.leveled_badge[0]?.level != '1'">
        <div class="attachment attachment-big wrap wrap-light2">
          <div class="icon">
            <img src="/assets/images/icon-lock.svg" alt="Icon - Lock">
          </div>
          <div class="detail">
            <p>
              <strong>This Section is Locked.</strong><br/>
              The user has elected not to complete it.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>