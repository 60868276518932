import {
  Component,
  OnInit,
  Input,
  HostBinding,
  SimpleChanges,
  OnChanges,
} from "@angular/core";

import { Credential } from "../../credential.type";
import { Submission } from "../../submission.type";
import { SubmissionDecision } from "../../submission-decision.type";
import { User } from "../../user.type";

@Component({
  selector: "app-section-overview",
  templateUrl: "./section-overview.component.html",
  styleUrls: ["./section-overview.component.css"],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class SectionOverviewComponent implements OnInit, OnChanges {
  @Input() credential: Credential;
  @Input() decision: SubmissionDecision;
  @Input() previousSubmissions: Submission[];
  @Input() submission: Submission;
  @Input() user: User;
  ownAward = false;
  isStaff = false;

  @HostBinding("attr.class") className = "l-detail-x-subsection wrap";

  constructor() {}

  ngOnInit() {
    if (!this.credential && this.submission) {
      if (typeof this.submission.credential !== "number") {
        this.credential = this.submission.credential;
      }
    }

    this.setAwardDisplay();
  }

  setAwardDisplay() {
    if (this.user) {
      this.isStaff =
        this.user.is_assessor || this.user.is_issuer || this.user.is_admin;
    }

    if (this.submission && this.user) {
      this.ownAward = (<User>this.submission.user).id === this.user.id;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const changeUser = changes["user"];
    const changeSubmission = changes["submission"];
    if (changeUser || changeSubmission) {
      this.setAwardDisplay();
    }
  }
}
