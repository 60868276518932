<nav
  class="l-detail-x-nav"
  role="navigation"
  aria-labelledby="sidebarnavigation"
>
  <h2 class="visuallyhidden" id="sidebarnavigation"></h2>
  <div class="l-detail-x-sidebaritems">
    <a
      href="#overview"
      (click)="emitNavigateTo('#overview')"
      class="sidebarnavitem"
    >
      Overview
    </a>
    <ng-container *ngIf="showNote && showNoteOnTop">
      <ng-container *ngTemplateOutlet="resubmissionNoteTmpl"></ng-container>
    </ng-container>
    <a
      href="#part-{{ sectionIndex + 1 }}"
      (click)="emitNavigateTo('#part-' + (sectionIndex + 1))"
      *ngFor="let section of allSections; let sectionIndex = index"
      class="sidebarnavitem sidebarnavitem-subtext"
    >
      Part {{ sectionIndex + 1 }}:
      <span class="sidebarnavitem-x-subtext">{{ section.title }}</span>
    </a>
    <ng-container *ngIf="showNote && !showNoteOnTop">
      <ng-container *ngTemplateOutlet="resubmissionNoteTmpl"></ng-container>
    </ng-container>
    <a
      *ngIf="completionText"
      href="#complete"
      (click)="emitNavigateTo('#complete')"
      class="sidebarnavitem"
    >
      {{ completionText }}
    </a>
  </div>
</nav>

<ng-template #resubmissionNoteTmpl>
  <a
    href="#resubmission-note"
    (click)="emitNavigateTo('#resubmission-note')"
    class="sidebarnavitem"
  >
    Resubmission Note
  </a>
</ng-template>
