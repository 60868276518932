<div class="textinfo-x-comment" *ngIf="isReadOnly && !isEmpty(comment)">
  <h4>Submission Feedback:</h4>
  <div class="textblock" [innerHtml]="comment | safeHtml"></div>
</div>
<div class="textinfo-x-comment" *ngIf="!isReadOnly && commentChange">
  <label class="forminput-x-label" style="margin-top: 16px"
    >Submission Feedback:</label
  >
  <quill-editor
    (onContentChanged)="onCommentChange($event)"
    [styles]="{ height: '120px' }"
    [ngModel]="comment"
    class="submission-comment-editor"
    name="criterion_comment_{{ criterion.id }}"
    placeholder="Enter your response..."
  >
  </quill-editor>
</div>
