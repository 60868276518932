<a
  class="cardcredential card"
  [routerLink]="
    submission
      ? getLatestSubmissionRoute(submission)
      : draft
      ? ['/explore', credential.slug, 'application']
      : ['/explore', credential.slug]
  "
>
  <div class="cardcredential-x-image">
    <picture>
      <source
        media="(min-width: 496px)"
        srcset="{{ credential.image }} 2x, {{ credential.image }}"
      />
      <img
        srcset="{{ credential.image }}"
        src="{{ credential.image }}"
        alt="{{ credential.description }}"
      />
    </picture>
  </div>
  <div class="cardcredential-x-text">
    <ng-container *ngIf="draft; else submissionStatus">
      <p>
        <span class="cardcredential-x-status">
          <span class="statustag statustag-progress"
            >Draft<span class="visuallyhidden">: </span></span
          >
        </span>
        {{ credential.name }}
      </p>
    </ng-container>
    <ng-template #submissionStatus>
      <p [ngSwitch]="getStatus(submission?.status.toLowerCase())">
        <span class="cardcredential-x-status" *ngSwitchCase="STATUS.SUBMITTED">
          <span class="statustag statustag-progress"
            >Submitted<span class="visuallyhidden">: </span></span
          >
        </span>

        <span
          class="cardcredential-x-status"
          *ngSwitchCase="STATUS.RESUBMITTED"
        >
          <span class="statustag statustag-progress"
            >Resubmitted<span class="visuallyhidden">: </span></span
          >
        </span>

        <span class="cardcredential-x-status" *ngSwitchCase="STATUS.ASSIGNED">
          <span class="statustag statustag-progress"
            >In Review<span class="visuallyhidden">: </span></span
          >
        </span>

        <span class="cardcredential-x-status" *ngSwitchCase="STATUS.ASSESSED">
          <span class="statustag statustag-progress"
            >In Review<span class="visuallyhidden">: </span></span
          >
        </span>

        <span class="cardcredential-x-status" *ngSwitchCase="STATUS.AWARDED">
          <span *ngIf="!credential.apply_only" class="statustag statustag-confirm"
            >Awarded<span class="visuallyhidden">: </span></span
          > 
          <span *ngIf="credential.apply_only" class="statustag statustag-{{highest_level_awarded ? 'confirm' : 'all'}}">
            Awarded, Level {{awarded_level}} of {{max_level}}<span class="visuallyhidden">: </span></span
          >
        </span>

        <!-- DIG-1716: don't show denied -->

        {{ credential.name }}
      </p>
    </ng-template>
    <ul>
      <li>
        <p class="icon icon-organization">
          {{ credential.organization.name }}
        </p>
      </li>
      <li>
        <p class="icon icon-price">
          {{ getPriceDisplay() }}
        </p>
      </li>
    </ul>
  </div>
</a>
