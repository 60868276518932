import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NewlineToBrPipe } from "./newline-to-br.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [NewlineToBrPipe],
  exports: [NewlineToBrPipe],
})
export class NewlineToBrModule {}
