import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { ApiGetService } from "./apiget.service";

import { Site } from "../site.type";

@Injectable({
  providedIn: "root",
})
export class SiteService {
  site$: BehaviorSubject<Site> = new BehaviorSubject(null);
  site: Site;
  error$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiGetService) {}

  getSite(domain: string): Promise<Site | void> {
    const site = this.apiService.get<Site>(`site/${domain}`);
    return site
      .then((site: Site) => {
        this.site = site;
        this.site$.next(site);
        return site;
      })
      .catch((err) => {
        if (err.status == 404) {
          this.error$.next(true);
          throw err;
        }
      });
  }
}
