import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "../authentication.guard";

import { SubmissionDetailComponent } from "./submission-detail.component";

const routes: Routes = [
  {
    path: "dashboard/submission/:id",
    component: SubmissionDetailComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "assess/submission/:id",
    component: SubmissionDetailComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "review/submission/:id",
    component: SubmissionDetailComponent,
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubmissionDetailRoutingModule {}
