import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AwardedSubmissionListComponent } from "./awarded-submission-list/awarded-submission-list.component";
import { DraftListComponent } from "./draft-list/draft-list.component";
import { ProgressPageComponent } from "./progress-page.component";

import { AuthenticationGuard } from "../authentication.guard";
import { SiteGuard } from "../site.guard";

const routes: Routes = [
  {
    path: "dashboard",
    component: ProgressPageComponent,
    canActivate: [AuthenticationGuard, SiteGuard],
    children: [
      {
        path: "",
        canActivateChild: [AuthenticationGuard],
        children: [
          { path: "awarded", component: AwardedSubmissionListComponent },
          { path: "drafts", component: DraftListComponent },
          { path: "", redirectTo: "awarded", pathMatch: "full" },
          {
            path: "",
            loadChildren: () =>
              import(
                "app/progress-page/progress-table/progress-table.module"
              ).then((m) => m.ProgressTableModule),
          },
        ],
      },
    ],
    data: { title: "Dashboard" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProgressPageRoutingModule {}
