import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

import { ViewFile } from "./view-file";

@Injectable()
export class LocalFilesService {
  getFile(f: string): Observable<ViewFile> {
    const file = JSON.parse(f);
    return Observable.create((observer: Observer<ViewFile>) => {
      observer.next(file);
    });
  }
}
