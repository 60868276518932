<div class="pdf-body">
  <iframe
    [attr.src]="sanitizer.bypassSecurityTrustResourceUrl(pdfURL)"
    [width]="width"
    [height]="height"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  >
  </iframe>
</div>
