import { Component } from "@angular/core";

@Component({
  selector: "app-links-demo",
  templateUrl: "./links-demo.component.html",
  styleUrls: ["./links-demo.component.css"],
})
export class LinksDemoComponent {
  urls: string[] = ["http://preloaded-example.com"];
  readonly: boolean;
}
