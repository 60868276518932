import { Component, OnInit, ElementRef } from "@angular/core";

import { FaqsService } from "./faqs.service";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.css"],
})
export class FaqsComponent implements OnInit {
  categories: any[];
  whitespace = /\s+/g;

  constructor(
    private faqsService: FaqsService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.faqsService.getData().then((res) => {
      this.categories = res;
    });
  }

  navigateTo(fragmentName) {
    const element = this.elementRef.nativeElement.querySelector(fragmentName);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return false;
  }

  getAnchor(category) {
    return "#" + this.slugify(category);
  }

  slugify(category) {
    return category.title.toLowerCase().replace(this.whitespace, "-");
  }
}
