import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationGuard } from "../authentication.guard";

import { SubmissionCreateComponent } from "./submission-create.component";
import { CredentialResolveGuard } from "../shared/resolvers/credential-resolve.guard";
import { WarnUserOnNavigateGuard } from "./warn-user-on-navigate.guard";

const routes: Routes = [
  {
    path: "explore/:slug/application",
    component: SubmissionCreateComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [WarnUserOnNavigateGuard],
    resolve: {
      credential: CredentialResolveGuard,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubmissionCreateRoutingModule {}
