/* eslint-disable curly */
import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  AfterViewChecked,
} from "@angular/core";
import { ViewerService, FileMIMEType, ViewFile } from "../shared";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

type DigViewerPayload =
  | { valid: false; message: string }
  | { valid: true; file: ViewFile };

@Component({
  selector: "dig-viewer",
  templateUrl: "./dig-viewer.component.html",
  styleUrls: ["./dig-viewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigViewerComponent implements AfterViewChecked {
  @Input() isVisible = false;
  @Output() closeDialog = new EventEmitter();

  payload$: Observable<DigViewerPayload> = of(null);

  @ViewChild("viewerComponents", { static: false }) container: ElementRef;

  width = 0;
  height = 0;

  TYPE = FileMIMEType;

  closeCarouselDialog() {
    this.closeDialog.emit();
  }

  @Input()
  set selectedUrl(url: string) {
    if (!url) return;

    this.payload$ = this.viewerService.getFile(url).pipe(
      map((file) => ({ file, valid: true as true })),
      catchError((err) => of({ message: err.message, valid: false as false }))
    );
  }

  constructor(
    private viewerService: ViewerService,
    private ref: ChangeDetectorRef
  ) {}

  ngAfterViewChecked() {
    if (this.container && this.isVisible) {
      this.updateViewportSize();
    }
  }

  @HostListener("window:resize")
  updateViewportSize() {
    if (this.container && this.isVisible) {
      const width = this.container.nativeElement.clientWidth,
        height = this.container.nativeElement.clientHeight;
      this.width = width * 0.9;
      this.height = height * 0.9;
      this.ref.detectChanges();
    }
  }
}
