<ng-template [ngIf]="question.question_type === 'TextQuestion'">
  <div class="forminput-small forminput forminput-attachment">

    <label for="comment" class="forminput-x-label"
      >Response
      <ng-template [ngIf]="question.word_limit" [ngIfElse]="noWordLimitLabel">
        <ng-template
          [ngIf]="sectionNumber > 2"
          [ngIfElse]="wordLimitNoAttachment"
        >
          <span
            >(Please keep response to {{ question.word_limit }} words. If
            response includes attachments, please upload or link in part 2 and
            make a note below.)</span
          >
        </ng-template>
        <ng-template #wordLimitNoAttachment>
          <span
            >(Please keep response to {{ question.word_limit }} words.)</span
          >
        </ng-template>
      </ng-template>
      <ng-template #noWordLimitLabel>
        <span *ngIf="sectionNumber > 2"
          >(If response includes attachments, please upload or link in part 2
          and make a note below.)</span
        >
      </ng-template>
    </label>
    <div class="forminput-x-input" style="position: relative">
      <textarea
        style="position: absolute; height: 100%"
        [formControl]="textInput"
        placeholder="Enter your response..."
        [value]="value"
      ></textarea>
      <div
        style="visibility: hidden; padding: 8px; min-height: 164px"
        [innerHTML]="textInput.value | newlineToBr"
      ></div>
    </div>
    <app-rubric-question
      [question]="question"
      [previousRubricRecommendations]="previousRubricRecommendations"
    ></app-rubric-question>
  </div>
</ng-template>

<ng-template [ngIf]="question.question_type === 'FileQuestion'">
  <div class="forminput forminput-small l-attachment">
    <label class="forminput-x-label"
      >Attachment <span>(Limit 20MB)</span></label
    >
    <dig-file-uploader
      [question]="question"
      (uploaded)="uploaded.emit($event)"
      (removed)="removed.emit($event)"
      [value]="asSubmissionFiles(draftFiles)"
      [disabled]="disabled"
    ></dig-file-uploader>
  </div>

  <div
    data-app-submission-links
    helpText="Please set permissions appropriately"
    [urls]="urls"
    (urlsChange)="urls = $event; urlsChange.emit(urls)"
    [value]="valueUrls"
    [disabled]="disabled"
    class="forminput-small forminput l-attachment"
  ></div>

  <div>
    <app-rubric-question
      [question]="question"
      [previousRubricRecommendations]="previousRubricRecommendations"
    ></app-rubric-question>
  </div>
</ng-template>
