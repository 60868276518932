import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Error } from "../messages.constants";
import { Comment, CommentAction } from "../comment.type";
import { SubmissionCommentMixinService } from "./submission-comment-mixin.service";

const TIME_EDITED_TRESHOLD = 5;

@Component({
  selector: "app-submission-comment-base",
  template: `<p></p>`,
  styles: [],
})
export class SubmissionCommentBaseComponent implements OnInit {
  @Input() data: Comment;
  @Input() actionScope: CommentAction[];
  @Output() submitCommentEvent = new EventEmitter<{
    data: Comment;
    newComment?: string;
  }>();
  @Output() removeCommentEvent = new EventEmitter<{ id: number }>();

  newComment: string = "";
  loading: boolean = false;
  editMode: CommentAction = CommentAction.view;

  constructor(
    private submissionCommentMixinService: SubmissionCommentMixinService,
    private messageService: ToastrService
  ) {}

  isEdited() {
    return (
      (this.data.updated_at.valueOf() - this.data.created_at.valueOf()) / 1000 >
      TIME_EDITED_TRESHOLD
    );
  }

  isEditable = () => this.actionScope.includes(CommentAction.edit);
  isDeletable = () => this.actionScope.includes(CommentAction.remove);
  isEditMode = () => this.editMode === CommentAction.edit;
  isViewMode = () => this.editMode === CommentAction.view;

  ngOnInit() {
    this.newComment = this.data.comment;
  }

  onSubmit() {
    const data: Comment = this.data;
    const fn: Promise<Comment> = data?.id
      ? this.submissionCommentMixinService.updateComment(
          data.id,
          this.newComment
        )
      : this.submissionCommentMixinService.addComment({
          ...data,
          comment: this.newComment,
        });
    fn.then((updatedComment: Comment) => {
      if (data.id) {
        this.editMode = CommentAction.view;
      } else {
        this.newComment = "";
      }
      this.submitCommentEvent.emit({
        data: updatedComment,
      });
    })
      .catch((err) => {
        this.messageService.error(Error.COMMENT_ADD_UPDATE(err.message), "");
      })
      .finally(() => (this.loading = false));
  }

  onCancel() {
    this.newComment = this.data.comment;
    this.editMode = CommentAction.view;
  }

  onEdit() {
    this.editMode = CommentAction.edit;
  }

  onRemove() {
    this.submissionCommentMixinService
      .removeComment(this.data.id)
      .then(() => {
        this.removeCommentEvent.emit({ id: this.data.id });
      })
      .catch((err) => {
        this.messageService.error(Error.COMMENT_REMOVE(err.message), "");
      })
      .finally(() => (this.loading = false));
  }
}
