<div
  *ngIf="question.rubric"
  class="attachment wrap"
  [class.attachment-borderorange]="!editing"
  [class.attachment-borderblue]="editing"
  [class.attachment-blue]="editing"
  [class.wrap-interactive3]="editing"
  [class.previous-was-wrong]="previousRecommendation?.implies_not_passing"
>
  <div #rubricContainer class="textinfo textinfo-expanded">
    <header class="textinfo-x-header">
      <h5>Assessment Rubric</h5>

      <div>
        <button
          *ngIf="showEditButton && !editing"
          (click)="startUpdateRubricRecommendation()"
          type="button"
          class="button button-tertiary"
          style="color: #2578a2"
        >
          Edit
        </button>
        <button
          #expandRubric
          *ngIf="!editing"
          type="button"
          class="button button-secondary"
          aria-labelledby="question"
          (click)="rubricContainer.classList.toggle('textinfo-is-active')"
        >
          <ng-container
            *ngIf="
              rubricContainer.classList.contains('textinfo-is-active');
              else expandText
            "
            >Collapse Rubric</ng-container
          >
          <ng-template #expandText>Expand Rubric</ng-template>
        </button>
      </div>

      <div *ngIf="editing">
        <button
          (click)="exitUpdateRubricRecommendation()"
          type="button"
          class="button button-secondary"
        >
          Cancel
        </button>
        <button
          (click)="saveUpdateRubricRecommendation()"
          type="button"
          class="button"
          style="color: white"
        >
          Save Changes
        </button>
      </div>
    </header>

    <div
      *ngIf="editing; else previewRubricRecommendation"
      class="textinfo-x-content"
    >
      <form [formGroup]="form">
        <fieldset
          *ngFor="
            let criterion of question.rubric.criterions;
            let criterionIndex = index
          "
        >
          <div
            class="forminput-x-input"
            *ngFor="
              let criterionLevel of criterion.criterion_levels;
              let criterionLevelIndex = index
            "
          >
            <div class="formradio formradio-bold">
              <input
                class="visuallyhidden"
                type="radio"
                value="{{ criterion.id }},{{ criterionLevel.id }}"
                [checked]="
                  rubricRecommendation?.criterion_levels.includes(
                    criterionLevel.id
                  )
                "
                (click)="
                  setRubricRecommendationCriterionLevel(
                    criterionLevel.id,
                    criterion
                  )
                "
                name="question_{{ criterion.id }}"
                id="question_{{ question.id }}_criterionlevel_{{
                  criterionLevel.id
                }}"
              />
              <label
                for="question_{{ question.id }}_criterionlevel_{{
                  criterionLevel.id
                }}"
              >
                {{ criterionLevel.quality }}
                <span
                  class="forminput-x-sublabel"
                  [innerHTML]="criterionLevel.description | newlineToBr"
                ></span>
              </label>
            </div>
          </div>
          <app-rubric-comment [criterion]="criterion" [isReadOnly]="false">
          </app-rubric-comment>
          <app-submission-comments
            [data]="comments"
            [info]="commentsInfo[criterion.id]"
          >
            <hr class="comments__hr" />
            <label class="forminput-x-label"
              >Assessor Comments (Private):</label
            >
          </app-submission-comments>
        </fieldset>
      </form>
    </div>

    <ng-template #previewRubricRecommendation>
      <div class="textinfo-x-content" [formGroup]="form">
        <fieldset *ngFor="let criterion of question.rubric.criterions">
          <ng-template
            ngFor
            [ngForOf]="criterion.criterion_levels"
            let-criterionLevel
            let-i="index"
          >
            <ng-container
              *ngTemplateOutlet="
                critLevel;
                context: {
                  $implicit: rubricRecommendation?.criterion_levels?.includes(
                    criterionLevel.id
                  )
                }
              "
            ></ng-container>

            <ng-template #critLevel let-isRecommendedLevel>
              <div
                [class.textinfo-x-hidden]="!isRecommendedLevel"
                [class.l-hidden]="!isRecommendedLevel"
                tabindex="-1"
              >
                <div class="forminput-x-input">
                  <div class="formradio formradio-bold">
                    <input
                      class="visuallyhidden"
                      disabled
                      type="radio"
                      [value]="criterionLevel.id"
                      [checked]="isRecommendedLevel"
                      id="question_{{ question.id }}_criterionlevel_{{
                        criterionLevel.id
                      }}"
                    />
                    <label
                      for="question_{{ question.id }}_criterionlevel_{{
                        criterionLevel.id
                      }}"
                    >
                      {{ criterionLevel.quality }}
                      <span
                        class="forminput-x-sublabel"
                        [innerHTML]="criterionLevel.description | newlineToBr"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
          <app-rubric-comment
            *ngIf="showAssessorComment"
            [criterion]="criterion"
            [isReadOnly]="true"
          >
          </app-rubric-comment>
          <app-submission-comments
            [data]="comments"
            [info]="commentsInfo[criterion.id]"
          >
            <hr class="comments__hr" />
            <label class="forminput-x-label"
              >Assessor Comments (Private):</label
            >
          </app-submission-comments>
        </fieldset>
      </div>
    </ng-template>
  </div>
</div>
