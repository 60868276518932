<div
  *ngIf="decision || (container && canCreate)"
  class="l-detail-x-subsectionbig"
>
  <div
    class="wrap"
    [class.wrap-interactive3]="!decision"
    [class.wrap-accent3]="decision"
  >
    <div class="textinfo textinfo-bigheader textinfo-expanded textinfo-outer">
      <header class="textinfo-x-header">
        <h5>Award</h5>
      </header>
      <p>
        Based on your review of this submission, should this micro-credential be
        awarded?
      </p>
      <div *ngIf="decision" class="textinfo-x-buttonmediasmall">
        <button
          type="button"
          class="button button-secondary"
          aria-labelledby="part6"
          data-toggletext="Collapse Rubric"
          onclick="this.parentElement.parentElement.classList.toggle('textinfo-is-active'); if (this.innerHTML === 'Expand Rubric') {this.innerHTML = this.dataset.toggletext} else {this.innerHTML = 'Expand Rubric'}"
        >
          Expand Rubric
        </button>
      </div>
      <div
        class="attachment attachment-big attachment-inner wrap"
        [class.attachment-borderblue]="!decision"
        [class.attachment-borderorange]="decision"
      >
          <app-leveled-recommendation
          *ngIf="leveled"
          [credential]="credential"
          [isRecommended]="isRecommended"
          [decision]="decision"
          [canDecide] = "canDecide"
          [canCreate] = "canCreate"
          [skippedSections]="skippedSections"
          [editing]="editing"
          [form]="form"
          [isDecision] = "true"
          ></app-leveled-recommendation>
          <app-boolean-recommendation
          *ngIf="!leveled"
          [credential]="credential"
          [isRecommended]="isRecommended"
          [canCreate] = "canCreate"
          [canDecide] = "canDecide"
          [decision]="decision"
          [editing]="editing"
          [form]="form"
          [isDecision] = "true"
          ></app-boolean-recommendation>
      </div>
    </div>
  </div>
</div>
