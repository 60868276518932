import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { Rubric } from "../../assessment.type";
import { SubmissionRecommendationRubric } from "../../submission-recommendation-rubric.type";

@Component({
  selector: ".app-previous-not-passing-rubric-recommendation",
  templateUrl: "./previous-not-passing-rubric-recommendation.component.html",
  styleUrls: ["./previous-not-passing-rubric-recommendation.component.css"],
})
export class PreviousNotPassingRubricRecommendationComponent
  implements OnChanges
{
  @Input() thisRubric: Rubric;
  @Input() previousRubricRecommendations: SubmissionRecommendationRubric[];
  thisPreviousRubricRecommendation: SubmissionRecommendationRubric;
  impliesNotPassing: boolean;

  setThisPreviousRecommendation() {
    if (this.thisRubric) {
      for (const rubricRecommendation of this.previousRubricRecommendations) {
        if (rubricRecommendation.rubric === this.thisRubric.id) {
          this.thisPreviousRubricRecommendation = rubricRecommendation;
          this.impliesNotPassing = rubricRecommendation.implies_not_passing;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes["previousRubricRecommendations"];
    if (change && !change.firstChange) {
      this.setThisPreviousRecommendation();
    }
  }
}
