import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";

import { ToastrService } from "ngx-toastr";

import { Submission } from "../shared/submission.type";
import { SubmissionDecision } from "../shared/submission-decision.type";
import { UserService } from "../shared/core/user.service";

import { Credential } from "../shared/credential.type";

import { SubmissionDetailMixinComponent } from "../shared/submission-detail-mixin/submission-detail-mixin.component";
import { SubmissionDetailMixinService } from "../shared/submission-detail-mixin/submission-detail-mixin.service";
import { Confirmation, Error } from "../shared/messages.constants";

import { DecisionCreateService } from "./decision-create.service";

@Component({
  selector: "app-decision-create",
  templateUrl: "./decision-create.component.html",
  styleUrls: ["./decision-create.component.css"],
})
export class DecisionCreateComponent
  extends SubmissionDetailMixinComponent
  implements OnInit
{
  decideForm: UntypedFormGroup;
  openEdits = 0;

  constructor(
    protected userService: UserService,
    private titleService: Title,
    protected submissionMixinService: SubmissionDetailMixinService,
    protected elementRef: ElementRef,
    protected route: ActivatedRoute,
    private messageService: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private decisionCreateService: DecisionCreateService
  ) {
    super(userService, submissionMixinService, elementRef, route);
  }

  ngOnInit() {
    super.ngOnInit();

    this.submission$.subscribe((submission: Submission) => {
      this.titleService.setTitle(
        `${(<Credential>submission.credential).name} Submission Review - ${
          (<Credential>submission.credential).organization.name
        } | Digital Promise`
      );
    });

    this.instantiateForm();
  }

  instantiateForm() {
    this.decideForm = this.fb.group({
      recommendation: null,
      issuer: null,
      leveled_badge: null,
      to_award: [null, Validators.required],
    });
  }

  initializeForm() {
    this.decideForm.patchValue({
      recommendation: this.recommendation ? this.recommendation.id : null,
      issuer: this.user.id,
    });
  }

  createDecision() {
    this.decisionCreateService
      .postDecision(this.decideForm.value)
      .then((decision: SubmissionDecision) => {
        if (decision.to_award) {
          this.messageService.success(Confirmation.CREDENTIAL("awarded"));
          this.router.navigate(["/review/to-review-awarded"]);
        } else {
          this.messageService.success(Confirmation.CREDENTIAL("denied"));
          this.router.navigate(["/review/to-review-denied"]);
        }
      })
      .catch((_err) => {
        this.messageService.error(Error.DECISION, "");
      });
    return false;
  }
}
