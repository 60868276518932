import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page-number",
  templateUrl: "./page-number.component.html",
  styleUrls: ["./page-number.component.css"],
})
export class PageNumberComponent {
  @Input() pageNumber: number;
  @Input() currentPage: number;
  @Input() alt = false;

  constructor() {}
}
