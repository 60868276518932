import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

import { Subject } from "rxjs";

import { User } from "../user.type";
import { UserService } from "../core/user.service";
import { Credential } from "../credential.type";
import { AssessmentExtension, AssessmentSection } from "../assessment.type";
import { Submission } from "../submission.type";
import { SubmissionEvidence } from "../submission-evidence.type";
import { SubmissionText } from "../submission-text.type";
import { SubmissionFile } from "../submission-file.type";
import { SubmissionUrl } from "../submission-url.type";
import { SubmissionRecommendation } from "../submission-recommendation.type";
import { SubmissionRecommendationRubric } from "../submission-recommendation-rubric.type";
import { SubmissionDecision } from "../submission-decision.type";
import { AwardedCredential } from "../awarded-credential.type";
import { ShareToken } from "../share-token.type";
import { HistoryData } from "../history.type";

import { SubmissionDetailMixinService } from "./submission-detail-mixin.service";

import { PreviousRecommendationRubricParentMixin } from "../previous-recommendation-rubric-mixin/previous-recommendation-rubric-parent.mixin";
import { Comment, CommentType, ContentType } from "../comment.type";
import { toDate, parseSubmitDate } from "../utils/date-utils";

@Component({
  selector: "app-submission-detail-mixin",
  templateUrl: "./submission-detail-mixin.component.html",
  styleUrls: ["./submission-detail-mixin.component.css"],
})
export class SubmissionDetailMixinComponent
  extends PreviousRecommendationRubricParentMixin
  implements OnInit
{
  user: User;
  credential: Credential;
  assessmentExtension: AssessmentExtension;
  allSections: AssessmentSection[] = [];
  showSections: AssessmentSection[] = [];
  submission: Submission;
  submissionHistory: HistoryData[] = [];
  submissionComments: Comment[];
  submission$: Subject<Submission> = new Subject();
  textEvidence: { [assessmentQuestion: number]: string } = {};
  fileEvidence: { [assessmentQuestion: number]: string[] } = {};
  urlEvidence: { [assessmentQuestion: number]: string[] } = {};
  recommendation: SubmissionRecommendation;
  rubricRecommendations: SubmissionRecommendationRubric[];
  // rubricRecommendationMap: { [criterionKey: string]: number } = {};
  decision: SubmissionDecision;
  award: AwardedCredential;
  ownAward = false;
  ownAssess = false;
  userIsStaff = false;
  canShowRecommendation = false;
  shareToken: ShareToken;

  readonly ContentType = ContentType;
  readonly CommentType = CommentType;

  constructor(
    protected userService: UserService,
    protected submissionMixinService: SubmissionDetailMixinService,
    protected elementRef: ElementRef,
    protected route: ActivatedRoute
  ) {
    super(submissionMixinService);
  }

  ngOnInit() {
    this.user = this.userService.user;
    this.instantiateForm();
    this.fetchContextObject();
  }

  fetchContextObject(): void {
    // Fetches a submission to build view context given an URL id segment
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get("id")) {
        this.submissionMixinService
          .getSubmission(params.get("id"))
          .then((submission: Submission) => {
            return this.buildContext(submission);
          });
      }
    });
  }

  instantiateForm() {
    // Build form data model
  }

  initializeForm() {
    // Populate form data model
  }

  buildContext(submission): Promise<void> {
    // Fetches and populates the view with all information related to a submission
    if (!submission) {
      return; // User didn't have permission to view submission
    }

    this.getPreviousRecommendationRubric(
      submission.credential,
      submission.user,
      this.shareToken && this.shareToken.token,
      "public"
    );

    this.buildSubmissionContext(submission);

    const assessmentExtensionPromise = this.fetchAssessmentExtension(
      (<Credential>submission.credential).extension
    );

    const evidencePromise = this.submissionMixinService
      .getEvidence(
        submission.readable_id,
        this.shareToken && this.shareToken.token
      )
      .then((evidence: SubmissionEvidence) => {
        this.buildTextEvidenceContext(<SubmissionText[]>evidence.texts);
        this.buildFileEvidenceContext(<SubmissionFile[]>evidence.files);
        this.buildUrlEvidenceContext(<SubmissionUrl[]>evidence.urls);
      })
      .catch(() => {});

    const rubricRecommendationsPromise = this.submissionMixinService
      .getRubricRecommendations(
        submission.readable_id,
        this.shareToken && this.shareToken.token
      )
      .then((rubricRecommendations: SubmissionRecommendationRubric[]) => {
        this.rubricRecommendations = rubricRecommendations;
      })
      .catch(() => {});

    const commentsPromise = this.fetchSubmissionComments(submission);

    return Promise.all([
      assessmentExtensionPromise,
      rubricRecommendationsPromise,
      evidencePromise,
      commentsPromise,
    ]).then(() => {
      this.initializeForm();
    });
  }

  fetchAssessmentExtension(
    assessmentExtension: AssessmentExtension | number
  ): Promise<AssessmentExtension | void> {
    let assessmentExtensionId: number;

    if (!assessmentExtension) {
      this.initializeForm();
      return Promise.resolve();
    }

    if (typeof assessmentExtension === "number") {
      assessmentExtensionId = assessmentExtension;
    } else {
      assessmentExtensionId = assessmentExtension.id;
    }

    const assessmentExtensionPromise = this.submissionMixinService
      .getAssessment(assessmentExtensionId)
      .then((assessmentExtension: AssessmentExtension) => {
        this.assessmentExtension = assessmentExtension;
        this.buildAllSectionsContext(assessmentExtension);
      });

    return assessmentExtensionPromise;
  }

  buildAllSectionsContext(assessmentExtension: AssessmentExtension) {
    this.allSections = [];
    this.showSections = [];
    for (const assessment of this.assessmentExtension.assessments) {
      for (const section of assessment.sections) {
        this.allSections.push(section);
        this.showSections.push(section);
      }
    }
  }

  fetchSubmissionHistory(submission: Submission) {
    this.submissionMixinService
      .getSubmissionHistory(submission.id)
      .then((submissionHistory: HistoryData[]) => {
        this.submissionHistory = submissionHistory;
      });
  }

  fetchSubmissionComments(submission: Submission) {
    return this.submissionMixinService
      .getSubmissionComments(
        submission.readable_id,
        this.shareToken && this.shareToken.token
      )
      .then((submissionComments: any[]) => {
        this.submissionComments = submissionComments.map((comment) => ({
          ...comment,
          object_pk: parseInt(comment.object_pk),
          created_at: toDate(comment.created_at),
          updated_at: toDate(comment.updated_at),
          submit_date: parseSubmitDate(comment.submit_date),
        }));
      })
      .catch(() => {});
  }

  buildSubmissionContext(submission: Submission) {
    if (submission) {
      this.submission = submission;
      this.submission$.next(submission);

      this.fetchSubmissionHistory(submission);

      this.credential = <Credential>submission.credential;
      this.recommendation = submission && submission.recommendation;
      this.decision =
        this.recommendation && <SubmissionDecision>this.recommendation.decision;
      this.award = this.decision && <AwardedCredential>this.decision.award;

      this.ownAward = this.user && (<User>submission.user).id === this.user.id;
      this.ownAssess =
        this.user && (<User>submission.assessor)?.id === this.user.id;

      this.userIsStaff =
        this.user &&
        (this.user.is_admin || this.user.is_issuer || this.user.is_assessor);

      this.canShowRecommendation = !!(this.decision || this.userIsStaff);
    }
  }

  buildTextEvidenceContext(text: SubmissionText[]) {
    text.forEach((text: SubmissionText) => {
      this.textEvidence[<number>text.assessment_question] = text.text;
    });
  }

  buildFileEvidenceContext(files: SubmissionFile[]) {
    files.forEach((file: SubmissionFile) => {
      const questionNumber = <number>file.assessment_question;

      if (questionNumber in this.fileEvidence) {
        this.fileEvidence[questionNumber].push(file.file);
      } else {
        this.fileEvidence[questionNumber] = [file.file];
      }
    });
  }

  buildUrlEvidenceContext(urls: SubmissionUrl[]) {
    urls.forEach((url: SubmissionUrl) => {
      const questionNumber = <number>url.assessment_question;

      if (questionNumber in this.urlEvidence) {
        this.urlEvidence[questionNumber].push(url.url);
      } else {
        this.urlEvidence[questionNumber] = [url.url];
      }
    });
  }

  getRubricRecommendation(
    rubricObjectType: string,
    rubricObjectId: number,
    rubricId: number
  ): SubmissionRecommendationRubric | any {
    if (!this.recommendation) {
      return {};
    }

    for (const rubricRecommendation of <SubmissionRecommendationRubric[]>(
      this.rubricRecommendations
    )) {
      if (
        rubricRecommendation.assessment_type === rubricObjectType &&
        rubricRecommendation.assessment_type_pk === rubricObjectId &&
        rubricRecommendation.rubric === rubricId
      ) {
        return rubricRecommendation;
      }
    }
    return {};
  }

  navigateTo(fragmentName) {
    const element = this.elementRef.nativeElement.querySelector(fragmentName);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return false;
  }
}
