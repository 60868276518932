import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Credential } from "../../shared/credential.type";
import { Submission } from "../../shared/submission.type";

import { ReviewPageService } from "../review-page.service";
import { Confirmation } from "../../shared/messages.constants";
import { ModalService } from "../../shared/core/modal.service";

@Component({
  selector: "app-bulk-assignment-modal",
  templateUrl: "./bulk-assignment-modal.component.html",
  styleUrls: ["./bulk-assignment-modal.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAssignmentModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  bulkSubmissions: Submission[];
  roles: any[] = [];
  bulkAssignmentForm: UntypedFormGroup;
  submissionReadbleIds = [];

  @ViewChild("ngxFocus", { static: true }) focusTrap;
  modalIsOpen = false;

  // Back button pressed.
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.closeDialog();
  }

  @Input()
  set isOpen(value: boolean) {
    this.modalService.anyModalIsOpen.next(value);
    this.modalIsOpen = value;

    if (value) {
      this.focusTrap.activateFocusTrap();
    }
  }

  @Input()
  set submissions(submissions: Submission[]) {
    this.bulkSubmissions = submissions;

    if (this.bulkSubmissions.length == 0) {
      return;
    }

    let currentSubmission = this.bulkSubmissions[0];
    this.reviewPageService
      .getOrganizationRoles(
        (<Credential>currentSubmission.credential).organization.id
      )
      .then((res) => {
        this.roles = res;
        this.ref.markForCheck();
      });

    this.submissionReadbleIds = [];
    this.bulkSubmissions.forEach((submission) => {
      this.submissionReadbleIds.push(submission.readable_id);
    });
  }

  constructor(
    private reviewPageService: ReviewPageService,
    private ref: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private modalService: ModalService
  ) {}

  closeDialog(message = null) {
    this.closeModal.emit(message);
    this.resetForm();
    this.focusTrap.deactivateFocusTrap();
    this.modalService.anyModalIsOpen.next(false);
    this.modalIsOpen = false;
  }

  onSubmit() {
    if (this.bulkAssignmentForm.valid) {
      const assessorId = this.bulkAssignmentForm.get("assessor").value,
        role = this.roles.find((r) => r.user.id === assessorId),
        assessor = role.user,
        reason = Confirmation.ASSIGNED(
          `${assessor.first_name} ${assessor.last_name}`
        );
      let submissionIds = [];
      this.bulkSubmissions.forEach((submission) => {
        submissionIds.push(submission.id);
      });
      const data = {
        ids: submissionIds,
        assessor: assessorId,
      };
      this.reviewPageService.bulkAssignSubmissions(data, reason).then((res) => {
        this.closeDialog("success");
      });

      this.bulkSubmissions = [];
    }
  }

  resetForm() {
    this.bulkAssignmentForm.reset({
      assessor: [null, [Validators.required]],
    });
  }

  createForm() {
    this.bulkAssignmentForm = this.fb.group({
      assessor: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.createForm();
  }
}
