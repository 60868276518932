import { Educator } from "./educator.type";
import { Organization } from "./organization.type";

export enum UserRole {
  ASSESSOR = "Assessor",
  ISSUER = "Issuer",
  ADMIN = "Admin",
  SUBMITTER = "Educator",
}

export interface User {
  educator?: Educator;
  id: number;
  password: string;
  last_login: string;
  is_superuser: boolean;
  is_issuer: boolean;
  is_assessor: boolean;
  is_admin: boolean;
  role: UserRole;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  receive_email_notifications: boolean;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  submission_count: number;
  issuer_organizations: Organization[];
  assessor_organizations: Organization[];
}
