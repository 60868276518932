// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=devserver` then `environment.devserver.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiHost: "https://api.microcredentials-dev.digitalpromise.dev",
  apiVersion: "v1",
  recaptchaSiteKey: "6LcoW2ofAAAAAJNTl7yBdQN-ZwDHowAtx5uEQLOc",
  stripePublicKey: "pk_test_0H0ba2K1GQ4a0rgJWQQuTbOJ",
  airbrakeProjectId: "400056",
  airbrakeProjectKey: "d1e2f0496cd53c992d19be50455154da",
  airbrakeEnvironment: "devserver",

  apiGetServiceOptions: {
    withCredentials: true,
  },
};
