import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SubmissionDetailMixinComponent } from "./submission-detail-mixin.component";
import { SubmissionDetailMixinService } from "./submission-detail-mixin.service";

@NgModule({
  imports: [CommonModule],
  declarations: [SubmissionDetailMixinComponent],
  providers: [SubmissionDetailMixinService],
})
export class SubmissionDetailMixinModule {}
