import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { cloneDeep, map } from "lodash";
import { Rubric, RubricCriterion } from "../assessment.type";
import {
  Comment,
  CommentInfo,
  CommentSubmitter,
  CommentType,
  ContentType,
} from "../comment.type";

import { SubmissionRecommendationRubric } from "../submission-recommendation-rubric.type";
import { Submission } from "../submission.type";

@Directive()
export class PreviousRecommendationRubricMixin implements OnChanges {
  assessmentType: string;
  commentsInfo: {};

  @Input() rubricRecommendations: SubmissionRecommendationRubric[] = [];
  @Input() previousRubricRecommendations: SubmissionRecommendationRubric[] = [];
  @Input() submission: Submission;
  @Input() comments: Comment[] = [];

  rubricRecommendation: SubmissionRecommendationRubric;
  previousRecommendation: SubmissionRecommendationRubric | void;

  getCommentsInfo(rubricCriterions: RubricCriterion[] = []): {} {
    return rubricCriterions.reduce((data, criterion) => {
      data[criterion.id] = <CommentInfo>{
        commentType: CommentType.ASSESSOR,
        contentType: ContentType.rubricCriterion,
        pk: criterion.id,
        submission: this.submission,
      };
      return data;
    }, {});
  }

  getFormSubmitterComments(rubric: Rubric, comments?: CommentSubmitter[]): {} {
    comments = comments?.filter((comment) => comment != null);
    const data = (rubric?.criterions || [])
      .map((criterion: RubricCriterion) => criterion.id)
      .reduce((commentsMap, criterionId) => {
        let comment: string | null;
        if (comments) {
          comment = comments.find(
            (d: CommentSubmitter) => d.object_pk === criterionId.toString()
          )?.comment;
        } else {
          comment = this.comments.find(
            (c: Comment) =>
              c.comment_type === CommentType.SUBMITTER &&
              c.content_type === ContentType.rubricCriterion &&
              c.object_pk === criterionId
          )?.comment;
        }
        commentsMap[criterionId] = [comment];
        return commentsMap;
      }, {});
    return data;
  }

  getRequestData(formValue: any) {
    let data = cloneDeep(formValue);
    data.comments = map(formValue.comments, (value, rubricCriterionId) => {
      if (!value) return null;
      return { object_pk: rubricCriterionId, comment: value };
    });
    return data;
  }

  getRubricRecommendation(
    recommendationSet: SubmissionRecommendationRubric[],
    rubricObjectType: string,
    rubricObjectId: number,
    rubricId: number
  ): void | SubmissionRecommendationRubric {
    if (!recommendationSet || !(recommendationSet.length > 0)) {
      return null;
    }

    for (const rubricRecommendation of recommendationSet) {
      if (
        rubricRecommendation.assessment_type === rubricObjectType &&
        rubricRecommendation.assessment_type_pk === rubricObjectId &&
        rubricRecommendation.rubric === rubricId
      ) {
        return rubricRecommendation;
      }
    }
    return null;
  }

  setPreviousRecommendation() {
    const assessmentType = this.assessmentType;

    if (this[assessmentType].rubric) {
      this.previousRecommendation = this.getRubricRecommendation(
        this.previousRubricRecommendations,
        `assessment ${assessmentType}`,
        this[assessmentType].id,
        this[assessmentType].rubric.id
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes["previousRubricRecommendations"];
    if (change && !change.firstChange) {
      this.setPreviousRecommendation();
    }
  }
}
