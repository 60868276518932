import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";

import { WindowRefService } from "../shared/core/window-ref.service";

import { SubmissionCreateComponent } from "./submission-create.component";

@Injectable({
  providedIn: "root",
})
export class WarnUserOnNavigateGuard
  implements CanDeactivate<SubmissionCreateComponent>
{
  constructor(private windowRefService: WindowRefService) {}

  canDeactivate(component: SubmissionCreateComponent): boolean {
    const windowRef = this.windowRefService.nativeWindow;

    if (component.hasUnsavedDraftChange()) {
      return windowRef["confirm"](
        "Leave page? Changes you made may not be saved."
      );
    }

    return true;
  }
}
