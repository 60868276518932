<div
  decision-status-bar
  *ngIf="decision && submission && user"
  class="submissionstate"
  [decision]="decision"
  [submission]="submission"
  [user]="user"
></div>

<div id="overview" class="appoverview">
  <div class="appoverview-x-container">
    <div class="appoverview-x-heading">
      <div class="appoverview-x-image">
        <img
          src="{{ credential.image }}"
          alt="Overview Img"
          width="120"
          height="120"
        />
      </div>

      <h1>{{ credential.name }}</h1>
    </div>
    <div class="appoverview-x-body">
      <style type="text/css">
        .no-bottom-margin {
          margin-bottom: 0;
          border-bottom: 0;
          min-height: 0;
        }
      </style>
      <div
        class="appoverview-x-actions"
        [class.no-bottom-margin]="
          !submission?.readable_id &&
          !(
            previousSubmissions?.length > 1 &&
            (ownAward || user?.is_admin || user?.is_issuer || user?.is_assessor)
          )
        "
      >
        <ul>
          <li>
            <a class="icon icon-organization small" href="#">
              <span class="icon-x-text">{{
                credential.organization.name
              }}</span>
            </a>
          </li>
          <li>
            <a class="icon icon-stack small" href="#">
              <span class="icon-x-text">{{ credential.tag }}</span>
            </a>
          </li>
        </ul>
        <button
          [routerLink]="['/explore', credential.slug]"
          class="button"
          type="button"
        >
          <span class="button-x-text">View Details</span>
        </button>
      </div>
      <div class="appoverview-x-details">
        <style type="text/css">
          .add-small-bottom-margin {
            margin-bottom: 8px;
          }
        </style>
        <ng-container *ngIf="submission?.readable_id">
          <h4>Submission ID</h4>
          <p
            [class.add-small-bottom-margin]="
              previousSubmissions?.length > 1 &&
              (ownAward ||
                user?.is_admin ||
                user?.is_issuer ||
                user?.is_assessor)
            "
          >
            {{ submission.readable_id }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
