<div
  *ngIf="section.rubric?.criterions?.length"
  class="wrap"
  [class.wrap-accent3]="!editing"
  [class.wrap-interactive3]="editing"
  [class.previous-was-wrong]="previousRecommendation?.implies_not_passing"
>
  <div
    #rubricContainer
    class="textinfo textinfo-expanded textinfo-outer"
    [class.textinfo-outerblue]="editing"
  >
    <header class="textinfo-x-header">
      <h5>Section Assessment</h5>
    </header>

    <ng-container *ngIf="editing; else previewRubricRecommendation">
      <form [formGroup]="form" class="forminput">
        <div
          class="attachment wrap attachment-big attachment-inner attachment-borderblue attachment-blue"
        >
          <div class="textinfo-x-headbutton">
            <h3 *ngFor="let criterion of section.rubric.criterions.slice(0, 1)">
              Section 1<ng-container *ngIf="criterion.description"
                >: </ng-container
              ><span *ngIf="criterion.description">
                {{ criterion.description }}</span
              >
            </h3>

            <div class="textinfo-x-buttonmediabig">
              <button
                (click)="exitUpdateRubricRecommendation()"
                type="button"
                class="button button-secondary"
              >
                Cancel
              </button>
              <button
                (click)="saveUpdateRubricRecommendation()"
                type="button"
                class="button"
                style="color: white"
              >
                Save Changes
              </button>
            </div>
          </div>

          <div class="textinfo-x-content" [formGroup]="form">
            <div
              *ngFor="
                let criterion of section.rubric.criterions;
                let criterionIndex = index
              "
              class="textinfo-x-section"
            >
              <h3 *ngIf="criterionIndex > 0">
                Section {{ criterionIndex + 1
                }}<ng-container *ngIf="criterion.description">: </ng-container
                ><span *ngIf="criterion.description">
                  {{ criterion.description }}</span
                >
              </h3>
              <div class="forminput forminput-small forminput-inputsspaced">
                <fieldset>
                  <div
                    class="forminput-x-input"
                    *ngFor="
                      let criterionLevel of criterion.criterion_levels;
                      let criterionLevelIndex = index
                    "
                  >
                    <div class="formradio formradio-bold">
                      <input
                        class="visuallyhidden"
                        type="radio"
                        value="{{ criterion.id }},{{ criterionLevel.id }}"
                        [checked]="
                          rubricRecommendation?.criterion_levels.includes(
                            criterionLevel.id
                          )
                        "
                        (click)="
                          setRubricRecommendationCriterionLevel(
                            criterionLevel.id,
                            criterion
                          )
                        "
                        name="section_{{ criterion.id }}"
                        id="section_{{ section.id }}_criterionlevel_{{
                          criterionLevel.id
                        }}"
                      />
                      <label
                        for="section_{{ section.id }}_criterionlevel_{{
                          criterionLevel.id
                        }}"
                      >
                        {{ criterionLevel.quality }}
                        <span
                          class="forminput-x-sublabel"
                          [innerHTML]="criterionLevel.description | newlineToBr"
                        ></span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <app-rubric-comment
                *ngIf="
                  rubricRecommendation && editing;
                  else previewRubricRecommendationComment
                "
                [criterion]="criterion"
                [isReadOnly]="false"
              >
              </app-rubric-comment>
              <app-submission-comments
                [data]="comments"
                [info]="commentsInfo[criterion.id]"
              >
                <hr class="comments__hr" />
                <label class="forminput-x-label"
                  >Assessor Comments (Private):</label
                >
              </app-submission-comments>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-template #previewRubricRecommendation>
      <div
        class="attachment wrap attachment-big attachment-inner attachment-borderorange"
      >
        <div class="textinfo-x-headbutton">
          <h3 *ngFor="let criterion of section.rubric.criterions.slice(0, 1)">
            Section 1<ng-container *ngIf="criterion.description"
              >: </ng-container
            ><span *ngIf="criterion.description">
              {{ criterion.description }}</span
            >
          </h3>

          <div class="textinfo-x-buttonmediabig">
            <button
              *ngIf="showEditButton"
              (click)="startUpdateRubricRecommendation()"
              type="button"
              class="button button-tertiary"
              style="color: #2578a2"
            >
              Edit
            </button>
            <button
              #expandRubric
              *ngIf="!editing"
              type="button"
              class="button button-secondary"
              aria-labelledby="question"
              (click)="rubricContainer.classList.toggle('textinfo-is-active')"
            >
              <ng-container
                *ngIf="
                  rubricContainer.classList.contains('textinfo-is-active');
                  else expandText
                "
                >Collapse Rubric</ng-container
              >
              <ng-template #expandText>Expand Rubric</ng-template>
            </button>
          </div>
        </div>

        <div class="textinfo-x-content" [formGroup]="form">
          <div
            class="textinfo-x-section"
            *ngFor="
              let criterion of section.rubric.criterions;
              let criterionIndex = index
            "
          >
            <h3 *ngIf="criterionIndex > 0">
              Section {{ criterionIndex + 1
              }}<ng-container *ngIf="criterion.description">: </ng-container
              ><span *ngIf="criterion.description">
                {{ criterion.description }}</span
              >
            </h3>
            <div class="forminput forminput-small forminput-inputsspaced">
              <fieldset>
                <div
                  class="forminput-x-input"
                  *ngFor="
                    let criterionLevel of criterion.criterion_levels;
                    let criterionLevelIndex = index
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="
                      critLevel;
                      context: {
                        $implicit:
                          rubricRecommendation?.criterion_levels?.includes(
                            criterionLevel.id
                          )
                      }
                    "
                  ></ng-container>
                  <ng-template #critLevel let-isRecommendedLevel>
                    <div
                      [class.textinfo-x-hidden]="!isRecommendedLevel"
                      [class.l-hidden]="!isRecommendedLevel"
                      tabindex="-1"
                    >
                      <div class="formradio formradio-bold">
                        <input
                          class="visuallyhidden"
                          disabled
                          type="radio"
                          [value]="criterionLevel.id"
                          [checked]="isRecommendedLevel"
                          id="section_{{ section.id }}_criterionlevel_{{
                            criterionLevel.id
                          }}"
                        />
                        <label
                          for="section_{{ section.id }}_criterionlevel_{{
                            criterionLevel.id
                          }}"
                        >
                          {{ criterionLevel.quality }}
                          <span
                            class="forminput-x-sublabel"
                            [innerHTML]="
                              criterionLevel.description | newlineToBr
                            "
                          ></span>
                        </label>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </fieldset>
            </div>
            <app-rubric-comment
              *ngIf="showAssessorComment"
              [criterion]="criterion"
              [isReadOnly]="true"
            >
            </app-rubric-comment>
            <app-submission-comments
              [data]="comments"
              [info]="commentsInfo[criterion.id]"
            >
              <hr class="comments__hr" />
              <label class="forminput-x-label"
                >Assessor Comments (Private):</label
              >
            </app-submission-comments>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
